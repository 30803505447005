import type { PlatformHeadCellType } from 'pages/Platforms/types';

const headCells: PlatformHeadCellType[] = [
  { id: 'name', label: 'Platform', sortable: true, filter: true },
  { id: 'urlSearchPattern', label: 'Domain', sortable: true, filter: true },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    filter: true,
    options: [
      { label: 'Blocked', value: 'blocked' },
      { label: 'Pending', value: 'pending' },
      { label: 'In Progress CS', value: 'in_progress_cs' },
      { label: 'In Progress DEV', value: 'in_progress_dev' },
      { label: 'In Progress PM', value: 'in_progress_pm' },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'blockingReason',
    label: 'Blocking Reason',
    sortable: true,
    filter: true,
    options: [
      { label: 'Not PE Compliant', value: 'not_pe_compliant' },
      { label: 'Not Review Portal', value: 'not_review_portal' },
      { label: 'Other', value: 'other' },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'totalSourcesCount',
    label: 'Sources',
    sortable: true,
  },
  {
    id: 'comment',
    label: 'Comment',
  },
  { id: 'createdAt', label: 'Created', filter: true, sortable: true },
  { id: 'updatedAt', label: 'Updated', filter: true, sortable: true, hideColumn: true },
];

export default headCells;

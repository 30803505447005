import React, { useEffect, useCallback } from 'react';
import type { FC, Dispatch, SetStateAction } from 'react';
import { Box, debounce, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { timeSettingsSchema } from '../../../../schemas';
import { convertMillisecondsToTime } from '../../../helper';
import type { SettingsLabelType, SettingsType } from '../../../types';
import TimeElementInput from './TimeElementInput';

type Props = {
  name: keyof SettingsType;
  label: SettingsLabelType;
  options: Readonly<Array<'days' | 'hours' | 'milliseconds' | 'minutes' | 'seconds' | 'weeks'>>;
  milliseconds: number;
  actions: {
    setDeleyActive: Dispatch<SetStateAction<number>>;
    setDeleyInactive: Dispatch<SetStateAction<number>>;
    setDeleyFailedActive: Dispatch<SetStateAction<number>>;
    setDeleyFailedInactive: Dispatch<SetStateAction<number>>;
    setIntervalCycle: Dispatch<SetStateAction<number>>;
  };
};

const TimeInput: FC<Props> = ({ milliseconds, name, label, options, actions }) => {
  const formik = useFormik({
    initialValues: convertMillisecondsToTime(milliseconds),
    enableReinitialize: true,
    validationSchema: timeSettingsSchema,
    onSubmit: () => {
      const timeInMilliseconds =
        formik.values.weeks * 604800000 +
        formik.values.days * 86400000 +
        formik.values.hours * 3600000 +
        formik.values.minutes * 60000 +
        formik.values.seconds * 1000 +
        formik.values.milliseconds;

      switch (name) {
        case 'delayActive':
          actions.setDeleyActive(timeInMilliseconds);
          break;

        case 'delayInactive':
          actions.setDeleyInactive(timeInMilliseconds);
          break;

        case 'delayFailedActive':
          actions.setDeleyFailedActive(timeInMilliseconds);
          break;

        case 'delayFailedInactive':
          actions.setDeleyFailedInactive(timeInMilliseconds);
          break;

        case 'intervalCycle':
          actions.setIntervalCycle(timeInMilliseconds);
          break;
        default:
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), 200),
    [formik.submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <Box sx={{ p: { xs: '0.5rem', sm: '0.5rem 0.75rem' } }}>
      <Typography sx={{ paddingBottom: '0.75rem' }}>{label}</Typography>
      <Grid spacing={3} container={true}>
        {options.map((timeElement) => (
          <TimeElementInput key={timeElement} formik={formik} name={timeElement} />
        ))}
      </Grid>
    </Box>
  );
};

export default TimeInput;

/* eslint-disable complexity */
import type { DuplicateIdsFilterType } from 'pages/DuplicateIds/types';
import { convertStringToYYMMDD } from 'pages/helper';
import type { PlatformFilterType, PlatformTableType } from 'pages/Platforms/types';
import type { SourceFilterType, SourceSortingType } from 'pages/Sources/types';
import type { Order } from 'types';

const getDateFilterName = (key: string): string => {
  switch (key) {
    case 'createdAt':
      return 'created';

    case 'updatedAt':
      return 'updated';

    case 'lastRunAt':
      return 'last-run';

    case 'lastTriggeredAt':
      return 'last-triggered';
    default:
      return '';
  }
};

const getPercentageFilterName = (key: string): string => {
  switch (key) {
    case 'averageActiveIntervalNote':
      return 'average-active-interval-note';

    case 'averageFailedActiveIntervalNote':
      return 'average-failedactive-interval-note';

    case 'averageInactiveIntervalNote':
      return 'average-inactive-interval-note';

    case 'averageFailedInactiveIntervalNote':
      return 'average-failedinactive-interval-note';
    default:
      return '';
  }
};

export const getQueryParameters = (
  activeFilters?:
    | DuplicateIdsFilterType['activeFilters']
    | PlatformFilterType['activeFilters']
    | SourceFilterType['activeFilters'],
): string[] => {
  const filters = Object.entries(activeFilters ?? {});

  return filters.reduce((pV, [key, value]) => {
    if (!value) {
      return pV;
    }

    if (key === 'isSkippingInactives' && typeof value === 'string') {
      pV.push(`skipping-inactives=${value}`);
    } else if (key.startsWith('average') && typeof value === 'string') {
      const filterName = getPercentageFilterName(key);

      pV.push(`${filterName}=${value}`);
    } else if (key.includes('is') && typeof value === 'string') {
      const name = key.replace('is', '').toLowerCase();

      pV.push(`${name}=${value}`);
    } else if ((key === 'platformName' || key === 'platformId') && typeof value === 'string') {
      pV.push(`platform=${value}`);
    } else if (key === 'profileId' && typeof value === 'string') {
      pV.push(`profile=${value}`);
    } else if (key === 'excludedPlatformId' && typeof value === 'string') {
      pV.push(`platform-exclude=${value}`);
    } else if (key === 'count' && typeof value === 'string') {
      pV.push(`count=${value}`);
    } else if (key === 'platformSpecificId' && typeof value === 'string') {
      pV.push(`platformSpecificId=${value}`);
    } else if (key === 'groupId' && typeof value === 'string') {
      pV.push(`groupId=${value}`);
    } else if (key === 'status' && typeof value === 'string') {
      pV.push(`status=${value}`);
    } else if (typeof value === 'string') {
      pV.push(`${key}=${encodeURIComponent(value)}`);
    } else if (value.min && value.max) {
      const filterName = getDateFilterName(key);
      const dateMax = new Date(convertStringToYYMMDD(value.min));

      dateMax.setHours(23, 59, 59);

      pV.push(
        `min-${filterName}=${new Date(
          convertStringToYYMMDD(value.max),
        ).toISOString()}&max-${filterName}=${dateMax.toISOString()}`,
      );
    } else if (value.min) {
      const filterName = getDateFilterName(key);

      pV.push(`min-${filterName}=${new Date(convertStringToYYMMDD(value.min)).toISOString()}`);
    } else if (value.max) {
      const filterName = getDateFilterName(key);

      pV.push(`max-${filterName}=${new Date(convertStringToYYMMDD(value.max)).toISOString()}`);
    }

    return pV;
  }, [] as string[]);
};

export const getSortParameter = (
  order: Order,
  orderBy: keyof PlatformTableType | keyof SourceSortingType | '',
): string => `&sort=${order === 'desc' ? '-' : ''}${orderBy.includes('At') ? getDateFilterName(orderBy) : orderBy}`;

import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  title: string;
  text: string;
};

const HeadCellLabel: FC<Props> = ({ title, text }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <span>{title}</span>
    <Tooltip title={text} arrow={true}>
      <IconButton>
        <InfoIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Tooltip>
  </Box>
);

export default HeadCellLabel;

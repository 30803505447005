import * as React from 'react';
import type { FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { CardStatisticsType } from '../types';

const CardElement: FC<CardStatisticsType> = ({ title, count, total, icon, tooltip }) => (
  <Card
    sx={{
      p: '1.5rem',
      minHeight: '9rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant="body1"
        sx={{
          textTransform: 'uppercase',
          fontSize: '0.8rem',
          '@media(min-width: 900px)': {
            fontSize: '1rem',
          },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: '1.8rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            fontSize: '1.8rem',
            color: (theme) => theme.palette.grey[500],
            paddingRight: '10px',
          },
          '@media(min-width: 900px)': {
            fontSize: '2.125rem',
            '& svg': {
              fontSize: '2.125rem',
            },
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{icon}</Box>
        <Box>
          {count}/{total}
        </Box>
      </Typography>
    </Box>
    <Tooltip title={tooltip} sx={{ position: 'absolute', top: '0.75rem', right: '0.75rem' }} arrow={true}>
      <IconButton>
        <InfoOutlinedIcon sx={{ fontSize: '1.125rem', color: (theme) => theme.palette.grey[500] }} />
      </IconButton>
    </Tooltip>
  </Card>
);

export default CardElement;

import * as React from 'react';
import type { FC } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SignOut from 'components/SignOut';

type Props = {
  isOpen: boolean;
  toggleDrawer: () => void;
  title: string;
};

const Header: FC<Props> = ({ isOpen, toggleDrawer, title }) => (
  <MuiAppBar
    position="absolute"
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      transition: (theme) =>
        theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      ...(isOpen && {
        marginLeft: '240px',
        width: 'calc(100% - 240px)',
        transition: (theme) =>
          theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
      }),
    }}
  >
    <Toolbar sx={{ px: { xs: '20px', sm: '24px' } }}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        sx={{
          mr: '36px',
          ...(isOpen && { display: 'none' }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography component="h1" variant="h6" color="inherit" sx={{ flexGrow: 1 }} noWrap={true}>
        {title}
      </Typography>
      <SignOut />
    </Toolbar>
  </MuiAppBar>
);

export default Header;

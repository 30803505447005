import type { FC } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getStatusLabel } from 'pages/Domains/helper';
import { capitalizeFirstLetter } from 'pages/helper';
import type { PlatformStatus } from 'pages/Platforms/types';
import type { ProfileRatingType } from 'types';

type Props = {
  platformName: string;
  profileRatingType: ProfileRatingType;
  platformStatus: PlatformStatus;
};

const PlatformNameCell: FC<Props> = ({ platformName, profileRatingType, platformStatus }) => {
  const capitalizedRatingType = capitalizeFirstLetter(profileRatingType);
  const renderOptions = (): { color: 'default' | 'primary' | 'secondary'; text: string } => {
    if (platformStatus === 'approved' && profileRatingType === 'customerRatings') {
      return {
        color: 'primary',
        text: capitalizedRatingType ?? '',
      };
    }

    if (platformStatus === 'approved' && profileRatingType === 'employeeRatings') {
      return {
        color: 'secondary',
        text: capitalizedRatingType ?? '',
      };
    }

    return {
      color: 'default',
      text: `Unsupported Platform - ${getStatusLabel(platformStatus)}`,
    };
  };

  return (
    <Tooltip
      placement="right"
      arrow={true}
      title={
        <Box sx={{ p: '0.5rem' }}>
          <Typography sx={{ fontSize: 14 }}>
            <b>Type: </b>
            {renderOptions().text}
          </Typography>
        </Box>
      }
    >
      <Chip
        label={platformName}
        color={renderOptions().color}
        size="small"
        style={{ fontWeight: 'bold', cursor: 'pointer' }}
      />
    </Tooltip>
  );
};

export default PlatformNameCell;

import React from 'react';
import type { FC } from 'react';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

type Props = {
  url: string;
  handleDeleteAllSourcesExceptThis: () => void;
};

const DeleteAllExceptThis: FC<Props> = ({ url, handleDeleteAllSourcesExceptThis }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    handleDeleteAllSourcesExceptThis();
    setOpen(false);
  };

  const dialogContent = `Are you sure you want to delete ALL SOURCES in this group EXCEPT ${url}?`;

  return (
    <>
      <IconButton title="delete all but this" onClick={handleOpen}>
        <DeleteSweepIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description" keepMounted={true}>
        <DialogTitle>Delete All Sources Except This</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAllExceptThis;

import { useLocation } from 'react-router-dom';
import { r } from 'routes';

const useGetTitleByPathName = (): string => {
  const { pathname } = useLocation();

  const getTitleByPathName = (path: string) => {
    switch (path) {
      case r.dashboard:
        return 'Dashboard';

      case r.platforms:
        return 'Platforms';

      case r.duplicateids:
        return 'Sources with Duplicate ID';

      case r.domains:
        return 'Unrecognized Platforms';

      case r.sources:
        return 'Sources';
      default:
        return '';
    }
  };

  return getTitleByPathName(pathname);
};

export default useGetTitleByPathName;

import React from 'react';
import type { FC, ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import type { AlertColor } from '@mui/material/Alert';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';

type ToastProps = {
  isOpen?: boolean;
  onClose: () => void;
  type: AlertColor;
  content: ReactNode;
  duration?: number;
};

export const Toast: FC<ToastProps> = ({ isOpen = true, onClose, type, content, duration = 5000 }) => (
  <Portal>
    <Snackbar open={isOpen} autoHideDuration={duration}>
      <Alert severity={type} onClose={onClose} sx={{ width: '100%' }}>
        {content}
      </Alert>
    </Snackbar>
  </Portal>
);

import React, { memo } from 'react';
import type { FC, MouseEvent } from 'react';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import type { PlatformTableType } from 'pages/Platforms/types';
import type { PlatformHeadCellType, Order } from 'types';
import ActionCell from './ActionCell';
import TableCellWrapper from './TableCellWrapper';

type EnhancedTableProps = {
  headCells: PlatformHeadCellType[];
  onRequestSort: (event: MouseEvent<unknown>, property: keyof PlatformTableType) => void;
  order: Order;
  orderBy: string;
};

const EnhancedTableHead: FC<EnhancedTableProps> = ({ headCells, onRequestSort, order, orderBy }) => {
  const createSortHandler = (property: keyof PlatformTableType) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          ({ hideColumn, id, sortable, label }) =>
            !hideColumn && (
              <TableCellWrapper key={id}>
                <TableSortLabel
                  hideSortIcon={!sortable}
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'desc'}
                  onClick={createSortHandler(id)}
                  sx={sortable ? null : { pointerEvents: 'none' }}
                >
                  {label}
                  {orderBy === id ?? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  )}
                </TableSortLabel>
              </TableCellWrapper>
            ),
        )}
        <ActionCell />
      </TableRow>
    </TableHead>
  );
};

export default memo(EnhancedTableHead);

import React from 'react';
import type { FC } from 'react';
import Grid from '@mui/material/Grid';
import Divider from 'components/Divider';
import type { FormikType } from '../../../types';
import SelectInput from '../inputs/SelectInput';
import settingsList from './settingsList';

const restrictResourceElements = settingsList.resourceSettings;

type Props = {
  formik: FormikType;
};

const RestrictResourceSettings: FC<Props> = ({ formik }) => (
  <>
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      Restrict Resource Type
    </Grid>
    {restrictResourceElements.map(({ name, label, options }) => (
      <SelectInput key={name} name={name} label={label} menuItems={options} formik={formik} isMultiple={true} />
    ))}
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      <Divider />
    </Grid>
  </>
);

export default RestrictResourceSettings;

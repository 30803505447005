import type { FC } from 'react';
import Box from '@mui/material/Box';
import { linkToTextRegex } from 'pages/helper';
import type { PlatformTableType, Value } from '../../types';

const getValue = (element: keyof PlatformTableType, value: Value) => {
  if ((element === 'createdAt' || element === 'updatedAt') && typeof value === 'string') {
    return new Date(value).toLocaleDateString('de-DE');
  }

  if (element === 'url') {
    return typeof value === 'string' ? value.replace(linkToTextRegex, '') : undefined;
  }

  if (element === 'comment') {
    return value.toString().length > 30 ? `${value.toString().slice(0, 30)}...` : value;
  }

  return value;
};

const DefaultTableCell: FC<{ element: keyof PlatformTableType; value: Value }> = ({ element, value }) => (
  <Box>{getValue(element, value)}</Box>
);

export default DefaultTableCell;

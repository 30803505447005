import * as React from 'react';
import type { FC } from 'react';
import Grid from '@mui/material/Grid';
import Divider from 'components/Divider';
import type { FormikType, SettingsType } from '../../../types';
import SwitchInput from '../inputs/SwitchInput';
import settingsList from './settingsList';

const statusElements = settingsList.statusSettings;

type PropsType = {
  formik: FormikType;
  isActive: boolean;
  isHidden: boolean;
  isSkippingInactives: boolean;
};

const checkIfTrue = (options: {
  name: keyof SettingsType;
  isActive: boolean;
  isHidden: boolean;
  isSkippingInactives: boolean;
}) => {
  const { name, isActive, isHidden, isSkippingInactives } = options;

  if (name === 'isActive') {
    return isActive;
  }

  if (name === 'isHidden') {
    return isHidden;
  }

  return isSkippingInactives;
};

const StatusSettings: FC<PropsType> = ({ formik, isActive, isHidden, isSkippingInactives }) => (
  <>
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      <Divider />
    </Grid>
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      STATUS
    </Grid>
    {statusElements.map(({ name, label }) => (
      <SwitchInput
        key={name}
        name={name}
        label={label}
        isTrue={checkIfTrue({ name, isActive, isHidden, isSkippingInactives })}
        formik={formik}
      />
    ))}
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      <Divider />
    </Grid>
  </>
);

export default StatusSettings;

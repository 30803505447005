import type { FC } from 'react';
import React from 'react';
import EventIcon from '@mui/icons-material/Event';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import DatePicker from 'react-datepicker';
import type { DateFilterPicker } from 'types';

const getLabel = (label: 'From' | 'To', activeFilter: DateFilterPicker) => {
  if (label === 'From' && activeFilter?.min) {
    return new Date(activeFilter.min);
  }

  if (label === 'To' && activeFilter?.max) {
    return new Date(activeFilter.max);
  }

  return null;
};

const getDateFrom = (label: 'From' | 'To', date: Date, activeFilter: DateFilterPicker) => {
  if (label === 'From') {
    return date.toISOString();
  }

  if (activeFilter?.min) {
    return activeFilter?.min;
  }

  return null;
};

const getDateTo = (label: 'From' | 'To', date: Date, activeFilter: DateFilterPicker) => {
  if (label === 'To') {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date.toISOString();
  }

  if (activeFilter?.max) {
    return activeFilter?.max;
  }

  return null;
};

const Calendar = styled(Box)(({ theme }) => ({
  border: 'none',
  boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;',
  padding: '0.5rem',
  '& .react-datepicker__header': {
    backgroundColor: theme.palette.common.white,
  },
  '& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '& .react-datepicker__navigation': {
    top: '0.85rem',
  },
  '& .react-datepicker__current-month': {
    marginBottom: '1rem',
  },
}));

type PropsType = {
  id: 'createdAt' | 'updatedAt';
  activeFilter: DateFilterPicker;
  onChangeFilter: (name: 'createdAt' | 'updatedAt', value: DateFilterPicker) => void;
  label: 'From' | 'To';
};

const FilterDatePicker: FC<PropsType> = ({ id, activeFilter, onChangeFilter, label }) => (
  <Box sx={{ m: '1rem auto 0', width: '170px' }}>
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={getLabel(label, activeFilter)}
      showPopperArrow={false}
      calendarContainer={Calendar}
      onChange={(date: Date) => {
        onChangeFilter(id, {
          min: getDateFrom(label, date, activeFilter),
          max: getDateTo(label, date, activeFilter),
        });
      }}
      customInput={
        <TextField
          label={label}
          sx={{ zIndex: 0 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      }
    />
  </Box>
);

export default FilterDatePicker;

/* eslint-disable react-hooks/exhaustive-deps */

import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import debounce from '@mui/utils/debounce';
import type { URLSearchParamsInit } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import useGetTitleByPathName from 'hooks/useGetTitleByPathName';
import type { DateFilterPicker } from 'types';
import { convertToDateFilterName, getJSONFromStorage, setJSONToStorage } from '../pages/helper';
import { isDatePickerFormat } from '../pages/types';

type ChangeFilterType<T> = (data: Array<{ name: T; value?: DateFilterPicker | string }>) => void;

type FiltersType = Record<string, string | undefined>;

type UseFilterReturnType<T extends string> = {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  resetFilters: () => void;
  activeFilters: FiltersType;
  changeFilter: ChangeFilterType<T>;
};

const useFilters = <T extends string>(options: {
  onChangeCallback: (data: FiltersType) => void;
}): UseFilterReturnType<T> => {
  const [isActive, setIsActive] = useState(false);
  const title = useGetTitleByPathName();
  const SEARCH_PARAMS_KEY = `${title}_SESSION_STORAGE_KEY`;
  const savedParameters = getJSONFromStorage(SEARCH_PARAMS_KEY) as Record<T, string>;

  const [searchParameters, setSearchParameters] = useSearchParams(savedParameters);
  const searchObject = Object.fromEntries(searchParameters.entries());
  const [filters, setFilters] = useState<FiltersType>({
    ...searchObject,
  });

  const callback = (values: FiltersType) => {
    options.onChangeCallback(values);

    const updatedSearchParameters = { ...searchObject, ...values };

    for (const [key, value] of Object.entries(updatedSearchParameters)) {
      if (!value) {
        delete updatedSearchParameters[key];
      }
    }

    setJSONToStorage(SEARCH_PARAMS_KEY, updatedSearchParameters);
    setSearchParameters(updatedSearchParameters as URLSearchParamsInit);
  };
  const callbackDebounced = debounce(callback, 700);

  const callbackMemoized = useCallback(callbackDebounced, []);

  useEffect(() => {
    callbackMemoized(filters);
  }, [filters]);

  const memoizedSetFilters = useCallback(setFilters, []);

  const changeFilter: ChangeFilterType<T> = (data) => {
    const updatedFilters = {
      ...filters,
    };

    for (const item of data) {
      if (!isDatePickerFormat(item.value)) {
        updatedFilters[item.name] = item.value || undefined;
      } else {
        const filterName = convertToDateFilterName(item.name);

        if (item.value?.min) {
          updatedFilters[filterName[0] as keyof typeof updatedFilters] = new Date(item.value.min).toISOString();
        }

        if (item.value?.max) {
          updatedFilters[filterName[1] as keyof typeof updatedFilters] = new Date(item.value.max).toISOString();
        }
      }

      memoizedSetFilters(updatedFilters);
    }
  };

  const resetFilters = () => {
    const emptyFilters = { ...filters };

    for (const key of Object.keys(filters)) {
      emptyFilters[key as T] = '';
    }

    setFilters(emptyFilters);
    window.sessionStorage.removeItem(SEARCH_PARAMS_KEY);
  };

  return {
    changeFilter,
    isActive,
    setIsActive,
    resetFilters,
    activeFilters: filters,
  };
};

export default useFilters;

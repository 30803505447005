import type { DateFilterPicker } from 'types';
import type { DuplicateIdsFilterType, DuplicateIdsHeadCellType } from './DuplicateIds/types';
import type {
  PlatformFilterNamesType,
  PlatformHeadCellType,
  PlatformNameType,
  PlatformTableType,
} from './Platforms/types';
import type { SourceTableType } from './Sources/types';
import type { DateRangeType } from './types';
import { isDateType } from './types';

export const dateLabels = ['createdAt', 'updatedAt', 'lastRunAt', 'lastTriggeredAt'];

const percentageIds = new Set([
  'averageActiveIntervalNote',
  'averageFailedActiveIntervalNote',
  'averageInactiveIntervalNote',
  'averageFailedInactiveIntervalNote',
]);
const { FALLBACK_PLATFORM_ID } = window.env;

export const isDateFormat = (
  filter: DateFilterPicker | boolean | number | string | undefined,
  id: Exclude<DuplicateIdsHeadCellType['id'], 'sources'> | keyof PlatformTableType | keyof SourceTableType,
): filter is DateFilterPicker => dateLabels.includes(id);

export const isPercentageFormat = (
  id:
    | Exclude<DuplicateIdsHeadCellType['id'], 'sources'>
    | keyof PlatformTableType
    | keyof SourceTableType
    | 'name-exclude',
): boolean => percentageIds.has(id);

const getLabel = (headCell: HeadCellType | undefined): string => {
  if (typeof headCell?.filterLabel === 'string') {
    return headCell.filterLabel;
  }

  if (typeof headCell?.label === 'string') {
    return headCell.label;
  }

  if (headCell?.id === 'url') {
    return 'URL';
  }

  return '';
};

export const convertToDateFilterName = (name: string): string[] => {
  const filterName = name.slice(0, -2);

  return [`min-${filterName}`, `max-${filterName}`];
};

export const convertToColumnName = (name: string): string => {
  const columnName = name.replace('min-', '').replace('max-', '');

  return `${columnName}At`;
};

type HeadCellType = Omit<PlatformHeadCellType, 'id'> & {
  id: keyof PlatformTableType | keyof SourceTableType;
};

export const convertStringToYYMMDD = (value: string): string => value.split('.').reverse().join('.');

type GetChipLabelProps = {
  key: keyof PlatformTableType | keyof SourceTableType;
  value: DateRangeType | string;
  headCells: HeadCellType[];
  platformNames?: PlatformNameType[];
};

export const getChipLabel = (options: GetChipLabelProps): string | null => {
  const { key, value, headCells, platformNames } = options;
  const headCell = headCells.find((element) => element.id === key);
  const label = getLabel(headCell);

  if (isDateType(value)) {
    const labelFrom = value?.dateFrom ? `from ${new Date(value?.dateFrom).toLocaleDateString('en-GB')}` : '';
    const labelTo = value?.dateTo ? `to ${new Date(value?.dateTo).toLocaleDateString('en-GB')}` : '';

    return `${key.replace('At', '').replace('min-', '').replace('max-', '')} ${labelFrom} ${labelTo}`;
  }

  if (isPercentageFormat(key)) {
    return `${label}: ${value}`;
  }

  if (key === 'platformName') {
    const option = platformNames?.find((element) => element.id === value)?.label ?? '';

    return `${label}: ${option}`;
  }

  const option = headCell?.options?.find((element) => element.value?.toString() === value)?.label ?? value;

  return `${label}: ${option}`;
};

export const getQuickFilterName = (id: keyof SourceTableType): keyof SourceTableType => {
  if (id === 'isInactive') {
    return 'isActive';
  }

  return id;
};

export const getQuickFilterValue = (id: string): string => {
  if (id === 'excludedPlatformId') {
    return FALLBACK_PLATFORM_ID;
  }

  if (id === 'isInactive') {
    return 'false';
  }

  if (id === 'isReviewed') {
    return 'false';
  }

  if (id === 'platformStatusQuery') {
    return 'approved';
  }

  return 'true';
};

export const getNameParameter = (
  name: Exclude<DuplicateIdsHeadCellType['id'], 'sources'> | PlatformFilterNamesType | keyof SourceTableType,
): string => {
  if (name === 'isReviewed') {
    return 'isVerified';
  }

  if (name === 'profileId') {
    return 'profile';
  }

  return name;
};

export const getValueParameter = (
  name: Exclude<DuplicateIdsHeadCellType['id'], 'sources'> | PlatformFilterNamesType | keyof SourceTableType,
  value: DateFilterPicker | number | string,
  platformNames?: PlatformNameType[],
): number | string | string | null | undefined => {
  if (name === 'platformName') {
    return platformNames?.find((element) => element.id === value)?.label;
  }

  if (isDateFormat(value, name)) {
    const createdFrom = `from${value?.min ?? ''}`;
    const createdTo = `to${value?.max ?? ''}`;

    if (value?.min && !value.max) {
      return createdFrom;
    }

    if (!value?.min && value?.max) {
      return createdTo;
    }

    if (value?.min && value?.max) {
      return `${createdFrom}${createdTo}`;
    }

    return null;
  }

  return value;
};

export const getFilterNameParameter: (
  name: string,
) => PlatformFilterNamesType | keyof DuplicateIdsFilterType['activeFilters'] | keyof SourceTableType = (
  name: string,
) => {
  if (name === 'isVerified') {
    return 'isReviewed' as PlatformFilterNamesType | keyof SourceTableType;
  }

  if (name === 'profile') {
    return 'profileId' as PlatformFilterNamesType | keyof SourceTableType;
  }

  return name as PlatformFilterNamesType | keyof DuplicateIdsFilterType['activeFilters'] | keyof SourceTableType;
};

export const getJSONFromStorage = (key: string): Record<string, string> => {
  const sessionStorageData = window.sessionStorage.getItem(key);
  const parsedData = sessionStorageData ? (JSON.parse(sessionStorageData) as unknown) : {};

  return parsedData as Record<string, string>;
};

export const setJSONToStorage = <T>(key: string, value: T): void => {
  try {
    if (window === undefined) {
      return;
    }

    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch {
    // eslint-disable-next-line no-console
    console.warn('cant set item into sessionStorage');
  }
};

export const roundNumber = (number_: number): number | string => {
  if (number_ % 1 !== 0) {
    return number_.toFixed(2);
  }

  return number_;
};

export const capitalizeFirstLetter = (text: string | undefined): string | null => {
  if (typeof text === 'undefined' || text.length === 0) {
    return null;
  }

  return text[0].toUpperCase() + text.slice(1);
};

export const limitText = ({ text = '', limit }: { text?: string; limit: number }): string => {
  if (text.length > limit) {
    return `${text.slice(0, limit)}...`;
  }

  return text;
};

export const linkToTextRegex = /^(https?:\/\/)?(w{3}.)?/i;

export const exhaustiveCheck = (): never => {
  throw new Error('Did not expect to be here');
};

import { toast } from 'react-toastify';
import type { CustomError } from 'api/ratingCrawlerBaseQuery';
import type {
  DuplicateGroupState,
  getDuplicateSourcesArgumentsType,
  getDuplicateSourcesReturnType,
} from 'pages/DuplicateIds/types';
import { getQueryParameters } from '../helper';
import { ratingCrawlerApiSlice } from '../ratingCrawlerApiSlice';

const duplicateSourcesExtendedApi = ratingCrawlerApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDuplicateSources: builder.query<getDuplicateSourcesReturnType, getDuplicateSourcesArgumentsType>({
      query: ({ page, rowsPerPage, activeFilters }) => {
        const queryParameters = getQueryParameters(activeFilters);
        const queryString = queryParameters.length === 0 ? '' : `&${queryParameters.join('&')}`;

        return {
          url: `/q/v1/sources-with-duplicate-id?offset=${page * rowsPerPage}&limit=${rowsPerPage}${queryString}`,
          method: 'GET',
        };
      },
      transformResponse: (payload: {
        data: Array<{ id: string; type: string; attributes: Omit<DuplicateGroupState, 'id'> }>;
        meta: { count: number };
      }) => ({
        duplicatesData: payload.data.map(({ id, attributes }) => ({
          ...attributes,
          id,
        })),
        meta: payload.meta,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    changeDuplicateStatus: builder.mutation<
      undefined,
      getDuplicateSourcesArgumentsType & { id: string; sourceId?: string; isVerified: boolean }
    >({
      query: ({ id, sourceId, isVerified }) => ({
        url: '/c/v1/change-duplicate-source-status',
        method: 'POST',
        body: JSON.stringify({
          id,
          sourceId,
          isVerified,
        }),
      }),
      async onQueryStarted({ id, sourceId, page, rowsPerPage, activeFilters, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          duplicateSourcesExtendedApi.util.updateQueryData(
            'getDuplicateSources',
            { page, rowsPerPage, activeFilters },
            (draft) => {
              const currentGroupIndex = draft.duplicatesData.findIndex((duplicateItem) => duplicateItem.id === id);
              const currentGroup = draft.duplicatesData[currentGroupIndex];

              if (!currentGroup) {
                return;
              }

              if (!sourceId) {
                for (const source of currentGroup.sources) {
                  source.isVerified = patch.isVerified;
                }
              }

              if (sourceId) {
                const currentSource = currentGroup.sources.find((source) => source.sourceId === sourceId);

                if (currentSource) {
                  currentSource.isVerified = patch.isVerified;
                }
              }

              const { verifiedSourcesNumber, nonVerifiedSourcesNumber } = currentGroup.sources.reduce(
                (pV, cV) => {
                  if (cV.isVerified) {
                    // eslint-disable-next-line no-param-reassign
                    pV.verifiedSourcesNumber += 1;
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    pV.nonVerifiedSourcesNumber += 1;
                  }

                  return pV;
                },
                {
                  verifiedSourcesNumber: 0,
                  nonVerifiedSourcesNumber: 0,
                },
              );

              if (
                ((activeFilters?.status === 'non-verified' || activeFilters?.status === undefined) &&
                  verifiedSourcesNumber === currentGroup.sources.length) ||
                (activeFilters?.status === 'verified' && nonVerifiedSourcesNumber > 0)
              ) {
                draft.duplicatesData.splice(currentGroupIndex, 1);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    changeDuplicateSourceIsReviewed: builder.mutation<undefined, { sourceId: string; reviewed: boolean }>({
      query: ({ sourceId, reviewed }) => ({
        url: '/c/v1/change-source-reviewed',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
          reviewed,
        }),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
    }),
    changeDuplicateSourceIsSkipped: builder.mutation<undefined, { sourceId: string; skipped: boolean }>({
      query: ({ sourceId, skipped }) => ({
        url: '/c/v1/change-source-skipped',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
          skipped,
        }),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
    }),
    deleteDuplicateSource: builder.mutation<
      undefined,
      { sourceId: string; groupUuid: string; paginationData: getDuplicateSourcesArgumentsType }
    >({
      query: ({ sourceId }) => ({
        url: '/c/v1/delete-source',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, groupUuid, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          duplicateSourcesExtendedApi.util.updateQueryData('getDuplicateSources', { ...paginationData }, (draft) => {
            const currentGroupIndex = draft.duplicatesData.findIndex((group) => group.id === groupUuid);

            if (currentGroupIndex !== -1) {
              const currentSourceIndex = draft.duplicatesData[currentGroupIndex].sources.findIndex(
                (source) => source.sourceId === sourceId,
              );

              if (currentSourceIndex !== -1) {
                if (draft.duplicatesData[currentGroupIndex].sources.length <= 2) {
                  draft.duplicatesData.splice(currentGroupIndex, 1);

                  return;
                }

                draft.duplicatesData[currentGroupIndex].sources.splice(currentSourceIndex, 1);
              }
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetDuplicateSourcesQuery,
  useChangeDuplicateStatusMutation,
  useChangeDuplicateSourceIsReviewedMutation,
  useChangeDuplicateSourceIsSkippedMutation,
  useDeleteDuplicateSourceMutation,
} = duplicateSourcesExtendedApi;

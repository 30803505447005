import React, { memo } from 'react';
import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import ActionCell from 'components/EnhancedTableHead/ActionCell';
import TableCellWrapper from 'components/EnhancedTableHead/TableCellWrapper';
import type { SourceSortingType, Order } from '../types';
import headCells from './headCells';

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof SourceSortingType) => void;
  order: Order;
  orderBy: string;
  platformID: string | null;
};

const EnhancedTableHead: FC<EnhancedTableProps> = ({ order, orderBy, onRequestSort, platformID }) => {
  const createSortHandler = (property: keyof SourceSortingType) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <ActionCell />
        {headCells.map(
          (headCell) =>
            !headCell.hideColumn && (
              <TableCellWrapper key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  hideSortIcon={!headCell.sortable ?? platformID}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'desc'}
                  onClick={createSortHandler(headCell.id as keyof SourceSortingType)}
                  sx={headCell.sortable && !platformID ? null : { pointerEvents: 'none' }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <span> {headCell.label}</span>
                    </Box>
                    {headCell.tooltip && (
                      <Tooltip title={headCell.tooltip} arrow={true}>
                        <IconButton>
                          <InfoIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  {orderBy === headCell.id && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  )}
                </TableSortLabel>
              </TableCellWrapper>
            ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default memo(EnhancedTableHead);

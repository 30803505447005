import type { FC } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import useTheme from 'theme';
import type { ProfileDetailsType, UrlStatusType } from '../types';
import UrlStatus from './UrlStatus';

type Props = {
  profile?: ProfileDetailsType;
  isOpenSourceDetails: boolean;
  platformSpecificId?: string;
  updatedAt?: string;
  createdAt?: string;
  deactivatedAt?: string;
  setProfileFilter: (profileId: string) => void;
  urlStatus?: UrlStatusType;
  matchingPlatforms?: string[];
  url?: string;
  rawUrl: string;
};

const SourceDetails: FC<Props> = ({
  profile,
  isOpenSourceDetails,
  platformSpecificId,
  updatedAt,
  createdAt,
  deactivatedAt,
  setProfileFilter,
  urlStatus,
  matchingPlatforms,
  url,
  rawUrl,
}) => {
  const theme = useTheme();

  const handleProfileFilter = () => {
    if (profile) {
      setProfileFilter(profile.id);
    }
  };

  const country = profile?.attributes.addressParts.country;

  let domain: 'com' | 'de' = 'com';

  if (country?.toLowerCase() === 'de') {
    domain = 'de';
  }

  return (
    <TableCell sx={{ border: 'none', padding: 0, backgroundColor: (t) => t.palette.common.white }} colSpan={18}>
      <Collapse
        in={isOpenSourceDetails}
        timeout="auto"
        unmountOnExit={false}
        sx={{ borderBottom: '1px solid #E0E0E0' }}
      >
        <Box sx={{ margin: 10 }}>
          <Typography variant="body1">Profile additional information:</Typography>
          <div>
            <Typography sx={{ fontSize: 14 }}>
              <b>Core link: </b>
              <a
                href={profile?.attributes.coreLink}
                target="_blank"
                rel="noreferrer"
                style={{ color: theme.palette.text.primary }}
              >
                {profile?.attributes.coreLink ?? '-'}
              </a>
            </Typography>

            <Link target="_blank" component="button" onClick={handleProfileFilter} sx={{ fontSize: '14px' }}>
              Show all sources for this profile
            </Link>
          </div>
          {profile?.attributes.profileName && profile.attributes.address && (
            <Box sx={{ mt: 1 }}>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: theme.palette.text.primary }}
                href={`https://www.google.${domain}/search?q=${encodeURIComponent(
                  profile.attributes.profileName,
                )}+${encodeURIComponent(profile.attributes.address)}`.replaceAll('%20', '+')}
              >
                Google profile
              </a>
            </Box>
          )}
          <Box sx={{ pt: 1 }}>
            <hr />
            <Typography variant="body1">Source additional information:</Typography>
            <Typography sx={{ fontSize: 14, wordBreak: 'break-word' }}>
              <b>Platform specific ID:</b> {platformSpecificId ?? '-'}
            </Typography>
            <Typography sx={{ fontSize: 14, wordBreak: 'break-word' }}>
              <b>Normalized URL:</b> {url ?? '-'}
            </Typography>
            <Typography sx={{ fontSize: 14, wordBreak: 'break-word' }}>
              <b>Raw URL:</b> {rawUrl}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Updated at:</b> {updatedAt ? new Date(updatedAt).toLocaleDateString('de-DE') : '-'}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Created at:</b> {createdAt ? new Date(createdAt).toLocaleDateString('de-DE') : '-'}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Deactivated at:</b> {deactivatedAt ? new Date(deactivatedAt).toLocaleDateString('de-DE') : '-'}
            </Typography>
            {urlStatus && <UrlStatus urlStatus={urlStatus} matchingPlatforms={matchingPlatforms} />}
          </Box>
        </Box>
      </Collapse>
    </TableCell>
  );
};

export default SourceDetails;

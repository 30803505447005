import secureLocalStorage from 'react-secure-storage';

type UseAuthReturnType = {
  setIsAuthenticated: (value: boolean) => void;
  isLoggedIn: boolean;
};

const useAuth = (): UseAuthReturnType => {
  const setIsAuthenticated = (value: boolean) => {
    if (value) {
      secureLocalStorage.setItem('isAuthenticated', 'true');

      return;
    }

    secureLocalStorage.removeItem('isAuthenticated');
  };

  const isLoggedIn = secureLocalStorage.getItem('isAuthenticated') === 'true';

  return {
    setIsAuthenticated,
    isLoggedIn,
  };
};

export default useAuth;

import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import type { DuplicateSource, getDuplicateSourcesArgumentsType } from '../types';
import DuplicateMatch from './DuplicateMatch';
import innerTableHeadCells from './headCellsSubTable';

type Props = {
  isGroupDetailsOpen: boolean;
  handleSourceStatusChange: (event: React.ChangeEvent<HTMLInputElement>, sourceId: string) => void;
  sources: DuplicateSource[];
  groupUuid: string;
  paginationData: getDuplicateSourcesArgumentsType;
};

const GroupDetails: FC<Props> = ({
  isGroupDetailsOpen,
  sources,
  handleSourceStatusChange,
  groupUuid,
  paginationData,
}) => {
  const innerTableHeadCellsMemoized = useMemo(
    () =>
      innerTableHeadCells.map((item) => (
        <TableCell key={item.id}>
          <b>{item.label}</b>
        </TableCell>
      )),
    [],
  );

  return (
    <TableCell sx={{ border: 'none', padding: 0, backgroundColor: ({ palette }) => palette.common.white }} colSpan={5}>
      <Collapse in={isGroupDetailsOpen} timeout="auto" unmountOnExit={false} sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              {innerTableHeadCellsMemoized}
            </TableRow>
          </TableHead>
          <TableBody>
            {sources.map((source) => (
              <DuplicateMatch
                key={source.sourceId}
                source={source}
                handleSourceStatusChange={handleSourceStatusChange}
                groupUuid={groupUuid}
                paginationData={paginationData}
                sources={sources}
              />
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
};

export default GroupDetails;

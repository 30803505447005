import React, { memo } from 'react';
import type { FC } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type Props = {
  toggleFilters: () => void;
};

const Header: FC<Props> = ({ toggleFilters }) => (
  <IconButton onClick={toggleFilters} sx={{ borderRadius: '4px' }}>
    <FilterListIcon />
    <Typography
      variant="body1"
      sx={{ textTransform: 'uppercase', fontWeight: 600, fontSize: '0.9rem', lineHeight: 1, ml: '0.5rem' }}
    >
      filters
    </Typography>
  </IconButton>
);

export default memo(Header);

import React from 'react';
import type { FC } from 'react';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbUp from '@mui/icons-material/ThumbUp';
import { Box, IconButton } from '@mui/material';

type Props = {
  setGroupStatus: (value: boolean) => void;
};

const ActionCell: FC<Props> = ({ setGroupStatus }) => (
  <Box sx={{ maxWidth: '100px', display: 'flex' }}>
    <IconButton
      title="click to switch all sources in this group to status verified"
      onClick={() => setGroupStatus(true)}
      color="primary"
    >
      <ThumbUp />
    </IconButton>
    <IconButton
      title="click to switch all sources in this group to status not verified"
      onClick={() => setGroupStatus(false)}
      color="default"
    >
      <ThumbDown />
    </IconButton>
  </Box>
);

export default ActionCell;

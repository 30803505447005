import { createTheme } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import palette from './palette';
import shape from './shape';
import spacing from './spacing';
import typography from './typography';

const theme = (): Theme =>
  createTheme({
    palette: palette(),
    shape: shape(),
    spacing,
    typography: typography(),
  } as const);

export default theme;

import React from 'react';
import type { FC } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import type { SettingsLabelType, FormikType, SettingsType } from '../../../types';

type Props = {
  name: keyof SettingsType;
  label: SettingsLabelType;
  isTrue: boolean;
  formik: FormikType;
};

const SwitchInput: FC<Props> = ({ name, label, isTrue, formik }) => (
  <Grid xs={12} sx={{ p: { xs: '0.5rem', sm: '0.5rem 0.75rem' } }} item={true}>
    <FormControlLabel
      control={<Switch defaultChecked={isTrue} />}
      onChange={formik.handleChange}
      name={name}
      value={formik.values[name]}
      label={label}
    />
  </Grid>
);

export default SwitchInput;

import React from 'react';
import type { FC } from 'react';
import { TableCell } from '@mui/material';

type Props = {
  align?: 'center' | 'left' | 'right';
};

const ActionCell: FC<Props> = ({ align = 'center' }) => (
  <TableCell
    key="action"
    align={align}
    sx={{
      fontSize: '13px',
      lineHeight: 1.2,
      padding: '1rem 0.75rem',
      backgroundColor: (theme) => theme.palette.common.white,
    }}
  >
    Action
  </TableCell>
);

export default ActionCell;

import React from 'react';
import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  title: string;
};

const Tooltip: FC<Props> = ({ title, children }) => (
  <Box sx={{ p: '1rem' }}>
    <Typography sx={{ fontSize: 14 }}>{title}</Typography>
    {children}
  </Box>
);

export default Tooltip;

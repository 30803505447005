import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { format } from 'date-fns';
import { useChartTimeWindow } from 'hooks/useChartTimeWindow';
import type { PlatformMonitoring } from 'pages/Platforms/types';
import type { ChartOptions } from 'types';
import { ChartCell } from './ChartCell';

type Props = {
  monitoringData: PlatformMonitoring[];
};

const options: ChartOptions = {
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
};

const NewSourcesChart: FC<Props> = ({ monitoringData }) => {
  const { timeWindow, displayData, handleChange } = useChartTimeWindow(monitoringData);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, LineController);

  const data = useMemo(
    () => ({
      labels: displayData.map((platformData) => format(new Date(platformData.createdAt), 'dd/MM/yyyy')).reverse(),
      datasets: [
        {
          label: 'New Sources in the last 24H',
          data: displayData.map((platformData) => platformData.newLast24H).reverse(),
          backgroundColor: ['red'],
          borderColor: ['red'],
          borderWidth: 3,
        },
        {
          label: 'Verified Sources in the last 24H',
          data: displayData.map((platformData) => platformData.reviewedNewLast24H).reverse(),
          backgroundColor: ['red'],
          borderColor: ['red'],
          borderWidth: 3,
          borderDash: [10, 3],
        },
        {
          label: 'New Sources from Customers in the last 24H',
          data: displayData.map((platformData) => platformData.newCustomerLast24H).reverse(),
          backgroundColor: ['blue'],
          borderColor: ['blue'],
          borderWidth: 3,
        },
        {
          label: 'Verified Sources from Customers in the last 24H',
          data: displayData.map((platformData) => platformData.reviewedNewCustomerLast24H).reverse(),
          backgroundColor: ['blue'],
          borderColor: ['blue'],
          borderWidth: 3,
          borderDash: [10, 3],
        },
      ],
    }),
    [displayData],
  );

  return (
    <ChartCell
      data={data}
      options={options}
      textRed="All Sources"
      textBlue="Customers Sources"
      headerText="Number of new/verified sources in the last day:"
      timeWindow={timeWindow}
      handleChange={handleChange}
    />
  );
};

export { NewSourcesChart };

import type { FC } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  isSidebarOpen?: boolean;
  size: number;
  isFullTable?: boolean;
};

const Loader: FC<Props> = ({ isSidebarOpen, size, isFullTable }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...(isFullTable && {
        transform: {
          xs: `translate(calc(50vw - ${isSidebarOpen ? '171px' : '79px'}))`,
          sm: `translate(calc(50vw - ${isSidebarOpen ? '191px' : '99px'}))`,
        },
        margin: '1rem 0',
      }),
    }}
  >
    <CircularProgress size={size} />
  </Box>
);

export default Loader;

import React from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { UrlStatusType } from '../types';
import MatchingPlatforms from './MatchingPlatforms';

type Props = {
  urlStatus: UrlStatusType;
  matchingPlatforms?: string[];
};

const UrlStatus: FC<Props> = ({ urlStatus, matchingPlatforms }) => (
  <Box sx={{ display: 'flex' }}>
    <Typography sx={{ fontSize: 14, mr: '5px' }}>
      <b>Url status:</b>
    </Typography>
    {urlStatus === 'multiplePlatformsFound' ? (
      <MatchingPlatforms urlStatus={urlStatus} matchingPlatforms={matchingPlatforms} />
    ) : (
      <Typography sx={{ fontSize: 14 }}>{urlStatus}</Typography>
    )}
  </Box>
);

export default UrlStatus;

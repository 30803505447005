import type { FC } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type { FormikType } from '../../types';

type Props = {
  formik: FormikType;
};

const ResultSettings: FC<Props> = ({ formik }) => (
  <>
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      <Box>ADD NEW RESULT</Box>
    </Grid>
    <Grid xs={6} sm={3} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <TextField
          id="outlined-basic"
          name="count"
          label="Count"
          value={formik.values.count}
          onChange={formik.handleChange}
        />
      </FormControl>
    </Grid>
    <Grid xs={6} sm={3} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem 0 0.75rem 0.75rem' } }} item={true}>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <TextField
          id="outlined-basic"
          name="score"
          label="Score"
          value={formik.values.score}
          onChange={formik.handleChange}
        />
      </FormControl>
    </Grid>
  </>
);

export default ResultSettings;

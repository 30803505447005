import type { FC } from 'react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import {
  Chart as ChartJS,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { format } from 'date-fns';
import { useChartTimeWindow } from 'hooks/useChartTimeWindow';
import type { PlatformMonitoring } from 'pages/Platforms/types';
import type { ChartOptions } from 'types';
import { ChartCell } from './ChartCell';

type Props = {
  monitoringData: PlatformMonitoring[];
};

const options: ChartOptions = {
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
};

const ErrorChart: FC<Props> = ({ monitoringData }) => {
  const theme = useTheme();
  const { error } = theme.palette;
  const { timeWindow, displayData, handleChange } = useChartTimeWindow(monitoringData);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, LineController);

  const errorTypes = displayData.reduce((pV, cV) => {
    for (const errorObject of cV.errorData) {
      pV.push(errorObject.errorType);
    }

    return pV;
  }, [] as string[]);

  const errorDataSets = useMemo(() => {
    const uniqueErrorTypes = [...new Set(errorTypes)];

    return uniqueErrorTypes.map((errorType) => ({
      label: errorType,
      data: [] as Array<number | null>,
      backgroundColor: ['blue'],
      borderColor: ['blue'],
      borderWidth: 1,
    }));
  }, [errorTypes]);

  for (const dataSet of errorDataSets) {
    for (const item of displayData) {
      const index = item.errorData.findIndex((errorObject) => dataSet.label === errorObject.errorType);

      if (index !== -1) {
        dataSet.data.unshift(item.errorData[index].rate);
      } else {
        dataSet.data.unshift(null);
      }
    }
  }

  const data = useMemo(
    () => ({
      labels: displayData.map((platformData) => format(new Date(platformData.createdAt), 'dd/MM/yyyy')).reverse(),
      datasets: [
        {
          label: 'General Error Percentage',
          data: displayData.map((platformData) => platformData.errorRate).reverse(),
          backgroundColor: [error.main],
          borderColor: [error.main],
          borderWidth: 3,
        },
        ...errorDataSets,
      ],
    }),
    [displayData, error.main, errorDataSets],
  );

  return (
    <ChartCell
      data={data}
      options={options}
      headerText="Percentage of sources with errors:"
      textRed="General Error Percentage"
      textBlue="Specific Error Percentage"
      timeWindow={timeWindow}
      handleChange={handleChange}
    />
  );
};

export { ErrorChart };

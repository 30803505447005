import { useState } from 'react';
import type { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useChangeSourcePlatformStatusMutation } from 'api/subSlices/sourcesExtendedSlice';
import type { PlatformStatus } from 'pages/Platforms/types';

type Props = {
  url: string;
  handleDeleteSource: () => void;
  platformId: string;
  platformStatus: PlatformStatus;
};

const Delete: FC<Props> = ({ url, handleDeleteSource, platformId, platformStatus }) => {
  const [open, setOpen] = useState(false);
  const [changePlatformStatus] = useChangeSourcePlatformStatusMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    handleDeleteSource();
    setOpen(false);
  };

  const handleDeleteBlock = () => {
    handleDeleteSource();
    changePlatformStatus({ id: platformId, status: 'blocked' });
    setOpen(false);
  };

  return (
    <>
      <IconButton title="delete" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description" keepMounted={true}>
        <DialogTitle>Delete Source</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete ${url} source?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
          {platformStatus !== 'approved' ? (
            <Button onClick={handleDeleteBlock} color="error">
              Delete & Block
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Delete;

const settingsList = {
  statusSettings: [
    { name: 'isActive', label: 'Active' },
    { name: 'isHidden', label: 'Hidden' },
    { name: 'isSkippingInactives', label: 'Skipping inactives' },
  ],
  proxySettings: [{ name: 'proxy', label: 'Proxy', options: ['disabled', 'zyte', 'zyte2', 'provenexpert'] }],
  delaySettings: [
    { name: 'delayActive', label: 'Delay Active', options: ['weeks', 'days', 'hours', 'minutes'] },
    { name: 'delayFailedActive', label: 'Delay Failed Active', options: ['weeks', 'days', 'hours', 'minutes'] },
    { name: 'delayInactive', label: 'Delay Inactive', options: ['weeks', 'days', 'hours', 'minutes'] },
    { name: 'delayFailedInactive', label: 'Delay Failed Inactive', options: ['weeks', 'days', 'hours', 'minutes'] },
    { name: 'intervalCycle', label: 'Interval Cycle', options: ['hours', 'minutes', 'seconds'] },
  ],
  resourceSettings: [
    {
      name: 'restrictResourceType',
      label: 'Restrict Resource Type',
      options: [
        'document',
        'fetch',
        'script',
        'xhr',
        'xmlhttprequest',
        'stylesheet',
        'ping',
        'image',
        'imageset',
        'main_frame',
        'websocket',
        'media',
        'font',
        'texttrack',
        'object',
        'prefetch',
        'manifest',
        'signedexchange',
        'cspviolationreport',
        'preflight',
        'other',
      ],
    },
  ],
} as const;

export default settingsList;

import React, { useState } from 'react';
import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import type { CrawlType, GetSourcesPaginationData } from '../types';
import History from './History';

type Props = {
  lastResultCount?: number;
  lastResultValue?: number;
  lastResultCrawlType?: CrawlType;
  hasExternalReviews?: boolean;
  isRatingBasedOnLastMonthCount?: boolean;
  numberOfMonths?: number;
  bestRating?: number;
  worstRating?: number;
  id: string;
  paginationData: GetSourcesPaginationData;
};

const LastResultCountCell: FC<Props> = ({
  lastResultCount,
  lastResultValue,
  lastResultCrawlType,
  hasExternalReviews,
  isRatingBasedOnLastMonthCount,
  numberOfMonths,
  bestRating,
  worstRating,
  id,
  paginationData,
}) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const handleHistoryOpen = () => {
    setIsHistoryOpen(true);
  };
  const handleHistoryClose = () => {
    setIsHistoryOpen(false);
  };
  const chipWithTooltip = (
    <>
      <Tooltip
        arrow={true}
        placement="right"
        title={
          <Box sx={{ p: '0.5rem' }}>
            {hasExternalReviews && (
              <Typography sx={{ fontSize: 12 }}>
                <b>Third-party Reviews Filtered</b>
              </Typography>
            )}
            {isRatingBasedOnLastMonthCount && (
              <Typography sx={{ fontSize: 12 }}>
                <b>Rating Count based on last {numberOfMonths ?? '12'} Months</b>
              </Typography>
            )}
            {bestRating ||
              (worstRating && (
                <Typography sx={{ fontSize: 12 }}>
                  <b>Rating Scale Converted</b>
                </Typography>
              ))}
          </Box>
        }
      >
        <IconButton title="history" onClick={handleHistoryOpen}>
          <Chip
            label={`${lastResultCount ?? '-'} / ${lastResultValue ?? '-'}`}
            color="default"
            size="small"
            style={{ fontWeight: 'bold' }}
            sx={{
              backgroundColor: (theme) => theme.palette.warning.main,
            }}
          />
        </IconButton>
      </Tooltip>
      <History
        id={id}
        paginationData={paginationData}
        hasButton={false}
        isExternallyOpenStatus={isHistoryOpen}
        setExternalOpenStatus={handleHistoryClose}
      />
    </>
  );
  const simpleChip = (
    <>
      <IconButton title="history" onClick={handleHistoryOpen}>
        <Chip
          label={`${lastResultCount ?? '-'} / ${lastResultValue ?? '-'}`}
          color="default"
          size="small"
          style={{ fontWeight: 'bold' }}
        />
      </IconButton>
      <History
        id={id}
        paginationData={paginationData}
        hasButton={false}
        isExternallyOpenStatus={isHistoryOpen}
        setExternalOpenStatus={handleHistoryClose}
      />
    </>
  );

  const manualRatingChip = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Chip
        label={`${lastResultCount ?? '-'} / ${lastResultValue ?? '-'}`}
        color="default"
        size="small"
        style={{ fontWeight: 'bold' }}
      />
      <Tooltip title="Manual Rating" arrow={true}>
        <IconButton>
          <InfoIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const ratingChip = () => {
    if (lastResultCrawlType === 'manual') {
      return manualRatingChip;
    }

    return hasExternalReviews || isRatingBasedOnLastMonthCount || bestRating || worstRating
      ? chipWithTooltip
      : simpleChip;
  };

  return typeof lastResultCount === 'number' ? ratingChip() : null;
};

export default LastResultCountCell;

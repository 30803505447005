import * as yup from 'yup';

const sourceSettingsSchema = yup.object({
  id: yup.string().required(),
  url: yup.string().required(),
  isReviewed: yup.boolean(),
  count: yup.number(),
  score: yup.number(),
});

export default sourceSettingsSchema;

import type { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { getAvatarBgColor } from './helpers';

type Props = {
  origin?: 'autocrawler' | 'customer' | 'support';
};

const OriginCell: FC<Props> = ({ origin }) =>
  origin ? (
    <Tooltip title={origin} placement="right" arrow={true}>
      <Avatar sx={{ fontSize: '22px', width: 35, height: 35, bgcolor: getAvatarBgColor(origin) }}>{origin[0]}</Avatar>
    </Tooltip>
  ) : null;

export default OriginCell;

import React, { useState, useMemo, useEffect } from 'react';
import type { MouseEvent, ChangeEvent, FC } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetResultsBySourceIdQuery } from 'api/subSlices/resultsExtendedSlice';
import { useResetSourceWarningMutation } from 'api/subSlices/sourcesExtendedSlice';
import Header from 'components/Header';
import Loader from 'components/Loader';
import { capitalizeFirstLetter } from 'pages/helper';
import type { GetSourcesPaginationData } from '../types';
import RatingWarning from './RatingWarning';

const historyHeadCells = [
  'Created At',
  'Crawl Type',
  'Rating Count',
  'Rating Value',
  'Count Warning',
  'Value Warning',
  'Status',
];

type Props = {
  id: string;
  paginationData: GetSourcesPaginationData;
  hasButton?: boolean;
  isExternallyOpenStatus?: boolean;
  setExternalOpenStatus?: React.Dispatch<React.SetStateAction<boolean>>;
};

const History: FC<Props> = ({
  id,
  paginationData,
  hasButton = true,
  isExternallyOpenStatus,
  setExternalOpenStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isChanged, setIsChanged] = useState(true);
  const [resetSourceWarning] = useResetSourceWarningMutation();

  useEffect(() => {
    if (typeof isExternallyOpenStatus === 'boolean') {
      setIsOpen(isExternallyOpenStatus);
    }
  }, [isExternallyOpenStatus]);

  const { data, isLoading } = useGetResultsBySourceIdQuery({
    sourceId: id,
    page,
    rowsPerPage,
    changed: isChanged || undefined,
  });

  const { data: resultsData, meta } = data ?? {};
  const { count } = meta ?? {};

  const handleHistoryOpen = () => {
    setIsOpen(true);
  };

  const handleHistoryClose = () => {
    setIsOpen(false);

    if (typeof setExternalOpenStatus === 'function') {
      setExternalOpenStatus(false);
    }
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeResultType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(10);
    setPage(0);
    setIsChanged(event.target.checked);
  };

  const tableBody = (() => {
    if (isLoading) {
      return <Loader isSidebarOpen={false} size={40} isFullTable={true} />;
    }

    if (!count) {
      return <p>No history</p>;
    }

    return resultsData?.map((result) => {
      const capitalizedType = capitalizeFirstLetter(result.attributes.type);

      return (
        <TableRow key={result.id}>
          <TableCell>{new Date(result.attributes.createdAt).toLocaleDateString('de-DE')}</TableCell>
          <TableCell sx={{ paddingY: '12px' }}>
            {result.attributes.type === 'success' ? <Chip label={result.attributes.rating.crawlType} /> : null}
          </TableCell>
          <TableCell>{result.attributes.type === 'success' ? result.attributes.rating.count : '-'}</TableCell>
          <TableCell>{result.attributes.type === 'success' ? result.attributes.rating.value : '-'}</TableCell>
          <TableCell>
            {result.attributes.type === 'success' ? (
              <RatingWarning ratingWarning={result.attributes.ratingCountWarning} />
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell>
            {result.attributes.type === 'success' ? (
              <RatingWarning ratingWarning={result.attributes.ratingValueWarning} />
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell>
            {result.attributes.type === 'error' ? (
              <Tooltip title={`${result.attributes.error.type ?? ''} - ${result.attributes.error.message ?? ''}`}>
                <Chip label={capitalizedType} color="error" size="small" style={{ fontWeight: 'bold' }} />
              </Tooltip>
            ) : (
              <Chip label={capitalizedType} color="success" size="small" style={{ fontWeight: 'bold' }} />
            )}
          </TableCell>
        </TableRow>
      );
    });
  })();

  const headCells = useMemo(
    () => historyHeadCells.map((headCell) => <TableCell key={headCell}>{headCell}</TableCell>),
    [],
  );

  const handleResetWarning = () => {
    resetSourceWarning({ sourceId: id, paginationData });
    handleHistoryClose();
  };

  return (
    <div>
      {hasButton && (
        <IconButton title="history" onClick={handleHistoryOpen}>
          <HistoryIcon />
        </IconButton>
      )}
      <Dialog
        onClose={handleHistoryClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullScreen={isMobile}
        sx={{
          '& .MuiPaper-root': {
            width: '700px',
            maxWidth: '700px',
          },
          '& .MuiDialogContent-root': {
            p: (theme) => theme.spacing(3),
          },
        }}
      >
        <Header id="customized-dialog-title" onClose={handleHistoryClose}>
          History
        </Header>
        <DialogContent dividers={false}>
          <Grid item={true} xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.25rem' } }}>
            <Button
              type="button"
              variant="contained"
              disableElevation={true}
              fullWidth={isMobile}
              onClick={handleResetWarning}
            >
              Reset Source Rating Warning
            </Button>
          </Grid>
          <Divider />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleChangeResultType} value={isChanged} defaultChecked={true} />}
              label="Has changed only"
            />
          </FormGroup>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>{headCells}</TableRow>
              </TableHead>
              <TableBody>{tableBody}</TableBody>
            </Table>
          </TableContainer>
          {count && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default History;

import React from 'react';
import type { FC } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { ListItem, ListItemIcon, Typography } from '@mui/material';

type Props = {
  title: string;
};

const Paragraph: FC<Props> = ({ title }) => (
  <ListItem alignItems="flex-start" sx={{ pb: '1rem' }} disablePadding={true}>
    <ListItemIcon sx={{ minWidth: '2rem', pl: '1rem' }}>
      <CircleIcon sx={{ width: '0.5rem', height: '0.5rem', color: (t) => t.palette.grey[200] }} />
    </ListItemIcon>
    <Typography sx={{ fontSize: 14 }}>{title}</Typography>
  </ListItem>
);

export default Paragraph;

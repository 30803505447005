import type { FC } from 'react';
import { Divider as MuiDivider } from '@mui/material';
import Grid from '@mui/material/Grid';

const Divider: FC = () => (
  <Grid xs={12} sx={{ m: '0.75rem 0' }} item={true}>
    <MuiDivider
      sx={{
        border: 'none',
        height: '1px',
        backgroundColor: (theme) => theme.palette.grey[200],
      }}
    />
  </Grid>
);

export default Divider;

import type { DuplicateIdsHeadCellType } from '../types';

const headCells: DuplicateIdsHeadCellType[] = [
  {
    id: 'groupId',
    label: 'Group Identifier',
    filter: true,
  },
  {
    id: 'platformSpecificId',
    label: 'Platform Specific ID',
    filter: true,
  },
  {
    id: 'count',
    label: 'Number of Duplicates',
    filter: true,
  },
  {
    id: 'sources',
    label: 'Sources List',
  },
  {
    id: 'status',
    label: 'Status',
    filter: true,
    hideColumn: true,
    options: [
      { label: 'Approved', value: 'verified' },
      { label: 'Not Approved', value: 'non-verified' },
      { label: 'All', value: 'all' },
      { label: '-', value: '' },
    ],
  },
];

export default headCells;

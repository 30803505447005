/* eslint-disable unicorn/no-nested-ternary */
import React from 'react';
import type { FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FilterDatePicker from 'components/FilterDatePicker';
import headCells from 'pages/Domains/components/headCells';
import { isDateFormat, isPercentageFormat } from 'pages/helper';
import type {
  PlatformFilterType,
  PlatformHeadCellType,
  PlatformFilterNamesType,
  PlatformNameType,
} from 'pages/Platforms/types';
import type { DateFilterPicker } from 'types';

type PropsType = {
  filter: PlatformFilterType;
  onChangeFilter: (name: PlatformFilterNamesType, value: DateFilterPicker | string) => void;
  onResetFilter: () => void;
  chosenPlatform: string;
  chosenUrlSearchPattern: string;
  platformNames: PlatformNameType[];
};

const Filters: FC<PropsType> = ({
  filter,
  onChangeFilter,
  onResetFilter,
  chosenPlatform,
  chosenUrlSearchPattern,
  platformNames,
}) => {
  const filterList = headCells
    .filter((element) => element.filter)
    .map((element) => ({
      ...element,
      options: element.options,
    }));

  const notDateFilterList = filterList.filter(
    (element) => !isDateFormat(filter.activeFilters[element.id], element.id) && !isPercentageFormat(element.id),
  );
  const dateFilterList = filterList.filter((element) => isDateFormat(filter.activeFilters[element.id], element.id));

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        my: '20px',
        p: '15px',
        textAlign: 'center',
        width: 'inherit',
      }}
    >
      <Grid sx={{ justifyContent: 'space-between' }} container={true}>
        {notDateFilterList.map((item: PlatformHeadCellType) => (
          <Grid key={item.id} xs={12} sm={6} lg={3} xl={2} item={true}>
            {['urlSearchPattern'].includes(item.id) ? (
              <TextField
                id={item.id}
                label={item.label}
                value={chosenUrlSearchPattern}
                variant="standard"
                sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}
                onChange={(event) => onChangeFilter(item.id, event.target.value)}
              />
            ) : ['name'].includes(item.id) ? (
              <Autocomplete
                id={item.id}
                options={platformNames.map((element) => element.label)}
                value={chosenPlatform}
                onChange={(event, value) => onChangeFilter(item.id, value ?? '')}
                disablePortal={true}
                renderInput={(parameters) => (
                  <TextField
                    {...parameters}
                    variant="standard"
                    label={item.label}
                    sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}
                  />
                )}
              />
            ) : (
              <FormControl variant="standard" sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}>
                <InputLabel id={item.id}>{item.label}</InputLabel>
                <Select
                  labelId={item.id}
                  id={item.id}
                  value={filter.activeFilters[item.id] ?? ''}
                  onChange={(event) => onChangeFilter(item.id, event.target.value)}
                  label={item.label}
                >
                  {item.options?.map((element) => (
                    <MenuItem key={element.label} value={String(element.value)}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid container={true}>
        {dateFilterList.map((item: PlatformHeadCellType) => {
          const activeFilter = filter.activeFilters[item.id];

          return (
            <Grid key={item.id} xs={12} sm={6} lg={3} item={true}>
              <Box sx={{ m: { xs: '2rem 0 1rem' } }}>
                <Typography variant="body1">{item.label}</Typography>
                {['From', 'To'].map((element) => (
                  <FilterDatePicker
                    key={element}
                    activeFilter={activeFilter as DateFilterPicker}
                    onChangeFilter={onChangeFilter}
                    id={item.id as 'createdAt' | 'updatedAt'}
                    label={element as 'From' | 'To'}
                  />
                ))}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Button
        sx={{ m: { xs: '2.5rem 0 1.5rem', lg: '1.5rem 0' } }}
        variant="contained"
        onClick={onResetFilter}
        disableElevation={true}
      >
        Reset
      </Button>
    </Box>
  );
};

export default Filters;

import React, { useState } from 'react';
import type { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from 'components/Divider';
import Header from 'components/Header';
import type { UrlStatusType } from '../types';

type Props = {
  urlStatus: UrlStatusType;
  matchingPlatforms?: string[];
};

const MatchingPlatforms: FC<Props> = ({ urlStatus, matchingPlatforms }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMatchingPlatformsOpen = () => {
    setIsOpen(true);
  };

  const handleMatchingPlatformsClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Link onClick={handleMatchingPlatformsOpen}>
        <Typography sx={{ fontSize: 14 }}>{urlStatus}</Typography>
      </Link>

      <Dialog
        onClose={handleMatchingPlatformsClose}
        aria-labelledby="matching platforms"
        open={isOpen}
        fullScreen={isMobile}
        sx={{
          '& .MuiPaper-root': {
            width: '700px',
            maxWidth: '700px',
          },
          '& .MuiDialogContent-root': {
            p: (theme) => theme.spacing(3),
          },
        }}
      >
        <Header id="matchingPlatforms" onClose={handleMatchingPlatformsClose}>
          Matching Platforms
        </Header>
        <DialogContent dividers={false} sx={{ mb: '20px' }}>
          {matchingPlatforms && (
            <List>
              {matchingPlatforms.map((platform, index) => (
                <>
                  <ListItem key={platform}>{platform}</ListItem>
                  {index !== matchingPlatforms.length - 1 && <Divider />}
                </>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MatchingPlatforms;

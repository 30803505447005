import type { DateFilterPicker } from 'types';

export type DateRangeType = { dateFrom: string | null; dateTo: string | null } | undefined;

export function isDateType(value: DateRangeType | string): value is DateRangeType {
  return (value as DateRangeType)?.dateFrom !== undefined || (value as DateRangeType)?.dateTo !== undefined;
}

export function isDatePickerFormat(value?: DateFilterPicker | string): value is DateFilterPicker {
  return (value as DateFilterPicker)?.min !== undefined || (value as DateFilterPicker)?.max !== undefined;
}

import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { limitText, linkToTextRegex } from 'pages/helper';

type Props = {
  url?: string;
  rawUrl: string;
};

const UrlCell: FC<Props> = ({ url, rawUrl }) => (
  <Box sx={{ width: '300px', display: 'flex', wordBreak: 'break-word' }}>
    <Link href={url ?? rawUrl} target="_blank">
      {limitText({ text: (url ?? rawUrl).replace(linkToTextRegex, ''), limit: 95 })}
      {!url && (
        <Tooltip title="Showing Raw URL as Fallback" arrow={true}>
          <IconButton>
            <InfoIcon sx={{ fontSize: '1rem', color: (theme) => theme.palette.error.main }} />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  </Box>
);

export default UrlCell;

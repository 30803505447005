import * as React from 'react';
import type { FC } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import ItemsList from './ItemsList';

type Props = {
  isOpen: boolean;
  toggleDrawer: () => void;
};

const Sidebar: FC<Props> = ({ isOpen, toggleDrawer }) => (
  <MuiDrawer
    variant="permanent"
    open={isOpen}
    sx={{
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: '240px',
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        boxSizing: 'border-box',
        ...(!isOpen && {
          overflowX: 'hidden',
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          width: '0px',
        }),
      },
    }}
  >
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav">
      <ItemsList />
    </List>
  </MuiDrawer>
);

export default Sidebar;

import type { FC } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useIsAuthenticated';
import { r } from 'routes';
import { sidebarOpenChanged, getSidebarOpen } from 'settingsReducer';
import Header from './Header';
import Sidebar from './Sidebar';

const getTitleByPathName = (pathname: string): string => {
  switch (pathname) {
    case r.dashboard:
      return 'Dashboard';

    case r.platforms:
      return 'Platforms';

    case r.duplicateids:
      return 'Sources with Duplicate ID';

    case r.domains:
      return 'Unrecognized Platforms';

    case r.sources:
      return 'Sources';

    default:
      return '';
  }
};

const Main: FC = () => {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(getSidebarOpen);
  const toggleDrawer = () => {
    dispatch(sidebarOpenChanged(!sidebarOpen));
  };

  if (!isLoggedIn) {
    return <Navigate to={r.login} />;
  }

  const title = getTitleByPathName(pathname);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header isOpen={sidebarOpen} toggleDrawer={toggleDrawer} title={title} />
      <Sidebar isOpen={sidebarOpen} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: (theme) => theme.palette.grey[100],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          marginTop: '64px',
        }}
      >
        <Box sx={{ p: '1rem' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Main;

import React from 'react';
import type { FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FilterDatePicker from 'components/FilterDatePicker';
import type { DateFilterPicker } from 'types';
import { dateLabels } from '../../helper';
import type {
  SourceFilterType,
  SourceTableType,
  SourceHeadCellType,
  SourceFilterNamesType,
  SourceNameType,
  DateRangeType,
} from '../types';
import headCells from './headCells';

type PropsType = {
  filter: SourceFilterType;
  onChangeFilter: (name: SourceFilterNamesType, value: DateFilterPicker | string) => void;
  onResetFilter: () => void;
  platformNames: SourceNameType[];
  chosenUrl?: string;
  chosenRawUrl?: string;
  chosenPlatformSpecificId?: string;
  chosenPlatformId?: string;
  chosenStatus?: string;
  chosenProfileId?: string;
  chosenPlatformName?: string;
  chosenErrorType?: string;
  chosenErrorMessage?: string;
};

type GetValue = (id: keyof SourceTableType) => DateRangeType | string;

type ConditionalComponentProps = Pick<PropsType, 'filter' | 'onChangeFilter'> & {
  item: SourceHeadCellType;
  getValue: GetValue;
};

const properties = new Set([
  'platformId',
  'excludedPlatformId',
  'url',
  'rawUrl',
  'profileId',
  'platformSpecificId',
  'errorType',
  'errorMessage',
]);

const ConditionalComponent: FC<ConditionalComponentProps> = ({ item, filter, onChangeFilter, getValue }) => (
  <>
    {properties.has(item.id) ? (
      <TextField
        id={item.id}
        label={item.label}
        value={getValue(item.id)}
        variant="standard"
        sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}
        onChange={(event) => onChangeFilter(item.id, event.target.value)}
      />
    ) : (
      <FormControl variant="standard" sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}>
        <InputLabel id={item.id}>{item.label}</InputLabel>
        <Select
          labelId={item.id}
          id={item.id}
          value={filter.activeFilters[item.id] ?? ''}
          onChange={(event) => onChangeFilter(item.id, event.target.value)}
          label={item.label}
        >
          {item.options?.map((element: { label: string; value: boolean | number | string | undefined }) => (
            <MenuItem key={element.label} value={String(element.value) ?? 'undefined'}>
              {element.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </>
);

const Filters: FC<PropsType> = ({
  filter,
  onChangeFilter,
  onResetFilter,
  chosenUrl,
  chosenRawUrl,
  chosenPlatformSpecificId,
  chosenPlatformId,
  chosenStatus,
  chosenProfileId,
  chosenPlatformName,
  chosenErrorType,
  chosenErrorMessage,
  platformNames,
}) => {
  const filterList = headCells
    .filter((element) => element.filter)
    .map((element) => ({
      ...element,
      options: element.options,
    }));

  const getValue = (id: keyof SourceTableType) => {
    if (id === 'url') {
      return chosenUrl;
    }

    if (id === 'rawUrl') {
      return chosenRawUrl;
    }

    if (id === 'platformSpecificId') {
      return chosenPlatformSpecificId;
    }

    if (id === 'platformId') {
      return chosenPlatformId;
    }

    if (id === 'platformStatusQuery') {
      return chosenStatus;
    }

    if (id === 'profileId') {
      return chosenProfileId;
    }

    if (id === 'platformName') {
      return chosenPlatformName;
    }

    if (id === 'errorType') {
      return chosenErrorType;
    }

    if (id === 'errorMessage') {
      return chosenErrorMessage;
    }

    return filter.activeFilters[id];
  };

  return (
    <Grid
      container={true}
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        my: '20px',
        p: '15px',
        textAlign: 'center',
        width: 'inherit',
      }}
    >
      <Grid container={true}>
        {filterList
          .filter((element) => !dateLabels.includes(element.id))
          .map((item: SourceHeadCellType) => (
            <Grid key={item.id} xs={12} sm={6} lg={3} xl={2} item={true}>
              {['platformName'].includes(item.id) ? (
                <Autocomplete
                  id={item.id}
                  options={platformNames.map((element) => element.label)}
                  value={getValue(item.id)}
                  disablePortal={true}
                  onChange={(event, value) => {
                    const id = platformNames.find((element) => element.label === value)?.id ?? '';

                    return onChangeFilter(item.id, id);
                  }}
                  renderInput={(parameters) => (
                    <TextField
                      {...parameters}
                      variant="standard"
                      label={item.label}
                      sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}
                    />
                  )}
                />
              ) : (
                <ConditionalComponent
                  item={item}
                  filter={filter}
                  onChangeFilter={onChangeFilter}
                  getValue={getValue as GetValue}
                />
              )}
            </Grid>
          ))}
      </Grid>
      <Grid container={true}>
        {filterList
          .filter((element) => dateLabels.includes(element.id))
          .map((item: SourceHeadCellType) => {
            const activeFilter = filter.activeFilters[item.id];

            return (
              <Grid key={item.id} xs={12} sm={6} lg={3} xl={2} item={true}>
                <Box sx={{ m: { xs: '2rem 0 1rem' } }}>
                  <Typography variant="body1">{item.label}</Typography>
                  {['From', 'To'].map((element) => (
                    <FilterDatePicker
                      key={element}
                      activeFilter={activeFilter as DateFilterPicker}
                      onChangeFilter={onChangeFilter}
                      id={item.id as 'createdAt' | 'updatedAt'}
                      label={element as 'From' | 'To'}
                    />
                  ))}
                </Box>
              </Grid>
            );
          })}
      </Grid>
      <Grid xs={12} item={true}>
        <Button
          sx={{ m: { xs: '2.5rem 0 1.5rem', lg: '1.5rem 0' } }}
          variant="contained"
          onClick={onResetFilter}
          disableElevation={true}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filters;

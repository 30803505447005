import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getQueryParameters, getSortParameter } from 'api/helper';
import { ratingCrawlerApiSlice } from 'api/ratingCrawlerApiSlice';
import type { CustomError } from 'api/ratingCrawlerBaseQuery';
import type { SourcesData } from 'api/types';
import type { PlatformStatus } from 'pages/Platforms/types';
import type {
  GetSourcesArgumentsType,
  GetSourcesPaginationData,
  ProfileDetailsType,
  SourceState,
  StatusType,
} from 'pages/Sources/types';

const updateState = <T extends keyof SourceState>({
  dispatch,
  paginationData,
  sourceId,
  stateProperty,
  value,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, AnyAction>;
  sourceId: string;
  paginationData: GetSourcesPaginationData;
  value: SourceState[T];
  stateProperty: T;
}) =>
  dispatch(
    sourcesExtendedApi.util.updateQueryData('getSources', { ...paginationData }, (draft) => {
      const currentSource = draft.sourcesData.find((source) => source.id === sourceId);

      if (currentSource) {
        currentSource[stateProperty] = value;
      }
    }),
  );

const sourcesExtendedApi = ratingCrawlerApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSources: builder.query<SourcesData, GetSourcesArgumentsType>({
      query: ({ page, rowsPerPage, activeFilters, order, orderBy }) => {
        const queryParameters = getQueryParameters(activeFilters);
        const queryString = `&${queryParameters.join('&')}`;
        const sortString = getSortParameter(order, orderBy);

        return {
          url: `/q/v1/sources?offset=${
            page * rowsPerPage
          }&limit=${rowsPerPage}${queryString}${sortString}&withDuplicates=true`,
          method: 'GET',
        };
      },
      transformResponse: (payload: {
        data: Array<{ id: string; type: string; attributes: Omit<SourceState, 'id'> }>;
        meta: { count: number };
      }) => ({
        sourcesData: payload.data.map((item) => ({
          id: item.id,
          ...item.attributes,
          lastResultType: item.attributes.isFaulty ? item.attributes.lastError?.type : item.attributes.lastResult?.type,
          lastResultCount: item.attributes.lastResult?.rating.count,
          lastResultCrawlType: item.attributes.lastResult?.rating.crawlType,
          lastResultValue: item.attributes.lastResult?.rating.value,
          lastErrorType: item.attributes.lastError?.error.type,
          lastErrorMessage: item.attributes.lastError?.error.message,
        })),
        meta: payload.meta,
      }),
      providesTags: ['Source'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    getSourcesByProfileId: builder.query<
      {
        sourcesData: SourceState[];
        meta: { count: number };
      },
      { profileId: string; page: number; rowsPerPage: number }
    >({
      query: ({ page, profileId, rowsPerPage }) => ({
        url: `/q/v1/sources-by-profile-id?id=${profileId}&offset=${page * rowsPerPage}&limit=${rowsPerPage}`,
        method: 'GET',
      }),
      transformResponse: (payload: {
        data: Array<{ id: string; type: string; attributes: Omit<SourceState, 'id'> }>;
        meta: { count: number };
      }) => ({
        sourcesData: payload.data.map((item) => ({
          id: item.id,
          ...item.attributes,
          lastResultType: item.attributes.isFaulty ? item.attributes.lastError?.type : item.attributes.lastResult?.type,
          lastResultCount: item.attributes.lastResult?.rating.count,
          lastResultCrawlType: item.attributes.lastResult?.rating.crawlType,
          lastResultValue: item.attributes.lastResult?.rating.value,
          lastErrorType: item.attributes.lastError?.error.type,
          lastErrorMessage: item.attributes.lastError?.error.message,
        })),
        meta: payload.meta,
      }),
      providesTags: (result) =>
        result
          ? [...result.sourcesData.map((source) => ({ type: 'Source' as const, id: source.id })), 'Source']
          : ['Source'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    changeSourceIsReviewed: builder.mutation<
      undefined,
      { sourceId: string; reviewed: boolean; paginationData: GetSourcesPaginationData }
    >({
      query: ({ sourceId, reviewed }) => ({
        url: '/c/v1/change-source-reviewed',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
          reviewed,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, reviewed, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          sourceId,
          stateProperty: 'isReviewed',
          value: reviewed,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    changeSourceIsSkipped: builder.mutation<
      undefined,
      { sourceId: string; skipped: boolean; paginationData: GetSourcesPaginationData }
    >({
      query: ({ sourceId, skipped }) => ({
        url: '/c/v1/change-source-skipped',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
          skipped,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, skipped, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          sourceId,
          stateProperty: 'isSkipped',
          value: skipped,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    changeSourceUrl: builder.mutation<
      undefined,
      { sourceId: string; url: string; paginationData: GetSourcesPaginationData }
    >({
      query: ({ sourceId, url }) => ({
        url: '/c/v1/change-source-url',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
          url: decodeURIComponent(url) === url ? encodeURI(url) : url,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, url, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          sourceId,
          stateProperty: 'rawUrl',
          value: url,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    deleteSource: builder.mutation<undefined, { sourceId: string; paginationData: GetSourcesPaginationData }>({
      query: ({ sourceId }) => ({
        url: '/c/v1/delete-source',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          sourcesExtendedApi.util.updateQueryData('getSources', { ...paginationData }, (draft) => {
            const currentSourceIndex = draft.sourcesData.findIndex((source) => source.id === sourceId);

            if (currentSourceIndex !== -1) {
              draft.sourcesData.splice(currentSourceIndex, 1);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    crawlSource: builder.mutation<undefined, { sourceId: string }>({
      query: ({ sourceId }) => ({
        url: '/c/v1/crawl-source',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
        }),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    getSourceById: builder.query<SourceState, { sourceId: string }>({
      query: ({ sourceId }) => ({
        url: `/q/v1/source-by-id?id=${sourceId}`,
        method: 'GET',
      }),
      transformResponse: (payload: { data: { id: string; attributes: Omit<SourceState, 'id'> } }) => ({
        id: payload.data.id,
        ...payload.data.attributes,
      }),
      providesTags: (result) => (result ? [{ type: 'Source' as const, id: result.id }, 'Source'] : ['Source']),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    getProfiles: builder.query<ProfileDetailsType[], string[]>({
      query: (profileIds) => {
        const ids = profileIds.join('&ids=');

        return {
          url: `/q/v1/profiles?ids=${ids}`,
          method: 'GET',
        };
      },
      transformResponse: (payload: { data: ProfileDetailsType[] }) => payload.data,
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    validateUrls: builder.query<Array<StatusType['attributes'] & { id: string }>, string[]>({
      query: (urls) => {
        const urlsString = urls.join('&urls=');

        return {
          url: `/q/v1/validate-urls?urls=${urlsString}`,
          method: 'GET',
        };
      },
      transformResponse: (payload: { data: StatusType[] }) =>
        payload.data.map((item) => ({
          id: item.id,
          ...item.attributes,
        })),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    changeSourceDuplicateStatus: builder.mutation<
      undefined,
      {
        id: string;
        sourceId?: string;
        isVerified: boolean;
        paginationData: GetSourcesPaginationData;
      }
    >({
      query: ({ id, sourceId, isVerified }) => ({
        url: '/c/v1/change-duplicate-source-status',
        method: 'POST',
        body: JSON.stringify({
          id,
          sourceId,
          isVerified,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => (!arguments_.sourceId ? ['Source'] : []),
      async onQueryStarted({ sourceId, isVerified, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          sourcesExtendedApi.util.updateQueryData('getSources', { ...paginationData }, (draft) => {
            for (const source of draft.sourcesData) {
              if (source.duplicates && sourceId) {
                const relevantSource = source.duplicates.find((dupSource) => dupSource.sourceId === sourceId);

                if (relevantSource) {
                  relevantSource.isVerified = isVerified;
                }
              }
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    changeSourcePlatformStatus: builder.mutation<undefined, { id: string; status: PlatformStatus }>({
      query: ({ id, status }) => ({
        url: '/c/v1/change-platform-status',
        method: 'POST',
        body: JSON.stringify({ id, status }),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    resetSourceWarning: builder.mutation<undefined, { sourceId: string; paginationData: GetSourcesPaginationData }>({
      query: ({ sourceId }) => ({
        url: '/c/v1/reset-source-warning',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Source', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          sourceId,
          stateProperty: 'ratingWarning',
          value: 'none',
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
    resetSourceWarningByPlatformId: builder.mutation<undefined, { platformId: string }>({
      query: ({ platformId }) => ({
        url: '/c/v1/reset-source-warning-by-platform-id',
        method: 'POST',
        body: JSON.stringify({
          id: platformId,
        }),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
      invalidatesTags: [{ type: 'Source' }],
    }),
  }),
});

export const {
  useChangeSourceIsReviewedMutation,
  useChangeSourceIsSkippedMutation,
  useChangeSourceDuplicateStatusMutation,
  useChangeSourcePlatformStatusMutation,
  useChangeSourceUrlMutation,
  useCrawlSourceMutation,
  useDeleteSourceMutation,
  useGetSourcesQuery,
  useGetSourceByIdQuery,
  useLazyGetSourceByIdQuery,
  useGetProfilesQuery,
  useLazyValidateUrlsQuery,
  usePrefetch,
  useResetSourceWarningMutation,
  useResetSourceWarningByPlatformIdMutation,
} = sourcesExtendedApi;

export { sourcesExtendedApi };

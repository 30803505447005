import React from 'react';
import type { FC } from 'react';
import Typography from '@mui/material/Typography';
import type { ProfileDetailsType } from 'pages/Sources/types';

type Props = {
  profile?: ProfileDetailsType;
};

const ProfileDetails: FC<Props> = ({ profile }) => {
  const { profileName, addressParts } = profile?.attributes ?? {};

  return (
    <Typography sx={{ fontSize: 14, padding: '0 1rem' }}>
      <b>Profile name: </b> {profileName ?? '-'}, <b>address: </b>
      {!profile ? (
        '-'
      ) : (
        <>
          {addressParts?.street ?? '-'}, {addressParts?.zip ?? '-'}, {addressParts?.city ?? '-'},{' '}
          {addressParts?.state ? `${addressParts.state},` : ''} {addressParts?.country ?? '-'}
        </>
      )}
    </Typography>
  );
};

export default ProfileDetails;

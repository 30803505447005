import type { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Main from 'components/Main';
import Dashboard from 'pages/Dashboard';
import Domains from 'pages/Domains';
import DuplicateIds from 'pages/DuplicateIds';
import Login from 'pages/Login';
import Platforms from 'pages/Platforms';
import Sources from 'pages/Sources';
import { r } from './routes';

const App: FC = () => (
  <Routes>
    <Route path={r.login} element={<Login />} />
    <Route path={r.root} element={<Main />}>
      <Route path={r.root} element={<Navigate to={r.dashboard} />} />
      <Route path={r.dashboard} element={<Dashboard />} />
      <Route path={r.platforms} element={<Platforms />} />
      <Route path={r.sources} element={<Sources />} />
      <Route path={r.duplicateids} element={<DuplicateIds />} />
      <Route path={r.domains} element={<Domains />} />
    </Route>
  </Routes>
);

export default App;

const typography = () =>
  ({
    fontSize: 14,
    fontWeightExtralight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    button: {
      fontWeight: 700,
      fontSize: '1rem',
    },
  } as const);

export default typography;

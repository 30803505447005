import React from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { limitText, linkToTextRegex } from 'pages/helper';

type Props = {
  url: string;
};

const UrlCell: FC<Props> = ({ url }) => (
  <Box sx={{ width: '800px', display: 'flex', wordBreak: 'break-word', fontSize: '14px' }}>
    <Link href={url} target="_blank">
      {limitText({ text: url.replace(linkToTextRegex, ''), limit: 95 })}
    </Link>
  </Box>
);

export default UrlCell;

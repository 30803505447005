import React from 'react';
import type { ChangeEvent, FC } from 'react';
import { Switch } from '@mui/material';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import {
  useChangeSourceDuplicateStatusMutation,
  useGetProfilesQuery,
  useGetSourceByIdQuery,
} from 'api/subSlices/sourcesExtendedSlice';
import Loader from 'components/Loader';
import type { GetSourcesPaginationData } from '../types';

type Props = {
  duplicateSource: {
    sourceId: string;
    isVerified: boolean;
  };
  duplicateGroupId: string;
  paginationData: GetSourcesPaginationData;
};

const DuplicateSource: FC<Props> = ({ duplicateSource, duplicateGroupId, paginationData }) => {
  const { sourceId, isVerified } = duplicateSource;
  const { data } = useGetSourceByIdQuery({ sourceId });
  const { url, profileId } = data ?? {};
  const { data: profilesData } = useGetProfilesQuery([profileId ?? '']);
  const profileData = profilesData?.[0];
  const [changeSourceStatus] = useChangeSourceDuplicateStatusMutation();

  const handleVerifiedChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeSourceStatus({
      sourceId,
      paginationData,
      id: duplicateGroupId,
      isVerified: event.target.checked,
    });
  };

  return (
    <TableRow key={profileId}>
      {!profilesData ? (
        <TableCell colSpan={4}>
          <Loader isSidebarOpen={false} size={20} isFullTable={false} />
        </TableCell>
      ) : (
        <>
          <TableCell>
            <Link
              to={`/sources?profileId=${encodeURIComponent(profileData?.id ?? '')}`}
              component={NavLink}
              target="_blank"
              sx={{ wordBreak: 'break-word' }}
            >
              {profileData?.id}
            </Link>
          </TableCell>
          <TableCell>
            <Switch
              title={isVerified ? 'Approved' : 'Not Approved'}
              checked={isVerified}
              onChange={(event) => handleVerifiedChange(event)}
            />
          </TableCell>
          <TableCell>{profileData?.attributes.profileName ?? '-'}</TableCell>
          <TableCell>
            {profileData ? (
              <>
                <Typography sx={{ fontSize: 14 }}>{profileData.attributes.addressParts.street ?? '-'}</Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {profileData.attributes.addressParts.zip ?? '-'}, {profileData.attributes.addressParts.city ?? '-'},{' '}
                  {profileData.attributes.addressParts.state ? `${profileData.attributes.addressParts.state},` : ''}{' '}
                  {profileData.attributes.addressParts.country ?? '-'}
                </Typography>
              </>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell>
            <Link href={url} target="_blank" sx={{ wordBreak: 'break-word' }}>
              {url}
            </Link>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default DuplicateSource;

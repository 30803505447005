import { useState } from 'react';
import type { FC } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useIsAuthenticated';
import { r } from 'routes';
import { useLoginMutation } from '../../api/loginApiSlice';
import { Toast } from '../../components/toast';

const Login: FC = () => {
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const { setIsAuthenticated } = useAuth();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      await login({
        email: data.get('email') as string,
        password: data.get('password') as string,
      }).unwrap();

      setIsAuthenticated(true);

      navigate(r.dashboard);
    } catch {
      setIsToastOpen(true);
    }
  };

  return (
    <>
      <Toast isOpen={isToastOpen} onClose={() => setIsToastOpen(false)} type="error" content="bad credentials" />
      <Grid container={true} component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item={true}
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?topic=nature)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item={true} xs={12} sm={8} md={5} component={Paper} elevation={6} square={true}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate={true} onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required={true}
                fullWidth={true}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus={true}
              />
              <TextField
                margin="normal"
                required={true}
                fullWidth={true}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Grid>
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                <Button type="submit" sx={{ width: '100%', mt: 3, mb: 2 }} variant="contained" disabled={isLoading}>
                  {isLoading ? <>Loading...</> : <>Sign In</>}
                </Button>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;

import { toast } from 'react-toastify';
import type { CustomError } from 'api/ratingCrawlerBaseQuery';
import type { GetSourcesPaginationData, ResultType } from 'pages/Sources/types';
import { ratingCrawlerApiSlice } from '../ratingCrawlerApiSlice';
import { sourcesExtendedApi } from './sourcesExtendedSlice';

const resultsExtendedApi = ratingCrawlerApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResultsBySourceId: builder.query<
      {
        data: ResultType[];
        meta: {
          count: number;
        };
      },
      {
        sourceId: string;
        page: number;
        rowsPerPage: number;
        changed?: boolean;
      }
    >({
      query: ({ sourceId, page, rowsPerPage, changed }) => ({
        url: `/q/v1/results-by-source-id?id=${sourceId}&offset=${page * rowsPerPage}&limit=${rowsPerPage}${
          changed?.toString() ? `&changed=${changed.toString()}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (result, error, arguments_) =>
        result ? [{ type: 'Result' as const, id: arguments_.sourceId }, 'Result'] : ['Result'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    addResult: builder.mutation<
      undefined,
      { sourceId: string; count?: number; value?: number; paginationData: GetSourcesPaginationData }
    >({
      query: ({ sourceId, count, value }) => ({
        url: '/c/v1/add-result',
        method: 'POST',
        body: JSON.stringify({
          id: sourceId,
          rating: {
            count,
            value,
          },
        }),
      }),
      invalidatesTags: (result, error, arguments_) => [{ type: 'Result', id: arguments_.sourceId }],
      async onQueryStarted({ sourceId, count, value, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          sourcesExtendedApi.util.updateQueryData('getSources', { ...paginationData }, (draft) => {
            const currentSource = draft.sourcesData.find((source) => source.id === sourceId);

            if (currentSource) {
              currentSource.lastResultType = 'success';
              currentSource.lastResultCount = count;
              currentSource.lastResultValue = value;
              currentSource.lastResultCrawlType = 'manual';
              currentSource.lastRunAt = new Date().toISOString();
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetResultsBySourceIdQuery, useLazyGetResultsBySourceIdQuery, useAddResultMutation } =
  resultsExtendedApi;

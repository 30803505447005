import React from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { capitalizeFirstLetter } from 'pages/helper';

type Props = {
  lastResultType?: 'error' | 'success';
  lastErrorType?: string;
  lastErrorMessage?: string;
};

const LastResultCell: FC<Props> = ({ lastResultType, lastErrorType, lastErrorMessage }) => {
  const capitalizedLastResult = capitalizeFirstLetter(lastResultType);

  return lastResultType ? (
    lastResultType === 'error' ? (
      <Tooltip
        placement="right"
        arrow={true}
        title={
          <Box sx={{ p: '0.5rem' }}>
            <Typography sx={{ fontSize: 14 }}>
              <b>Error type: </b>
              {lastErrorType}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Error message: </b>
              {lastErrorMessage}
            </Typography>
          </Box>
        }
      >
        <Chip
          label={capitalizedLastResult}
          color={lastResultType}
          size="small"
          style={{ fontWeight: 'bold', cursor: 'pointer' }}
        />
      </Tooltip>
    ) : (
      <Chip label={capitalizedLastResult} color={lastResultType} size="small" style={{ fontWeight: 'bold' }} />
    )
  ) : null;
};

export default LastResultCell;

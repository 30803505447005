import { useState } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormik } from 'formik';
import {
  useChangePlatformDelaysMutation,
  useChangePlatformIsActiveMutation,
  useChangePlatformIsHiddenMutation,
  useChangePlatformIsSkippingInactivesMutation,
  useChangePlatformProxyMutation,
  useChangePlatformResourceTypeMutation,
} from 'api/subSlices/platformsExtendedSlice';
import { useResetSourceWarningByPlatformIdMutation } from 'api/subSlices/sourcesExtendedSlice';
import Header from 'components/Header';
import platformSettingsSchema from 'pages/Platforms/schemas/platformSettingsSchema';
import type { GetPlatformsArgumentsType, SettingsType } from '../../types';
import DelaySettings from './settings/DelaySettings';
import ProxySettings from './settings/ProxySettings';
import RestrictResourceSettings from './settings/RestrictResourceSettings';
import StatusSettings from './settings/StatusSettings';

type Props = {
  settings: SettingsType;
  paginationData: GetPlatformsArgumentsType;
};

const PlatformSettings: FC<Props> = ({ settings, paginationData }) => {
  const { id, isActive, isHidden, isSkippingInactives } = settings;
  const [openSettings, setOpenSettings] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [changePlatformDelays] = useChangePlatformDelaysMutation();
  const [resetSourcesWarning] = useResetSourceWarningByPlatformIdMutation();

  const [delayActive, setDeleyActive] = useState(settings.delayActive);
  const [delayInactive, setDeleyInactive] = useState(settings.delayInactive);
  const [delayFailedActive, setDeleyFailedActive] = useState(settings.delayFailedActive);
  const [delayFailedInactive, setDeleyFailedInactive] = useState(settings.delayFailedInactive);
  const [intervalCycle, setIntervalCycle] = useState(settings.intervalCycle);
  const [intervalItemCount, setIntervalItemCount] = useState(settings.intervalItemCount);

  const actions = {
    setDeleyActive,
    setDeleyInactive,
    setDeleyFailedActive,
    setDeleyFailedInactive,
    setIntervalCycle,
    setIntervalItemCount,
  };

  const handleSettingsOpen = () => {
    setOpenSettings(true);
  };
  const handleSettingsClose = (reset?: boolean) => {
    setOpenSettings(false);
    formik.resetForm();

    if (reset) {
      setDeleyActive(settings.delayActive);
      setDeleyInactive(settings.delayInactive);
      setDeleyFailedActive(settings.delayFailedActive);
      setDeleyFailedInactive(settings.delayFailedInactive);
      setIntervalCycle(settings.intervalCycle);
      setIntervalItemCount(settings.intervalItemCount);
    }
  };

  const [changePlatformIsActive] = useChangePlatformIsActiveMutation();
  const [changePlatformIsHidden] = useChangePlatformIsHiddenMutation();
  const [changePlatformIsSkippingInactives] = useChangePlatformIsSkippingInactivesMutation();
  const [changePlatformProxy] = useChangePlatformProxyMutation();
  const [changePlatformResourceType] = useChangePlatformResourceTypeMutation();

  const handleSubmit = (values: SettingsType) => {
    if (values.isActive !== formik.initialValues.isActive) {
      changePlatformIsActive({ id, active: values.isActive, paginationData });
    }

    if (values.isHidden !== formik.initialValues.isHidden) {
      changePlatformIsHidden({ id, hidden: values.isHidden, paginationData });
    }

    if (values.isSkippingInactives !== formik.initialValues.isSkippingInactives) {
      changePlatformIsSkippingInactives({ id, skip: values.isSkippingInactives, paginationData });
    }

    if (values.proxy !== formik.initialValues.proxy) {
      changePlatformProxy({ id, proxy: values.proxy, paginationData });
    }

    if (values.restrictResourceType !== formik.initialValues.restrictResourceType) {
      if (values.restrictResourceType === undefined || values.restrictResourceType.length === 0) {
        changePlatformResourceType({ id, paginationData });
      } else {
        changePlatformResourceType({ id, restrictResourceType: values.restrictResourceType, paginationData });
      }
    }

    if (delayActive !== settings.delayActive) {
      changePlatformDelays({ id, delay: { active: delayActive }, paginationData });
    }

    if (delayFailedActive !== settings.delayFailedActive) {
      changePlatformDelays({ id, delay: { failedActive: delayFailedActive }, paginationData });
    }

    if (delayInactive !== settings.delayInactive) {
      changePlatformDelays({ id, delay: { inactive: delayInactive }, paginationData });
    }

    if (delayFailedInactive !== settings.delayFailedInactive) {
      changePlatformDelays({ id, delay: { failedInactive: delayFailedInactive }, paginationData });
    }

    if (intervalCycle !== settings.intervalCycle) {
      changePlatformDelays({ id, intervalCycle, paginationData });
    }

    if (intervalItemCount !== settings.intervalItemCount) {
      changePlatformDelays({ id, intervalItemCount, paginationData });
    }

    handleSettingsClose();
  };

  const formik = useFormik({
    initialValues: settings,
    enableReinitialize: true,
    validationSchema: platformSettingsSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleResetWarning = () => {
    resetSourcesWarning({ platformId: id });
  };

  return (
    <div>
      <Button variant="text" size="small" onClick={handleSettingsOpen} sx={{ textTransform: 'initial' }}>
        Settings
      </Button>
      <Dialog
        onClose={() => handleSettingsClose(true)}
        aria-labelledby="customized-dialog-title"
        open={openSettings}
        fullScreen={isMobile}
        sx={{
          '& .MuiPaper-root': {
            width: '700px',
            maxWidth: '700px',
          },
          '& .MuiDialogContent-root': {
            p: (theme) => theme.spacing(3),
          },
        }}
      >
        <Header id="customized-dialog-title" onClose={() => handleSettingsClose(true)}>
          Settings
        </Header>
        <DialogContent dividers={false}>
          <form
            onSubmit={formik.handleSubmit}
            style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
          >
            <Grid container={true}>
              <Grid item={true} xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }}>
                <Button
                  type="button"
                  variant="contained"
                  disableElevation={true}
                  fullWidth={isMobile}
                  onClick={handleResetWarning}
                >
                  Reset Rating Warning
                </Button>
              </Grid>
              <StatusSettings
                formik={formik}
                isActive={isActive}
                isHidden={isHidden}
                isSkippingInactives={isSkippingInactives}
              />
              <ProxySettings formik={formik} />
              <RestrictResourceSettings formik={formik} />
              <DelaySettings settings={settings} actions={actions} intervalItemCount={intervalItemCount} />
            </Grid>
            <Box
              sx={{
                p: { xs: '2.5rem 0.5rem 1rem', sm: '3rem 0.75rem 1.5rem' },
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button type="submit" variant="contained" disableElevation={true} fullWidth={isMobile}>
                Save changes
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PlatformSettings;

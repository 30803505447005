const palette = () =>
  ({
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    primary: {
      main: '#0db1cd',
      light: '#f1fbfc',
      dark: '#0a97b0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#c8b274',
      light: '#f6f3ea',
      dark: '#a79563',
      contrastText: '#ffffff',
    },
    error: {
      light: '#fbdfdd',
      main: '#e92e21',
      dark: '#c12b21',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#fff2d9',
      main: '#ffa800',
      dark: '#d38d00',
      contrastText: '#ffffff',
    },
    info: {
      light: '#f2f2d7',
      main: '#b0b013',
      dark: '#91910e',
      contrastText: '#ffffff',
    },
    success: {
      light: '#def6eb',
      main: '#25c47c',
      dark: '#24a36a',
      contrastText: '#ffffff',
    },
    grey: {
      200: '#eeeeee',
      500: '#9e9e9e',
      700: '#616161',
      800: '#444444',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      light: '#aaaaaa',
      main: '#eeeeee',
      dark: '#d5d5d5',
      contrastText: '#444444',
    },
    white: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#eeeeee',
      contrastText: '#444444',
    },
    text: {
      primary: '#444444',
      secondary: '#333444',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    background: {
      paper: '#ffffff',
      default: '#f5f5f5',
    },
    gradient: {
      green: 'linear-gradient(291deg, #35a9a6 30%, #25c47c)',
      primary: 'linear-gradient(304deg, #0a97b0 93%, #0db1cd)',
      mask: {
        primary: 'linear-gradient(295deg, rgba(53, 169, 166, 0.9) 20%, rgba(13,177,205, 0.9))',
      },
      blue: 'linear-gradient(279deg, #35a9a6, #0db1cd)',
    },
  } as const);

export default palette;

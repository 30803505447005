import type { FC } from 'react';
import React from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import type { ChartData } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import type { ChartOptions } from 'types';
import type { TimeWindow } from '../types';

type Props = {
  data: ChartData<'line', Array<number | null | undefined>, string>;
  options: ChartOptions;
  headerText: string;
  textRed: string;
  timeWindow: TimeWindow;
  handleChange: (event: SelectChangeEvent<TimeWindow>) => void;
  textBlue?: string;
};

const ChartCell: FC<Props> = ({ data, options, textRed, textBlue, headerText, timeWindow, handleChange }) => (
  <Paper sx={{ ml: '3rem', mt: '1rem', p: '1rem', flex: 3 }}>
    <Typography variant="body1" sx={{ pb: '1rem' }}>
      {headerText}
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControl sx={{ width: '175px' }}>
        <InputLabel id="time window select label">Time Window</InputLabel>
        <Select
          labelId="time-window-select-label"
          id="time-window-select"
          name="time-window"
          value={timeWindow}
          label="Time Window"
          onChange={(event) => handleChange(event)}
        >
          <MenuItem value="1week">1 Week</MenuItem>
          <MenuItem value="2week">2 Weeks</MenuItem>
          <MenuItem value="3week">3 Weeks</MenuItem>
          <MenuItem value="month-1d">Month by Day</MenuItem>
          <MenuItem value="month-3d">Month by 3 Day</MenuItem>
          <MenuItem value="3month">3 Months</MenuItem>
          <MenuItem value="year">Year</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
        <Box sx={{ minWidth: '1rem', minHeight: '1rem', borderRadius: '50%', backgroundColor: 'red' }} />
        <Typography sx={{ marginLeft: '0.5rem', fontSize: 12 }}>{textRed}</Typography>
      </Box>
      {textBlue && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
          <Box sx={{ minWidth: '1rem', minHeight: '1rem', borderRadius: '50%', backgroundColor: 'blue' }} />
          <Typography sx={{ marginLeft: '0.5rem', fontSize: 12 }}>{textBlue}</Typography>
        </Box>
      )}
    </Box>

    <Box>
      <Chart data={data} options={options} type="line" />
    </Box>
  </Paper>
);

export { ChartCell };

import React, { useState } from 'react';
import type { FC } from 'react';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useChangeSourceDuplicateStatusMutation } from 'api/subSlices/sourcesExtendedSlice';
import Header from 'components/Header';
import type { GetSourcesPaginationData } from '../types';
import DuplicateSource from './DuplicateSource';

type Props = {
  duplicates: Array<{ sourceId: string; isVerified: boolean }>;
  duplicateGroupId: string;
  paginationData: GetSourcesPaginationData;
};

const Duplicates: FC<Props> = ({ duplicates, duplicateGroupId, paginationData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [changeSourceStatus] = useChangeSourceDuplicateStatusMutation();

  const getDuplicateGroupColor = () => {
    const verifiedSourcesNumber = duplicates.reduce((pV, cV) => {
      if (cV.isVerified) {
        // eslint-disable-next-line no-param-reassign
        pV += 1;
      }

      return pV;
    }, 0);

    if (duplicates.length > 0 && verifiedSourcesNumber === duplicates.length) {
      return 'success';
    }

    if (verifiedSourcesNumber === 0) {
      return 'error';
    }

    return 'warning';
  };

  const handleDuplicatesOpen = () => {
    setIsOpen(true);
  };

  const handleDuplicatesClose = () => {
    setIsOpen(false);
  };

  const setGroupStatusTrue = () => {
    changeSourceStatus({ id: duplicateGroupId, isVerified: true, paginationData });
  };

  const setGroupStatusFalse = () => {
    changeSourceStatus({ id: duplicateGroupId, isVerified: false, paginationData });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Chip
        label={duplicates.length}
        color={getDuplicateGroupColor()}
        size="small"
        style={{ fontWeight: 'bold' }}
        onClick={handleDuplicatesOpen}
      />
      <Dialog
        onClose={handleDuplicatesClose}
        aria-labelledby="duplicates"
        open={isOpen}
        fullScreen={isMobile}
        sx={{
          '& .MuiPaper-root': {
            width: '700px',
            maxWidth: '700px',
          },
          '& .MuiDialogContent-root': {
            p: (theme) => theme.spacing(3),
          },
        }}
      >
        <Header id="customized-dialog-title" onClose={handleDuplicatesClose}>
          Duplicates
        </Header>
        <DialogContent dividers={false} sx={{ mb: '20px' }}>
          <Box sx={{ maxWidth: '100px', display: 'flex' }}>
            <IconButton
              title="click to switch all sources in this group to status verified"
              onClick={setGroupStatusTrue}
              color="primary"
            >
              <ThumbUp />
            </IconButton>
            <IconButton
              title="click to switch all sources in this group to status not verified"
              onClick={setGroupStatusFalse}
              color="default"
            >
              <ThumbDown />
            </IconButton>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Profile ID
                      <Tooltip title="Click the id to see all sources of this profile" arrow={true}>
                        <IconButton>
                          <InfoIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>Approval Status</TableCell>
                  <TableCell>Profile Name</TableCell>
                  <TableCell>Profile Address</TableCell>
                  <TableCell>Url</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {duplicates.map((duplicateSource) => (
                  <DuplicateSource
                    key={duplicateSource.sourceId}
                    duplicateSource={duplicateSource}
                    duplicateGroupId={duplicateGroupId}
                    paginationData={paginationData}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Duplicates;

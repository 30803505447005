import React, { useMemo } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getQuickFilterName, getQuickFilterValue } from 'pages/helper';
import type { SourceTableType, SourceHeadCellType } from '../types';
import headCells from './headCells';

type Props = {
  onChangeFilter: (name: keyof SourceTableType, value: string) => void;
  onResetFilter: () => void;
};

const QuickFilters: FC<Props> = ({ onChangeFilter, onResetFilter }) => {
  const filterListMemoized: SourceHeadCellType[] = useMemo(
    () => headCells.filter((element) => element.quickFilter),
    [],
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', marginTop: '0.3rem' }}>
      <Button sx={{ fontSize: '0.75rem', color: '#444444' }} onClick={onResetFilter}>
        all
      </Button>
      {filterListMemoized.map((item) => (
        <Button
          key={item.id}
          onClick={() => onChangeFilter(getQuickFilterName(item.id), getQuickFilterValue(item.id))}
          sx={{ fontSize: '0.75rem' }}
        >
          {item.quickFilterLabel ?? item.label}
        </Button>
      ))}
    </Box>
  );
};

export default QuickFilters;

import { configureStore } from '@reduxjs/toolkit';
import { ratingCrawlerApiSlice } from 'api/ratingCrawlerApiSlice';
import { loginApiSlice } from './api/loginApiSlice';
import reducer from './reducer';

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload'],
        ignoredPaths: ['ratingCrawlerApi.mutations'],
      },
    }).concat(ratingCrawlerApiSlice.middleware, loginApiSlice.middleware),
});

export default store;

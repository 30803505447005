import React from 'react';
import type { FC } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { convertTimeToString } from '../helper';
import type { DetailsType, PlatformTimeType } from '../types';
import { ErrorChart } from './ErrorChart';
import { NewSourcesChart } from './NewSourcesChart';
import { RatingWarningChart } from './RatingWarningChart';

type Props = {
  isOpenSources: boolean;
  details: DetailsType;
};

const getDelayLabel = (key: keyof PlatformTimeType) => {
  switch (key) {
    case 'delayActive':
      return 'active';

    case 'delayFailedActive':
      return 'failed active';

    case 'delayInactive':
      return 'inactive';
    default:
      return 'failed inactive';
  }
};

const Details: FC<Props> = ({ isOpenSources, details }: Props) => {
  const {
    url,
    updatedAt,
    createdAt,
    delay,
    intervalCycle,
    intervalItemCount,
    comment,
    profileRatingType,
    bestRating,
    worstRating,
    hasExternalReviews,
    ratingBasedOnLastMonthCount,
    numberOfMonths,
    monitoringData,
  } = details;

  const convertedDelays: PlatformTimeType = {
    delayActive: convertTimeToString(delay.active),
    delayFailedActive: convertTimeToString(delay.failedActive),
    delayInactive: convertTimeToString(delay.inactive),
    delayFailedInactive: convertTimeToString(delay.failedInactive),
  };

  return (
    <TableCell sx={{ pb: 0, pt: 0, backgroundColor: (t) => t.palette.common.white }} colSpan={13}>
      <Collapse in={isOpenSources} timeout="auto" unmountOnExit={false}>
        <hr />
        <Box sx={{ display: 'flex', pb: '1rem', gap: '1rem' }}>
          <Paper sx={{ mt: '1rem', textAlign: 'left', lineHeight: 1.5, p: '1rem', flex: 1 }}>
            <Typography variant="body1" sx={{ pb: '1rem' }}>
              Platform additional information:
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>URL:</b> {url}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Created at:</b> {createdAt ? new Date(createdAt).toLocaleDateString('de-DE') : '-'}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Updated at:</b> {updatedAt ? new Date(updatedAt).toLocaleDateString('de-DE') : '-'}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Rating Type:</b> {profileRatingType === 'employeeRatings' ? 'Employee Rating' : 'Customer Rating'}
            </Typography>
            {hasExternalReviews === true ? (
              <Typography sx={{ fontSize: 14, color: (t) => t.palette.warning.main }}>
                <b>External Reviews Filtered Out!</b>
              </Typography>
            ) : null}
            {worstRating !== undefined && (
              <Typography sx={{ fontSize: 14, color: (t) => t.palette.warning.main }}>
                <b>Worst Rating:</b> {worstRating}
              </Typography>
            )}
            {bestRating !== undefined && (
              <Typography sx={{ fontSize: 14, color: (t) => t.palette.warning.main }}>
                <b>Best Rating:</b> {bestRating}
              </Typography>
            )}
            {ratingBasedOnLastMonthCount === true && (
              <Typography sx={{ fontSize: 14, color: (t) => t.palette.warning.main }}>
                <b>Rating Value is based on last the {numberOfMonths} months!</b>
              </Typography>
            )}
            {comment !== '' && (
              <Typography sx={{ fontSize: 14 }}>
                <b>Comment:</b> {comment}
              </Typography>
            )}
            <Typography sx={{ fontSize: 14 }}>
              <b>Interval Cycle:</b> {convertTimeToString(intervalCycle)}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              <b>Interval Item Count:</b> {intervalItemCount}
            </Typography>
            <List disablePadding={true}>
              <Typography sx={{ fontSize: 14 }}>
                <b>Delay:</b>
              </Typography>
              {Object.entries(convertedDelays).map(([key, value]) => (
                <ListItem key={key} disablePadding={true}>
                  <ListItemIcon sx={{ minWidth: '2rem', pl: '1rem' }}>
                    <CircleIcon sx={{ width: '0.5rem', height: '0.5rem', color: '#444444' }} />
                  </ListItemIcon>
                  <Typography sx={{ fontSize: 14 }}>
                    <b>{getDelayLabel(key as keyof PlatformTimeType)}:</b> {value ?? '-'}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
          {monitoringData && (
            <Box display="flex" sx={{ flex: 9, gap: '1rem' }}>
              <ErrorChart monitoringData={monitoringData} />
              <RatingWarningChart monitoringData={monitoringData} />
              <NewSourcesChart monitoringData={monitoringData} />
            </Box>
          )}
        </Box>
      </Collapse>
    </TableCell>
  );
};

export default Details;

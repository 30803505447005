import type { ChangeEvent, FC, MouseEvent } from 'react';
import React from 'react';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { useSelector } from 'react-redux';
import { useGetDuplicateSourcesQuery } from 'api/subSlices/duplicateSourcesExtendedSlice';
import Loader from 'components/Loader';
import useFilters from 'hooks/useFilters';
import usePaginationData from 'hooks/usePaginationData';
import type { SourceFilterNamesType } from 'pages/Sources/types';
import { getSidebarOpen } from 'settingsReducer';
import type { DateFilterPicker } from 'types';
import EnhancedTableHead from './components/EnhancedTableHead';
import EnhancedToolbar from './components/EnhancedToolbar';
import Row from './components/Row';
import type { DuplicateIdsFilterType, getDuplicateSourcesArgumentsType } from './types';

const DuplicateIds: FC = () => {
  const isSidebarOpen = useSelector(getSidebarOpen);

  const [paginationData, setPaginationData] = usePaginationData<never, DuplicateIdsFilterType['activeFilters']>();

  const { isActive, setIsActive, resetFilters, activeFilters, changeFilter } = useFilters<SourceFilterNamesType>({
    onChangeCallback: (data) => {
      setPaginationData({ type: 'setActiveFilters', payload: data });
    },
  });

  const { data, isLoading, isFetching } = useGetDuplicateSourcesQuery(paginationData);

  const { duplicatesData = [], meta = { count: 0 } } = data ?? {};
  const totalCount = meta.count;

  const toggleFilters = () => {
    setIsActive((previous) => !previous);
  };

  const onChangeFilter = (name: SourceFilterNamesType, value: DateFilterPicker | string) => {
    changeFilter([{ name, value }]);
  };

  const onDeleteChip = (names: SourceFilterNamesType[]) => {
    changeFilter(names.map((name) => ({ name, value: '' })));
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationData({ type: 'setPage', payload: newPage });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPaginationData({ type: 'setRowsPerPage', payload: Number.parseInt(event.target.value, 10) });
    setPaginationData({ type: 'setPage', payload: 0 });
  };

  return (
    <>
      <EnhancedToolbar
        filter={{ activeFilters, active: isActive }}
        onChangeFilter={onChangeFilter}
        onResetFilter={resetFilters}
        toggleFilters={toggleFilters}
        onDeleteChip={onDeleteChip}
        chosenGroupId={activeFilters.groupId}
        chosenPlatformSpecificId={activeFilters.platformSpecificId}
        chosenCount={paginationData?.activeFilters?.count}
        chosenStatus={paginationData?.activeFilters?.status}
      />
      <Paper sx={{ m: { xs: '10px 0 20px 0', sm: '10px 20px 20px' }, p: '0 15px' }}>
        <TableContainer sx={{ height: 'calc(100vh - 180px)' }}>
          <Table aria-label="collapsible table" stickyHeader={true}>
            <EnhancedTableHead />
            <TableBody sx={isFetching && !isLoading ? { opacity: '0.75' } : null}>
              {isLoading ? (
                <Loader isSidebarOpen={isSidebarOpen} size={40} isFullTable={true} />
              ) : (
                <>
                  {duplicatesData.map((duplicateGroup) => (
                    <Row
                      key={duplicateGroup.id}
                      row={duplicateGroup}
                      paginationData={paginationData as getDuplicateSourcesArgumentsType}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={paginationData.rowsPerPage}
            page={paginationData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ position: 'sticky', bottom: 0, backgroundColor: (theme) => theme.palette.common.white }}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default DuplicateIds;

import type { PlatformHeadCellType } from '../types';
import HeadCellLabel from './HeadCellLabel';

const headCells: PlatformHeadCellType[] = [
  { id: 'name', label: 'Platform', sortable: true, filter: true },
  {
    id: 'isActive',
    label: 'Active',
    filter: true,
    options: [
      { label: 'active', value: true },
      { label: 'inactive', value: false },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'isHidden',
    label: 'Hidden',
    filter: true,
    options: [
      { label: 'hidden', value: true },
      { label: 'visible', value: false },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'isSkippingInactives',
    label: 'Skipping inactives',
    filter: true,
    options: [
      { label: 'skipping', value: true },
      { label: 'not skipping', value: false },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'averageActiveIntervalNote',
    label: HeadCellLabel({
      title: 'A Int',
      text: 'Shows the average crawling delay for Active sources',
    }) as JSX.Element,
    sortable: true,
    filter: true,
    filterLabel: 'Average Active',
  },
  {
    id: 'averageFailedActiveIntervalNote',
    label: HeadCellLabel({
      title: 'FA Int',
      text: 'Shows the average crawling delay for Failed Active sources',
    }) as JSX.Element,
    sortable: true,
    filter: true,
    filterLabel: 'Average Failed Active',
  },
  {
    id: 'averageInactiveIntervalNote',
    label: HeadCellLabel({
      title: 'IA Int',
      text: 'Shows the average crawling delay for Inactive sources',
    }) as JSX.Element,
    sortable: true,
    filter: true,
    filterLabel: 'Average Inactive',
  },
  {
    id: 'averageFailedInactiveIntervalNote',
    label: HeadCellLabel({
      title: 'FIA Int',
      text: 'Shows the average crawling delay for Failed Inactive sources',
    }) as JSX.Element,
    sortable: true,
    filter: true,
    filterLabel: 'Average Failed Inactive',
  },
  {
    id: 'totalSourcesCount',
    label: 'Sources',
    sortable: true,
  },
  {
    id: 'activeSourcesCount',
    label: 'Active sources',
    sortable: true,
  },
  {
    id: 'skippedSourcesCount',
    label: 'Skipped sources',
    sortable: true,
  },
  { id: 'urlSearchPattern', label: 'Url search pattern', hideColumn: true },
  {
    id: 'proxy',
    label: 'Proxy',
    filter: true,
    options: [
      { label: 'disabled', value: 'disabled' },
      { label: 'zyte', value: 'zyte' },
      { label: 'zyte2', value: 'zyte2' },
      { label: 'provenexpert', value: 'provenexpert' },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'crawlerType',
    label: 'Crawler Type',
    filter: true,
    options: [
      { label: 'Puppeteer', value: 'puppeteer' },
      { label: 'Serp', value: 'serp' },
      { label: '-', value: '' },
    ],
    hideColumn: true,
  },
  { id: 'createdAt', label: 'Created', filter: true, sortable: true, hideColumn: true },
  { id: 'updatedAt', label: 'Updated', filter: true, sortable: true, hideColumn: true },
];

export default headCells;

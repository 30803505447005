export const getAvatarBgColor = (origin?: string): string => {
  switch (origin) {
    case 'customer':
      return '#0DB1CD';

    case 'support':
      return '#9F9F9F';

    default:
      return '#9F9F9F';
  }
};

import React from 'react';
import type { FC } from 'react';
import Chip from '@mui/material/Chip';
import CommonEnhancedToolbar from 'components/EnhancedToolbar';
import type { ChipType, DateFilterPicker } from 'types';
import { convertToColumnName, getChipLabel } from '../../helper';
import type { DateRangeType, SourceFilterNamesType, SourceFilterType, SourceTableType, SourceNameType } from '../types';
import Filters from './Filters';
import headCells from './headCells';
import QuickFilters from './QuickFilters';

type Props = {
  filter: SourceFilterType;
  onChangeFilter: (name: SourceFilterNamesType, value: DateFilterPicker | string) => void;
  onResetFilter: () => void;
  toggleFilters: () => void;
  onDeleteChip: (name: SourceFilterNamesType[]) => void;
  chosenUrl: string;
  chosenRawUrl: string;
  chosenPlatformSpecificId: string;
  chosenPlatformId: string;
  chosenStatus: string;
  chosenProfileId: string;
  chosenPlatformName: string;
  platformNames: SourceNameType[];
  chosenErrorType: string;
  chosenErrorMessage: string;
};

const EnhancedToolbar: FC<Props> = ({
  filter,
  onDeleteChip,
  onChangeFilter,
  onResetFilter,
  toggleFilters,
  chosenUrl,
  chosenRawUrl,
  chosenPlatformSpecificId,
  chosenPlatformId,
  chosenStatus,
  chosenProfileId,
  chosenPlatformName,
  chosenErrorType,
  chosenErrorMessage,
  platformNames,
}) => {
  const onDelete = ({ key, columnNames }: { key: string; columnNames: SourceFilterNamesType[] }) => {
    if (columnNames.length > 0) {
      onDeleteChip(columnNames);
    } else {
      onDeleteChip([key as keyof SourceTableType]);
    }
  };

  const chips = Object.entries(filter.activeFilters).reduce<
    Array<{ key: string; value: DateRangeType | string; columnNames: SourceFilterNamesType[] }>
  >((pV, [key, value]) => {
    const dateFilterIndex = pV.findIndex((activeFilter) => activeFilter.key === convertToColumnName(key));

    if (typeof value !== 'string') {
      return pV;
    }

    const columnName = key as SourceFilterNamesType;

    if (key.includes('min-')) {
      if (dateFilterIndex >= 0) {
        const current = pV[dateFilterIndex] as ChipType;

        current.value.dateFrom = value;
        current.columnNames.push(columnName);
      } else {
        pV.push({ key: convertToColumnName(key), columnNames: [columnName], value: { dateFrom: value, dateTo: null } });
      }
    } else if (key.includes('max-')) {
      if (dateFilterIndex >= 0) {
        const current = pV[dateFilterIndex] as ChipType;

        current.value.dateTo = value;
        current.columnNames.push(columnName);
      } else {
        pV.push({ key, columnNames: [columnName], value: { dateTo: value, dateFrom: null } });
      }
    } else {
      pV.push({ key, value, columnNames: [] });
    }

    return pV;
  }, []);

  return (
    <CommonEnhancedToolbar
      toggleFilters={toggleFilters}
      filter={{
        component: (
          <Filters
            onChangeFilter={onChangeFilter}
            onResetFilter={onResetFilter}
            filter={filter}
            chosenUrl={chosenUrl}
            chosenRawUrl={chosenRawUrl}
            chosenPlatformId={chosenPlatformId}
            chosenStatus={chosenStatus}
            chosenProfileId={chosenProfileId}
            chosenPlatformName={chosenPlatformName}
            platformNames={platformNames}
            chosenPlatformSpecificId={chosenPlatformSpecificId}
            chosenErrorType={chosenErrorType}
            chosenErrorMessage={chosenErrorMessage}
          />
        ),
        isActive: filter.active,
      }}
    >
      {chips.map(
        ({ key, value, columnNames }) =>
          value && (
            <Chip
              key={key}
              label={getChipLabel({ key: key as keyof SourceTableType, value, headCells, platformNames })}
              size="medium"
              sx={{ m: '5px 10px 5px 0' }}
              onDelete={() => onDelete({ key, columnNames })}
            />
          ),
      )}

      <QuickFilters onChangeFilter={onChangeFilter} onResetFilter={onResetFilter} />
    </CommonEnhancedToolbar>
  );
};

export default EnhancedToolbar;

import { useState } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { convertData } from 'pages/Platforms/helper';
import type { PlatformMonitoring, TimeWindow } from 'pages/Platforms/types';

const useChartTimeWindow = (
  monitoringData: PlatformMonitoring[],
): {
  timeWindow: TimeWindow;
  handleChange: (event: SelectChangeEvent<TimeWindow>) => void;
  displayData: PlatformMonitoring[];
} => {
  const [timeWindow, setTimeWindow] = useState<TimeWindow>('1week');

  const handleChange = (event: SelectChangeEvent<TimeWindow>) => {
    setTimeWindow(event.target.value as TimeWindow);
  };

  const displayData = convertData({ timeWindow, monitoringData });

  return { timeWindow, handleChange, displayData };
};

export { useChartTimeWindow };

import type { Dispatch, Reducer } from 'react';
import { useReducer } from 'react';
import type { Order } from 'types';

type PaginationData<T, K> = {
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: keyof T | '';
  activeFilters?: K;
};

type InitialParameters<T, K> = {
  orderByInitValue?: keyof T;
  initialFilters?: K;
};

type useSetPaginationDataReturn<T, K> = [state: PaginationData<T, K>, dispatch: Dispatch<Action<T, K>>];

type Action<T, K> =
  | { type: 'setActiveFilters'; payload: K }
  | { type: 'setOrder'; payload: Order }
  | { type: 'setOrderBy'; payload: keyof T }
  | { type: 'setPage'; payload: number }
  | { type: 'setRowsPerPage'; payload: number };

function reducer<T, K>(state: PaginationData<T, K>, action: Action<T, K>): PaginationData<T, K> {
  switch (action.type) {
    case 'setActiveFilters':
      return { ...state, activeFilters: { ...state.activeFilters, ...action.payload } };

    case 'setOrder':
      return { ...state, order: action.payload };

    case 'setOrderBy':
      return { ...state, orderBy: action.payload };

    case 'setPage':
      return { ...state, page: action.payload };

    case 'setRowsPerPage':
      return { ...state, rowsPerPage: action.payload };
    default:
      return state;
  }
}

const usePaginationData = <T, K>(initialParameters?: InitialParameters<T, K>): useSetPaginationDataReturn<T, K> => {
  const initalState: PaginationData<T, K> = {
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: initialParameters?.orderByInitValue ?? '',
    activeFilters: initialParameters?.initialFilters ? { ...initialParameters.initialFilters } : undefined,
  };

  const [state, dispatch] = useReducer<Reducer<PaginationData<T, K>, Action<T, K>>>(reducer, initalState);

  return [state, dispatch];
};

export default usePaginationData;

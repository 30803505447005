import type { FC } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CommonEnhancedToolbar from 'components/EnhancedToolbar';
import headCells from 'pages/Domains/components/headCells';
import { convertToColumnName, getChipLabel } from 'pages/helper';
import type {
  PlatformFilterType,
  PlatformTableType,
  PlatformFilterNamesType,
  PlatformNameType,
} from 'pages/Platforms/types';
import type { DateRangeType } from 'pages/types';
import type { DateFilterPicker } from 'types';
import Filters from './Filters';

type Props = {
  filter: PlatformFilterType;
  onChangeFilter: (name: PlatformFilterNamesType, value: DateFilterPicker | string) => void;
  onResetFilter: () => void;
  toggleFilters: () => void;
  onDeleteChip: (name: PlatformFilterNamesType[]) => void;
  chosenPlatform: string;
  chosenUrlSearchPattern: string;
  platformNames: PlatformNameType[];
};

type ChipType = { key: string; value: { dateFrom: string | null; dateTo: string | null }; columnNames: string[] };

const EnhancedToolbar: FC<Props> = ({
  filter,
  onDeleteChip,
  onChangeFilter,
  onResetFilter,
  toggleFilters,
  chosenPlatform,
  chosenUrlSearchPattern,
  platformNames,
}) => {
  const chips = Object.entries(filter.activeFilters).reduce<
    Array<{ key: string; value: DateRangeType | string; columnNames: PlatformFilterNamesType[] }>
  >((pV, [key, value]) => {
    const dateFilterIndex = pV.findIndex((activeFilter) => activeFilter.key === convertToColumnName(key));

    if (typeof value !== 'string') {
      return pV;
    }

    const columnName = key as PlatformFilterNamesType;

    if (key.includes('min-')) {
      if (dateFilterIndex >= 0) {
        const current = pV[dateFilterIndex] as ChipType;

        current.value.dateFrom = value;
        current.columnNames.push(columnName);
      } else {
        pV.push({ key: convertToColumnName(key), columnNames: [columnName], value: { dateFrom: value, dateTo: null } });
      }
    } else if (key.includes('max-')) {
      if (dateFilterIndex >= 0) {
        const current = pV[dateFilterIndex] as ChipType;

        current.value.dateTo = value;
        current.columnNames.push(columnName);
      } else {
        pV.push({ key, columnNames: [columnName], value: { dateTo: value, dateFrom: null } });
      }
    } else {
      pV.push({ key, value, columnNames: [] });
    }

    return pV;
  }, []);

  const onDelete = ({ key, columnNames }: { key: string; columnNames: PlatformFilterNamesType[] }) => {
    if (columnNames.length > 0) {
      onDeleteChip(columnNames);
    } else {
      onDeleteChip([key as keyof PlatformTableType]);
    }
  };

  return (
    <CommonEnhancedToolbar
      toggleFilters={toggleFilters}
      filter={{
        component: (
          <Filters
            onChangeFilter={onChangeFilter}
            onResetFilter={onResetFilter}
            filter={filter}
            chosenPlatform={chosenPlatform}
            platformNames={platformNames}
            chosenUrlSearchPattern={chosenUrlSearchPattern}
          />
        ),
        isActive: filter.active,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          {chips.map(
            ({ key, value, columnNames }) =>
              value && (
                <Chip
                  key={key}
                  label={getChipLabel({ key: key as keyof PlatformTableType, value, headCells })}
                  size="medium"
                  sx={{ m: '5px 10px 5px 0' }}
                  onDelete={() => onDelete({ key, columnNames })}
                />
              ),
          )}
        </Box>
      </Box>
    </CommonEnhancedToolbar>
  );
};

export default EnhancedToolbar;

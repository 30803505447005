import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getQueryParameters, getSortParameter } from 'api/helper';
import { ratingCrawlerApiSlice } from 'api/ratingCrawlerApiSlice';
import type { CustomError } from 'api/ratingCrawlerBaseQuery';
import type {
  DelayType,
  GetPlatformsArgumentsType,
  PlatformBlockingReason,
  PlatformState,
  PlatformStatus,
  PlatformStatusType,
  ProxyType,
} from 'pages/Platforms/types';
import type {
  GetPlatformsReturnType,
  PlatformMonitoringReturnType,
  PlatformMonitoringTransformedType,
  PlatformsTransformedType,
} from '../types';

const updateState = <T extends keyof PlatformState['attributes']>({
  dispatch,
  paginationData,
  id,
  stateProperty,
  value,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, AnyAction>;
  id: string;
  paginationData: GetPlatformsArgumentsType;
  value: PlatformState['attributes'][T];
  stateProperty: T;
}) =>
  dispatch(
    platformsExtendedApi.util.updateQueryData('getPlatforms', { ...paginationData }, (draft) => {
      const currentPlatform = draft.transformedData.find((transformedData) => transformedData.id === id);

      if (currentPlatform) {
        currentPlatform.attributes[stateProperty] = value;
      }
    }),
  );

const platformsExtendedApi = ratingCrawlerApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlatforms: builder.query<PlatformsTransformedType, GetPlatformsArgumentsType>({
      query: ({ activeFilters, order, orderBy, page, rowsPerPage }) => {
        const queryParameters = getQueryParameters(activeFilters);
        const queryString = `&${queryParameters.join('&')}`;
        const sortString = getSortParameter(order, orderBy);

        return {
          url: `/q/v1/platforms?offset=${page * rowsPerPage}&limit=${rowsPerPage}${queryString}${sortString}`,
          method: 'GET',
        };
      },
      transformResponse: (payload: GetPlatformsReturnType) => ({
        transformedData: payload.data.map(({ attributes, ...rest }) => ({
          ...rest,
          attributes: {
            ...attributes,
            ...attributes.extraData,
            ...attributes.sourcesData,
          },
        })),
        meta: payload.meta,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    changePlatformIsActive: builder.mutation<
      undefined,
      { id: string; active: boolean; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, active }) => ({
        url: '/c/v1/change-platform-active',
        method: 'POST',
        body: JSON.stringify({ id, active }),
      }),
      async onQueryStarted({ id, active, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'isActive',
          value: active,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformIsHidden: builder.mutation<
      undefined,
      { id: string; hidden: boolean; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, hidden }) => ({
        url: '/c/v1/change-platform-hidden',
        method: 'POST',
        body: JSON.stringify({ id, hidden }),
      }),
      async onQueryStarted({ id, hidden, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'isHidden',
          value: hidden,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformIsSkippingInactives: builder.mutation<
      undefined,
      { id: string; skip: boolean; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, skip }) => ({
        url: '/c/v1/change-platform-skipping',
        method: 'POST',
        body: JSON.stringify({ id, skip }),
      }),
      async onQueryStarted({ id, skip, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'isSkippingInactives',
          value: skip,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformProxy: builder.mutation<
      undefined,
      { id: string; proxy: ProxyType; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, proxy }) => ({
        url: '/c/v1/change-platform-proxy',
        method: 'POST',
        body: JSON.stringify({ id, proxy }),
      }),
      async onQueryStarted({ id, proxy, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'proxy',
          value: proxy,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformResourceType: builder.mutation<
      undefined,
      { id: string; restrictResourceType?: string[]; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, restrictResourceType }) => ({
        url: '/c/v1/change-platform-resource-type',
        method: 'POST',
        body: JSON.stringify({ id, restrictResourceType }),
      }),
      async onQueryStarted({ id, restrictResourceType, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'restrictResourceType',
          value: restrictResourceType,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    getPlatformStatus: builder.query<{ aggregatedSuccess?: number; aggregatedError?: number }, { id: string }>({
      query: ({ id }) => ({
        url: `/q/v1/platform-status?id=${id}`,
        method: 'GET',
      }),
      transformResponse: (payload: { data: PlatformStatusType[] }) => ({
        aggregatedSuccess: payload.data.find((element) => element.type === 'platformSuccessful')?.attributes.count,
        aggregatedError: payload.data.find((element) => element.type === 'platformErroneous')?.attributes.count,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    getPlatformNames: builder.query<Array<{ id: string; label: string }>, string>({
      query: (query) => {
        const queryString = query ? `?${query}` : '';

        return {
          url: `/q/v1/platform-names${queryString}`,
          method: 'GET',
        };
      },
      transformResponse: (payload: { data: Array<{ id: string; attributes: { name: string } }> }) =>
        payload.data.map(({ attributes, ...rest }) => ({
          ...rest,
          label: attributes.name,
        })),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    changePlatformDelays: builder.mutation<
      undefined,
      {
        id: string;
        delay?: Partial<DelayType>;
        intervalCycle?: number;
        intervalItemCount?: number;
        paginationData: GetPlatformsArgumentsType;
      }
    >({
      query: ({ id, delay, intervalCycle, intervalItemCount }) => ({
        url: '/c/v1/change-platform-delays',
        method: 'POST',
        body: JSON.stringify({
          id,
          delayActive: delay?.active,
          delayInactive: delay?.inactive,
          delayFailedActive: delay?.failedActive,
          delayFailedInactive: delay?.failedInactive,
          intervalCycle,
          intervalItemCount,
        }),
      }),
      async onQueryStarted(
        { id, delay, intervalCycle, intervalItemCount, paginationData },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          platformsExtendedApi.util.updateQueryData('getPlatforms', { ...paginationData }, (draft) => {
            const currentPlatform = draft.transformedData.find((transformedData) => transformedData.id === id);

            if (currentPlatform && delay?.active) {
              currentPlatform.attributes.delay.active = delay.active;
            }

            if (currentPlatform && delay?.failedActive) {
              currentPlatform.attributes.delay.failedActive = delay.failedActive;
            }

            if (currentPlatform && delay?.inactive) {
              currentPlatform.attributes.delay.inactive = delay.inactive;
            }

            if (currentPlatform && delay?.failedInactive) {
              currentPlatform.attributes.delay.failedInactive = delay.failedInactive;
            }

            if (currentPlatform && intervalCycle) {
              currentPlatform.attributes.intervalCycle = intervalCycle;
            }

            if (currentPlatform && intervalItemCount) {
              currentPlatform.attributes.intervalItemCount = intervalItemCount;
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    getPlatformMonitoring: builder.query<PlatformMonitoringTransformedType, { platformId: string }>({
      query: ({ platformId }) => ({
        url: `/q/v1/platform-monitoring-by-id?id=${platformId}`,
        method: 'GET',
      }),
      transformResponse: (payload: PlatformMonitoringReturnType) => ({
        data: payload.data.map((platform) => ({
          ...platform.attributes,
        })),
        meta: {
          count: payload.meta.count,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
    }),
    changePlatformStatus: builder.mutation<
      undefined,
      { id: string; status: PlatformStatus; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, status }) => ({
        url: '/c/v1/change-platform-status',
        method: 'POST',
        body: JSON.stringify({ id, status }),
      }),
      async onQueryStarted({ id, status, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'status',
          value: status,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformBlockingReason: builder.mutation<
      undefined,
      { id: string; blockingReason: PlatformBlockingReason; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, blockingReason }) => ({
        url: '/c/v1/change-platform-reason',
        method: 'POST',
        body: JSON.stringify({ id, blockingReason }),
      }),
      async onQueryStarted({ id, blockingReason, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'blockingReason',
          value: blockingReason,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformUrlSearchPattern: builder.mutation<
      undefined,
      { id: string; urlSearchPattern: string; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, urlSearchPattern }) => ({
        url: '/c/v1/change-platform-url-search-pattern',
        method: 'POST',
        body: JSON.stringify({ id, urlSearchPattern }),
      }),
      async onQueryStarted({ id, urlSearchPattern, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'urlSearchPattern',
          value: urlSearchPattern,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
    changePlatformComment: builder.mutation<
      undefined,
      { id: string; comment: string; paginationData: GetPlatformsArgumentsType }
    >({
      query: ({ id, comment }) => ({
        url: '/c/v1/change-platform-comment',
        method: 'POST',
        body: JSON.stringify({ id, comment }),
      }),
      async onQueryStarted({ id, comment, paginationData }, { dispatch, queryFulfilled }) {
        const patchResult = updateState({
          dispatch,
          paginationData,
          id,
          stateProperty: 'comment',
          value: comment,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }

          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arguments_) => [{ type: 'Platform', id: arguments_.id }],
    }),
  }),
});

export const {
  useGetPlatformsQuery,
  useChangePlatformIsActiveMutation,
  useChangePlatformIsHiddenMutation,
  useChangePlatformIsSkippingInactivesMutation,
  useChangePlatformProxyMutation,
  useChangePlatformResourceTypeMutation,
  useChangePlatformDelaysMutation,
  useGetPlatformMonitoringQuery,
  useGetPlatformNamesQuery,
  useChangePlatformStatusMutation,
  useChangePlatformBlockingReasonMutation,
  useChangePlatformUrlSearchPatternMutation,
  useChangePlatformCommentMutation,
} = platformsExtendedApi;

import React, { memo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ActionCell from 'components/EnhancedTableHead/ActionCell';
import TableCellWrapper from 'components/EnhancedTableHead/TableCellWrapper';
import headCells from './headCells';

const EnhancedTableHead = () => (
  <TableHead>
    <TableRow>
      <ActionCell />
      {headCells.map(
        (headCell) =>
          !headCell.hideColumn && (
            <TableCellWrapper key={headCell.id}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                  <span> {headCell.label}</span>
                </Box>
                {headCell.tooltip && (
                  <Tooltip title={headCell.tooltip} arrow={true}>
                    <IconButton>
                      <InfoIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </TableCellWrapper>
          ),
      )}
    </TableRow>
  </TableHead>
);

export default memo(EnhancedTableHead);

import React, { useState } from 'react';
import type { FC } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormik } from 'formik';
import { useAddResultMutation } from 'api/subSlices/resultsExtendedSlice';
import Header from 'components/Header';
import sourceSettingsSchema from '../../schemas/sourceSettingsSchema';
import type { GetSourcesPaginationData, SettingsType } from '../../types';
import ResultSettings from './ResultSettings';
import UrlSettings from './UrlSettings';

type Props = {
  settings: SettingsType;
  paginationData: GetSourcesPaginationData;
  handleChangeSourceUrl: (newUrl: string) => void;
};

const SourceSettings: FC<Props> = ({ settings, paginationData, handleChangeSourceUrl }) => {
  const { id } = settings;
  const [openSettings, setOpenSettings] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [addResults] = useAddResultMutation();

  const formik = useFormik({
    initialValues: settings,
    enableReinitialize: true,
    validationSchema: sourceSettingsSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSettingsOpen = () => {
    setOpenSettings(true);
  };
  const handleSettingsClose = () => {
    setOpenSettings(false);
    formik.resetForm();
  };

  function handleSubmit(values: SettingsType) {
    if (values.url !== formik.initialValues.url) {
      handleChangeSourceUrl(values.url);
    }

    if (values.count || values.score) {
      addResults({ sourceId: id, count: values.count, value: values.score, paginationData });
    }

    handleSettingsClose();
  }

  return (
    <>
      <IconButton title="settings" onClick={handleSettingsOpen}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        onClose={handleSettingsClose}
        aria-labelledby="customized-dialog-title"
        open={openSettings}
        fullScreen={isMobile}
        sx={{
          '& .MuiPaper-root': {
            width: '700px',
            maxWidth: '700px',
          },
          '& .MuiDialogContent-root': {
            p: (theme) => theme.spacing(3),
          },
        }}
      >
        <Header id="customized-dialog-title" onClose={handleSettingsClose}>
          Settings
        </Header>
        <DialogContent dividers={false}>
          <form
            onSubmit={formik.handleSubmit}
            style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
          >
            <Grid container={true}>
              <UrlSettings formik={formik} />
              <ResultSettings formik={formik} />
            </Grid>
            <Grid container={true}>
              <Box
                sx={{
                  p: { xs: '2.5rem 0.5rem 1rem', sm: '3rem 0.75rem 1.5rem' },
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button type="submit" variant="contained" disableElevation={true} fullWidth={isMobile}>
                  Save changes
                </Button>
              </Box>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SourceSettings;

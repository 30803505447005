import React from 'react';
import type { FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { List } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import useTheme from 'theme';
import { chartData } from '../data';
import Paragraph from './Paragraph';

type Props = {
  puppeteerDefaultPlatformsCount?: number;
  puppeteerHeadfullPlatformsCount?: number;
  valueserpPlatformsCount?: number;
};

const options = {
  plugins: {
    tooltip: {
      enabled: true,
    },
  },
};

const ChartElement: FC<Props> = ({
  puppeteerDefaultPlatformsCount,
  puppeteerHeadfullPlatformsCount,
  valueserpPlatformsCount,
}) => {
  ChartJS.register(ArcElement, Legend, Tooltip);

  const theme = useTheme();
  const { success } = theme.palette;

  const isShowChart = puppeteerDefaultPlatformsCount || puppeteerHeadfullPlatformsCount || valueserpPlatformsCount;

  const data = {
    labels: ['Puppeteer Default', 'Puppeteer Headfull', 'Valueserp'],
    datasets: [
      {
        label: 'distribution',
        data: [puppeteerDefaultPlatformsCount, puppeteerHeadfullPlatformsCount, valueserpPlatformsCount],
        backgroundColor: [success.main, success.dark, success.light],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Box
      sx={{
        maxWidth: '300px',
        m: '0 auto',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      {isShowChart && (
        <>
          <Typography
            variant="body1"
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.8rem',
              pb: '0.5rem',
              '@media(min-width: 900px)': {
                fontSize: '1rem',
              },
            }}
          >
            Platform types
            <MuiTooltip
              sx={{ position: 'absolute', top: { xs: '-8px', md: '-6px' }, right: 0 }}
              arrow={true}
              title={
                <List sx={{ p: '1rem' }}>
                  <Typography sx={{ fontSize: 14, pb: '1rem' }}>
                    Platform type describes how we get the data:
                  </Typography>
                  <Paragraph title={chartData.paragraph1} />
                  <Paragraph title={chartData.paragraph2} />
                  <Paragraph title={chartData.paragraph3} />
                </List>
              }
            >
              <IconButton>
                <InfoOutlinedIcon sx={{ fontSize: '1.125rem', color: (t) => t.palette.grey[500] }} />
              </IconButton>
            </MuiTooltip>
          </Typography>
          <Pie data={data} options={options} />
        </>
      )}
    </Box>
  );
};

export default ChartElement;

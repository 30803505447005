import type { FC } from 'react';
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { useGetPlatformNamesQuery, useGetPlatformsQuery } from 'api/subSlices/platformsExtendedSlice';
import EnhancedTableHead from 'components/EnhancedTableHead';
import useFilters from 'hooks/useFilters';
import usePaginationData from 'hooks/usePaginationData';
import type { DateFilterPicker } from 'types';
import EnhancedToolbar from './components/EnhancedToolbar';
import headCells from './components/headCells';
import TableBody from './components/TableBody';
import type { PlatformFilterNamesType, PlatformFilterType, PlatformStatus, PlatformTableType } from './types';

const Platforms: FC = () => {
  const [paginationData, setPaginationData] = usePaginationData<
    PlatformTableType,
    PlatformFilterType['activeFilters'] & { 'status-exclude'?: PlatformStatus }
  >({ orderByInitValue: 'createdAt', initialFilters: { status: 'approved' } });

  const { isActive, setIsActive, resetFilters, activeFilters, changeFilter } = useFilters<PlatformFilterNamesType>({
    onChangeCallback: (data) => {
      setPaginationData({ type: 'setActiveFilters', payload: data });
    },
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationData({ type: 'setPage', payload: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationData({ type: 'setRowsPerPage', payload: Number.parseInt(event.target.value, 10) });
    setPaginationData({ type: 'setPage', payload: 0 });
  };

  const { isLoading, isFetching, data } = useGetPlatformsQuery(paginationData);

  const { data: platformNames } = useGetPlatformNamesQuery('status=approved');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof PlatformTableType) => {
    const isAsc =
      (paginationData.orderBy === property && paginationData.order === 'asc') || paginationData.orderBy !== property;

    setPaginationData({ type: 'setOrder', payload: isAsc ? 'desc' : 'asc' });
    setPaginationData({ type: 'setOrderBy', payload: property });
    setPaginationData({ type: 'setPage', payload: 0 });
  };

  const onChangeFilter = (name: PlatformFilterNamesType, value: DateFilterPicker | string) => {
    changeFilter([{ name, value }]);
  };

  const onDeleteChip = (names: PlatformFilterNamesType[]) => {
    changeFilter(names.map((name) => ({ name, value: '' })));
  };

  const toggleFilters = () => {
    setIsActive((previous) => !previous);
  };

  return (
    <>
      <EnhancedToolbar
        filter={{ activeFilters, active: isActive }}
        onDeleteChip={onDeleteChip}
        onChangeFilter={onChangeFilter}
        onResetFilter={resetFilters}
        toggleFilters={toggleFilters}
        chosenPlatform={activeFilters.name as string}
        platformNames={platformNames ?? []}
      />
      <Paper sx={{ m: { xs: '20px 0', sm: '20px' }, p: '0 15px' }}>
        <TableContainer sx={{ height: 'calc(100vh - 250px)' }}>
          <Table aria-label="collapsible table" stickyHeader={true} sx={{ position: 'relative', zIndex: '0' }}>
            <EnhancedTableHead
              headCells={headCells}
              order={paginationData.order}
              orderBy={paginationData.orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody isLoading={isLoading} isFetching={isFetching} data={data} paginationData={paginationData} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={data?.meta.count ?? 0}
          rowsPerPage={paginationData.rowsPerPage}
          page={paginationData.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ position: 'sticky', bottom: 0, backgroundColor: (theme) => theme.palette.common.white }}
        />
      </Paper>
    </>
  );
};

export default Platforms;

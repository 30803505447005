import React from 'react';
import type { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Header from './Header';

type Props = {
  filter: {
    component: ReactElement;
    isActive: boolean;
  };
  toggleFilters: () => void;
};

const CommonEnhancedToolbar: FC<Props> = ({ filter, toggleFilters, children }) => (
  <Box sx={{ m: { xs: 0, sm: '20px 20px 0' } }}>
    <Header toggleFilters={toggleFilters} />
    {filter.isActive && filter.component}
    {children}
  </Box>
);

export default CommonEnhancedToolbar;

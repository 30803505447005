import type { HttpMethods } from '@pe-libs/react-components/build/lib/types';

export type CustomError = {
  code: string;
  detail: string;
  title: string;
  meta: {
    property: string;
    type: string;
  };
};

type BaseQueryReturnType = Promise<
  | {
      data: unknown;
      error?: undefined;
      meta?: { request: Request; response: Response };
    }
  | {
      error: {
        status: number;
        data: unknown;
      };
      data?: undefined;
      meta?: { request: Request; response: Response };
    }
>;

export const ratingCrawlerBaseQuery =
  ({ baseUrl = '' }: { baseUrl: string }) =>
  async ({
    url,
    method,
    headers,
    body,
    isRefreshTokenRequest,
  }: {
    url: string;
    method: HttpMethods;
    headers?: Record<string, string> | undefined;
    body?: BodyInit | undefined;
    isRefreshTokenRequest?: boolean;
  }): BaseQueryReturnType => {
    try {
      const result = await request(baseUrl + url, { method, headers, body, isRefreshTokenRequest });

      return {
        data: result,
      };
    } catch (error) {
      return {
        error: {
          data: (error as { status: number; errors: CustomError[] }).errors,
          status: (error as { status: number; errors: CustomError[] }).status,
        },
      };
    }
  };

type RequestType = (
  url: string,
  {
    method,
    headers,
    body,
    isRefreshTokenRequest,
  }: {
    method: HttpMethods;
    headers?: Record<string, string> | undefined;
    body?: BodyInit | undefined;
    isRefreshTokenRequest?: boolean;
  },
) => Promise<unknown>;

type RequestErrorsType = Array<{ meta?: { property?: string } }>;

const { AUTH_HOST } = window.env;

const request: RequestType = async (url: string, { method, headers, body, isRefreshTokenRequest }) => {
  const response = await fetch(url, {
    method,
    body,
    headers: { 'Content-Type': 'application/json', ...headers },
    credentials: 'include',
  });

  const json = response.status !== 204 ? ((await response.json()) as Record<string, unknown>) : null;

  const isAuthError =
    response.status === 400 &&
    (json?.errors as RequestErrorsType).some((error) => error.meta?.property === 'Authorization');

  if (response.ok) {
    if (response.status === 204) {
      return response;
    }

    return json;
  }

  if ((json?.errors as unknown[]).length > 0 && isRefreshTokenRequest) {
    window.location.href = '/login';
  } else if (isAuthError) {
    await request(`${AUTH_HOST}/refresh`, {
      method: 'POST',
      isRefreshTokenRequest: true,
      body: JSON.stringify({}),
    });

    return request(url, {
      method,
      headers,
      body,
    });
  }

  // eslint-disable-next-line no-throw-literal -- error will be thrown by the baseQuery function
  throw {
    errors: json?.errors as CustomError[],
    status: response.status,
  };
};

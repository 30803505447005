import React from 'react';
import type { FC, ReactElement } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import type { PlatformTableType, Value } from 'pages/Platforms/types';
import type { SortedRowReduceArray } from 'types';

type Props = {
  sortedRow: SortedRowReduceArray;
  settingsComponent: ReactElement;
  children: (element: keyof PlatformTableType, value: Value | '-', id: string) => JSX.Element;
  id: string;
};

const TableRowWrapper: FC<Props> = ({ sortedRow, settingsComponent, children, id }) => (
  <TableRow
    sx={{
      '&:hover': {
        backgroundColor: (t) => t.palette.action.hover,
      },
    }}
  >
    <>
      {sortedRow.map(([element, value]) => (
        <TableCell
          key={element}
          align="left"
          sx={{
            borderBottom: 'none',
            fontSize: '13px',
            padding: '1rem 0.75rem',
            display: element === 'name' ? 'flex' : 'table-cell',
            alignItems: 'center',
          }}
        >
          {children(element as keyof PlatformTableType, (value as Value) ?? '-', id)}
        </TableCell>
      ))}
    </>

    <TableCell
      align="center"
      sx={{
        borderBottom: 'none',
        fontSize: '13px',
        padding: '1rem 0.75rem',
      }}
    >
      {settingsComponent}
    </TableCell>
  </TableRow>
);

export default TableRowWrapper;

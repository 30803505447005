import React, { useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useChangeDuplicateStatusMutation } from 'api/subSlices/duplicateSourcesExtendedSlice';
import type { DuplicateGroupState, getDuplicateSourcesArgumentsType } from '../types';
import ActionCell from './ActionCell';
import DefaultTableCell from './DefaultTableCell';
import GroupDetails from './GroupDetails';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '1rem 0.75rem',
    borderBottom: 'none',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledButton = styled('button')(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.primary,
  border: 'none',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  '&:hover': {
    cursor: 'pointer',
  },
}));

type Props = {
  row: DuplicateGroupState;
  paginationData: getDuplicateSourcesArgumentsType;
};

const Row: FC<Props> = ({ row, paginationData }) => {
  const { groupId, platformSpecificId, count, sources, id } = row;
  const [isGroupDetailsOpen, setIsGroupDetailsOpen] = useState(false);
  const [changeDuplicateStatus] = useChangeDuplicateStatusMutation();

  const accordionToggle = () => {
    setIsGroupDetailsOpen(!isGroupDetailsOpen);
  };

  const setGroupStatus = (isVerified: boolean) => {
    changeDuplicateStatus({ id, isVerified, ...paginationData });
  };

  const handleSourceStatusChange = (event: ChangeEvent<HTMLInputElement>, sourceId: string) => {
    changeDuplicateStatus({ id, sourceId, isVerified: event.target.checked, ...paginationData });
  };

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell align="left">
          <ActionCell setGroupStatus={setGroupStatus} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <DefaultTableCell element="groupId" value={groupId} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <DefaultTableCell element="platformSpecificId" value={platformSpecificId} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <DefaultTableCell element="count" value={count} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <StyledButton aria-label="expand row" onClick={accordionToggle}>
            View {isGroupDetailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </StyledButton>
        </StyledTableCell>
      </StyledTableRow>
      {isGroupDetailsOpen && (
        <GroupDetails
          isGroupDetailsOpen={isGroupDetailsOpen}
          sources={sources}
          handleSourceStatusChange={handleSourceStatusChange}
          groupUuid={id}
          paginationData={paginationData}
        />
      )}
    </>
  );
};

export default Row;

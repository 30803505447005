import { createApi } from '@reduxjs/toolkit/query/react';
import { ratingCrawlerBaseQuery } from './ratingCrawlerBaseQuery';

const { RATING_CRAWLER_HOST } = window.env;

export const ratingCrawlerApiSlice = createApi({
  reducerPath: 'ratingCrawlerApi',
  baseQuery: ratingCrawlerBaseQuery({ baseUrl: RATING_CRAWLER_HOST }),
  tagTypes: ['Source', 'Platform', 'Result'],
  endpoints: () => ({}),
});

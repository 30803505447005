import React from 'react';
import type { FC } from 'react';
import { Checkbox, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SettingsLabelType, FormikType, SettingsType } from '../../../types';

type Props = {
  name: keyof SettingsType;
  label: SettingsLabelType;
  menuItems: Readonly<string[]>;
  formik: FormikType;
  isMultiple?: boolean;
};

const SelectInput: FC<Props> = ({ name, label, menuItems, formik, isMultiple = false }) => (
  <Grid xs={12} sm={6} sx={{ p: { xs: '0.5rem', sm: '0.5rem 0.75rem' } }} item={true}>
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={formik.values[name] === undefined ? [] : formik.values[name]}
        label={label}
        onChange={formik.handleChange}
        multiple={isMultiple}
        renderValue={isMultiple ? (selected) => (selected as string[]).join(', ') : (selected) => selected}
      >
        {menuItems &&
          menuItems.map((item) => (
            <MenuItem key={item} value={item}>
              {isMultiple ? (
                <>
                  <Checkbox
                    checked={formik.values[name] === undefined ? false : (formik.values[name] as string).includes(item)}
                  />
                  <ListItemText primary={item} />
                </>
              ) : (
                item
              )}
            </MenuItem>
          ))}
      </Select>
      {formik.errors[name] && formik.touched[name] && (
        <Box sx={{ fontSize: '0.75rem', color: (theme) => theme.palette.error.main }}>{formik.errors[name]}</Box>
      )}
    </FormControl>
  </Grid>
);

export default SelectInput;

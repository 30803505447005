/* eslint-disable complexity */
import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useLazyGetResultsBySourceIdQuery } from 'api/subSlices/resultsExtendedSlice';
import {
  useChangeSourceIsReviewedMutation,
  useChangeSourceIsSkippedMutation,
  useChangeSourceUrlMutation,
  useCrawlSourceMutation,
  useDeleteSourceMutation,
  useGetProfilesQuery,
  useLazyGetSourceByIdQuery,
  useLazyValidateUrlsQuery,
  usePrefetch,
} from 'api/subSlices/sourcesExtendedSlice';
import type { GetSourcesPaginationData, SettingsType, SourceTableType } from '../types';
import ActionCell from './ActionCell';
import Duplicates from './Duplicates';
import LastResultCell from './LastResultCell';
import LastResultCountCell from './LastResultCountCell';
import LastRunCell from './LastRunCell';
import OriginCell from './OriginCell';
import PlatformNameCell from './PlatformNameCell';
import PlatformSpecificIdCell from './PlatformSpecificIdCell';
import ProfileDetails from './ProfileDetails';
import RatingWarning from './RatingWarning';
import SourceDetails from './SourceDetails';
import UrlCell from './UrlCell';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: '1rem 0.75rem',
    borderBottom: 'none',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledButton = styled('button')(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.primary,
  border: 'none',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  '&:hover': {
    cursor: 'pointer',
  },
}));

type Props = {
  row: SourceTableType;
  setProfileFilter: (profileId: string) => void;
  paginationData: GetSourcesPaginationData;
};

const Row: FC<Props> = ({ row, setProfileFilter, paginationData }) => {
  const {
    id,
    platformId,
    platformSpecificId,
    url,
    isReviewed,
    isActive,
    isSkipped,
    isFaulty,
    createdAt,
    updatedAt,
    deactivatedAt,
    lastRunAt,
    origin,
    lastResultType,
    lastResultCount,
    lastResultValue,
    lastResultCrawlType,
    lastErrorType,
    lastErrorMessage,
    platformName,
    duplicates,
    duplicateGroupId,
    profileId,
    rawUrl,
    platformExtraData,
    platformDelay,
    platformIsSkippingInactives,
    platformIsActive,
    ratingWarning,
  } = row;

  const {
    hasExternalReviews,
    ratingBasedOnLastMonthCount,
    numberOfMonths,
    bestRating,
    worstRating,
    profileRatingType,
    status: platformStatus,
  } = platformExtraData ?? {};
  const settings: SettingsType = {
    url: url ?? rawUrl,
    id,
  };

  const prefetchSource = usePrefetch('getSourceById', { ifOlderThan: 60 });

  const prefetchDuplicateSources = useCallback(() => {
    if (duplicates) {
      for (const source of duplicates) {
        prefetchSource({ sourceId: source.sourceId });
      }
    }
  }, [duplicates, prefetchSource]);

  useEffect(() => {
    prefetchDuplicateSources();
  }, [duplicates, prefetchDuplicateSources, prefetchSource]);

  const [crawlResultsReveived, setCrawlResultsReceived] = useState(false);

  const [changeSourceIsReviewed] = useChangeSourceIsReviewedMutation();
  const [changeSourceIsSkipped] = useChangeSourceIsSkippedMutation();
  const [deleteSource] = useDeleteSourceMutation();
  const [changeSourceUrl] = useChangeSourceUrlMutation();
  const [crawlSource] = useCrawlSourceMutation();
  const [getSourceById, { data: sourceByIdData }] = useLazyGetSourceByIdQuery();
  const [getResultsBySourceId] = useLazyGetResultsBySourceIdQuery();

  const sourceByIdCrawlData = {
    lastResultType: sourceByIdData?.isFaulty ? sourceByIdData.lastError?.type : sourceByIdData?.lastResult?.type,
    lastResultCount: sourceByIdData?.lastResult?.rating.count,
    lastResultCrawlType: sourceByIdData?.lastResult?.rating.crawlType,
    lastResultValue: sourceByIdData?.lastResult?.rating.value,
    lastErrorType: sourceByIdData?.lastError?.error.type,
    lastErrorMessage: sourceByIdData?.lastError?.error.message,
    lastRunAt: sourceByIdData?.lastRunAt,
    url: sourceByIdData?.url,
    rawUrl: sourceByIdData?.rawUrl,
    platformName: sourceByIdData?.platformName,
    platformStatus: sourceByIdData?.platformExtraData.status,
    plaformRatingType: sourceByIdData?.platformExtraData.profileRatingType,
  };

  const { data: getProfileData } = useGetProfilesQuery([profileId]);
  const profileData = getProfileData?.[0];

  const [validateUrl, resultValidateUrl] = useLazyValidateUrlsQuery();

  const { FALLBACK_PLATFORM_ID } = window.env;

  const urlStatus = resultValidateUrl.data?.[0].status;
  const matchingPlatforms =
    urlStatus === 'multiplePlatformsFound' ? resultValidateUrl.data?.[0].matchingPlatforms : undefined;

  const [openSourceDetails, setOpenSourceDetails] = useState(false);
  const accordionToggle = () => {
    if (!openSourceDetails && platformId === FALLBACK_PLATFORM_ID) {
      validateUrl([settings.url]);
    }

    setOpenSourceDetails(!openSourceDetails);
  };

  const handleVerifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeSourceIsReviewed({ sourceId: id, reviewed: event.target.checked, paginationData });
  };

  const handleSkippedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeSourceIsSkipped({ sourceId: id, skipped: event.target.checked, paginationData });
  };

  const handleDeleteSource = () => {
    deleteSource({ sourceId: id, paginationData });
  };

  const handleChangeSourceUrl = (newUrl: string) => {
    changeSourceUrl({ sourceId: id, url: newUrl, paginationData });
  };

  const handleCrawlSource = async () => {
    await crawlSource({ sourceId: id }).unwrap();

    let count = 0;
    const resultsInterval = setInterval(
      (async () => {
        const source = await getSourceById({ sourceId: id }).unwrap();

        if (lastRunAt !== source.lastRunAt || count >= 40) {
          setCrawlResultsReceived(true);
          await getResultsBySourceId({ sourceId: id, page: 0, rowsPerPage: 10, changed: true });
          clearInterval(resultsInterval);
        }

        count += 1;
      }) as () => void,
      3000,
    );
  };

  const crawlingMetaData = {
    crawlResultsReveived,
    setCrawlResultsReceived,
    handleCrawlSource,
  };

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell align="center" sx={{ display: 'flex' }}>
          <ActionCell
            settings={settings}
            id={id}
            url={settings.url}
            platformId={platformId}
            platformStatus={platformExtraData.status}
            hasNormalizedUrl={typeof url === 'string'}
            handleDeleteSource={handleDeleteSource}
            crawlingMetaData={crawlingMetaData}
            handleChangeSourceUrl={handleChangeSourceUrl}
            paginationData={paginationData}
          />
        </StyledTableCell>
        <StyledTableCell align="left" title={url}>
          <UrlCell url={sourceByIdCrawlData.url ?? url} rawUrl={sourceByIdCrawlData.rawUrl ?? rawUrl} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <StyledButton aria-label="expand row" onClick={accordionToggle}>
            View {openSourceDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </StyledButton>
        </StyledTableCell>
        <StyledTableCell align="center">
          <PlatformNameCell
            platformName={sourceByIdCrawlData.platformName ?? platformName}
            platformStatus={sourceByIdCrawlData.platformStatus ?? platformStatus}
            profileRatingType={sourceByIdCrawlData.plaformRatingType ?? profileRatingType}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          {!platformSpecificId && <PlatformSpecificIdCell />}
          {duplicates && duplicates.length > 0 && (
            <Duplicates
              duplicates={duplicates}
              duplicateGroupId={duplicateGroupId as string}
              paginationData={paginationData}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Switch
            title={isReviewed ? 'verified' : 'not verified'}
            checked={isReviewed}
            onChange={handleVerifyChange}
            disabled={typeof url !== 'string' || platformStatus !== 'approved'}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Box sx={{ display: 'flex' }}>
            <Brightness1Icon color={isActive ? 'success' : 'disabled'} titleAccess={isActive ? 'active' : 'inactive'} />
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Switch title={isSkipped ? 'skipped' : 'not skipped'} checked={isSkipped} onChange={handleSkippedChange} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <OriginCell origin={origin} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <LastResultCell
            lastResultType={sourceByIdCrawlData.lastResultType ?? lastResultType}
            lastErrorType={sourceByIdCrawlData.lastErrorType ?? lastErrorType}
            lastErrorMessage={sourceByIdCrawlData.lastErrorMessage ?? lastErrorMessage}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <LastResultCountCell
            lastResultCount={sourceByIdCrawlData.lastResultCount ?? lastResultCount}
            lastResultValue={sourceByIdCrawlData.lastResultValue ?? lastResultValue}
            lastResultCrawlType={sourceByIdCrawlData.lastResultCrawlType ?? lastResultCrawlType}
            hasExternalReviews={hasExternalReviews}
            isRatingBasedOnLastMonthCount={ratingBasedOnLastMonthCount}
            numberOfMonths={numberOfMonths}
            bestRating={bestRating}
            worstRating={worstRating}
            id={id}
            paginationData={paginationData}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <RatingWarning ratingWarning={ratingWarning} />
        </StyledTableCell>
        <StyledTableCell align="left">
          {lastRunAt || sourceByIdData?.lastRunAt ? (
            <LastRunCell
              platformDelay={platformDelay}
              isPlatformSkippingInactives={platformIsSkippingInactives}
              isPlatformActive={platformIsActive}
              isActive={isActive}
              isFaulty={isFaulty}
              lastRunAt={sourceByIdData?.lastRunAt ?? lastRunAt}
              isSkipped={isSkipped}
              hasUrl={url !== undefined}
              isReviewed={isReviewed}
            />
          ) : (
            '-'
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow sx={{ padding: 0, borderBottom: 'none' }}>
        <ProfileDetails profile={profileData} profileRatingType={profileRatingType} />
      </StyledTableRow>
      <StyledTableRow sx={{ padding: 0, borderBottom: 'none' }}>
        <SourceDetails
          profile={profileData}
          isOpenSourceDetails={openSourceDetails}
          platformSpecificId={platformSpecificId}
          updatedAt={updatedAt}
          createdAt={createdAt}
          deactivatedAt={deactivatedAt}
          setProfileFilter={setProfileFilter}
          urlStatus={urlStatus}
          matchingPlatforms={matchingPlatforms}
          url={url}
          rawUrl={rawUrl}
        />
      </StyledTableRow>
    </>
  );
};

export default Row;

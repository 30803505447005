import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { format } from 'date-fns';
import { useChartTimeWindow } from 'hooks/useChartTimeWindow';
import type { PlatformMonitoring } from 'pages/Platforms/types';
import type { ChartOptions } from 'types';
import { ChartCell } from './ChartCell';

type Props = {
  monitoringData: PlatformMonitoring[];
};

const options: ChartOptions = {
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
};

const NotVerifiedSourcesChart: FC<Props> = ({ monitoringData }) => {
  const { timeWindow, displayData, handleChange } = useChartTimeWindow(monitoringData);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, LineController);

  const data = useMemo(
    () => ({
      labels: displayData.map((platformData) => format(new Date(platformData.createdAt), 'dd/MM/yyyy')).reverse(),
      datasets: [
        {
          label: 'Number of not reviewed sources',
          data: displayData.map((platformData) => platformData.notReviewedCount).reverse(),
          backgroundColor: ['red'],
          borderColor: ['red'],
          borderWidth: 3,
        },
      ],
    }),
    [displayData],
  );

  return (
    <ChartCell
      data={data}
      options={options}
      textRed="Not verified sources"
      headerText="Number of not yet verified sources:"
      timeWindow={timeWindow}
      handleChange={handleChange}
    />
  );
};

export { NotVerifiedSourcesChart };

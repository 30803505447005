import React from 'react';
import type { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type { SourceFilterNamesType } from 'pages/Sources/types';
import type { DateFilterPicker } from 'types';
import type { DuplicateIdsHeadCellType } from '../types';
import headCells from './headCells';

type PropsType = {
  onChangeFilter: (name: SourceFilterNamesType, value: DateFilterPicker | string) => void;
  onResetFilter: () => void;
  chosenGroupId?: string;
  chosenPlatformSpecificId?: string;
  chosenCount?: string;
  chosenStatus?: string;
};

const Filters: FC<PropsType> = ({
  onChangeFilter,
  onResetFilter,
  chosenGroupId,
  chosenPlatformSpecificId,
  chosenCount,
  chosenStatus,
}) => {
  const filterList = headCells
    .filter((element) => element.filter)
    .map((element) => ({
      ...element,
      options: element.options,
    }));

  const getValue = (id: Exclude<DuplicateIdsHeadCellType['id'], 'sources'> | 'status') => {
    if (id === 'count') {
      return chosenCount;
    }

    if (id === 'groupId') {
      return chosenGroupId;
    }

    if (id === 'platformSpecificId') {
      return chosenPlatformSpecificId;
    }

    if (id === 'status') {
      return chosenStatus;
    }

    return chosenCount;
  };

  return (
    <Grid
      container={true}
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        my: '20px',
        p: '15px',
        textAlign: 'center',
        width: 'inherit',
      }}
    >
      <Grid container={true}>
        {filterList
          .filter((item) => !item.options)
          .map((item) => (
            <Grid key={item.id} xs={12} sm={6} lg={3} xl={2} item={true}>
              <TextField
                id={item.id}
                label={item.label}
                value={getValue(item.id as Exclude<DuplicateIdsHeadCellType['id'], 'sources'> & 'status')}
                variant="standard"
                inputProps={item.id === 'count' ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}}
                type={item.id === 'count' ? 'number' : 'text'}
                sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}
                onChange={(event) => onChangeFilter(item.id as SourceFilterNamesType, event.target.value)}
              />
            </Grid>
          ))}
        {filterList
          .filter((item) => item.options)
          .map((item) => (
            <Grid key={item.id} xs={12} sm={6} lg={3} xl={2} item={true}>
              <FormControl variant="standard" sx={{ m: { xs: '1rem 0', sm: '1rem' }, width: '170px' }}>
                <InputLabel id={item.id}>{item.label}</InputLabel>
                <Select
                  labelId={item.id}
                  id={item.id}
                  value={getValue(item.id)}
                  onChange={(event) => onChangeFilter(item.id as SourceFilterNamesType, event.target.value)}
                  label={item.label}
                >
                  {item.options?.map((element: { label: string; value: boolean | number | string | undefined }) => (
                    <MenuItem key={element.label} value={String(element.value) ?? 'undefined'}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
      </Grid>
      <Grid xs={12} item={true}>
        <Button
          sx={{ m: { xs: '2.5rem 0 1.5rem', lg: '1.5rem 0' } }}
          variant="contained"
          onClick={onResetFilter}
          disableElevation={true}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filters;

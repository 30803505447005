import React from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { roundNumber } from 'pages/helper';
import type { PlatformDelay } from '../types';

type Props = {
  lastRunAt: string;
  platformDelay: PlatformDelay;
  isPlatformSkippingInactives: boolean;
  isPlatformActive: boolean;
  isActive: boolean;
  isFaulty: boolean;
  isSkipped: boolean;
  isReviewed: boolean;
  hasUrl: boolean;
};

const LastRunCell: FC<Props> = ({
  lastRunAt,
  platformDelay,
  isPlatformSkippingInactives,
  isPlatformActive,
  isActive,
  isFaulty,
  isSkipped,
  isReviewed,
  hasUrl,
}) => {
  const getRelevantDelay = () => {
    if (isActive) {
      if (!isFaulty) {
        return platformDelay.active;
      }

      return platformDelay.failedActive;
    }

    if (!isFaulty) {
      return platformDelay.inactive;
    }

    return platformDelay.failedInactive;
  };

  const timeSinceLastRun = Date.now() - new Date(lastRunAt).getTime();
  const percentTimeDiff = (timeSinceLastRun * 100) / getRelevantDelay();

  const lowPercentage = 130;

  const getSourceStatus = () => {
    if ((isPlatformSkippingInactives && !isActive) || isSkipped || !isReviewed || !hasUrl || !isPlatformActive) {
      return {
        status: 'notRelevant',
        color: 'default',
      } as const;
    }

    if (percentTimeDiff <= 100) {
      return {
        status: 'ok',
        color: 'success',
      } as const;
    }

    if (percentTimeDiff <= lowPercentage) {
      return {
        status: 'low',
        color: 'warning',
      } as const;
    }

    return {
      status: 'high',
      color: 'error',
    } as const;
  };

  return (
    <Tooltip
      placement="right"
      arrow={true}
      title={
        <Box sx={{ p: '0.5rem' }}>
          {getSourceStatus().status === 'notRelevant' ? (
            <Typography sx={{ fontSize: 14 }}>
              <b>Not triggered routinly</b>
            </Typography>
          ) : (
            <Typography sx={{ fontSize: 14 }}>
              <b>Crawled within: </b>
              {roundNumber(percentTimeDiff)}%
            </Typography>
          )}
        </Box>
      }
    >
      <Chip
        label={new Date(lastRunAt).toLocaleDateString('de-DE')}
        color={getSourceStatus().color}
        size="small"
        style={{ fontWeight: 'bold', cursor: 'pointer' }}
      />
    </Tooltip>
  );
};

export default LastRunCell;

/* eslint-disable no-param-reassign */
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { SettingsState, StateType } from './types';

export const sidebarOpenChanged = createAction('MAIN/SIDEBAR_OPENED_CHANGED', (opened: boolean) => ({
  payload: { opened },
}));

export const crawlerListOpenChanged = createAction('MAIN/CRAWLER_LIST_OPENED_CHANGED', (opened: boolean) => ({
  payload: { opened },
}));

export const getSidebarOpen = createSelector(
  (state: StateType) => state.settings,
  (settings) => settings.sidebarOpen,
);

export const getCrawlerListOpen = createSelector(
  (state: StateType) => state.settings,
  (settings) => settings.crawlerListOpen,
);

const initialState: SettingsState = {
  sidebarOpen: false,
  crawlerListOpen: false,
};

const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sidebarOpenChanged, (state, { payload: { opened } }) => {
      state.sidebarOpen = opened;
    });

    builder.addCase(crawlerListOpenChanged, (state, { payload: { opened } }) => {
      state.crawlerListOpen = opened;
    });
  },
});

export default slice.reducer;

import type { InnerTableHeadCellType } from '../types';

const innerTableHeadCells: InnerTableHeadCellType[] = [
  {
    id: 'url',
    label: 'URL',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'verified',
    label: 'Verified',
  },
  {
    id: 'active',
    label: 'Active',
  },
  {
    id: 'skipped',
    label: 'Skipped',
  },
  {
    id: 'origin',
    label: 'Origin',
  },
];

export default innerTableHeadCells;

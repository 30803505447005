import React, { useState, useMemo } from 'react';
import type { FC } from 'react';
import TableRow from '@mui/material/TableRow';
import { useGetPlatformMonitoringQuery } from 'api/subSlices/platformsExtendedSlice';
import TableRowWrapper from 'components/TableRowWrapper';
import Details from 'pages/Platforms/components/Details';
import headCells from 'pages/Platforms/components/headCells';
import PlatformSettings from 'pages/Platforms/components/PlatformSettings';
import ChipTableCell from 'pages/Platforms/components/tableCells/ChipTableCell';
import DefaultTableCell from 'pages/Platforms/components/tableCells/DefaultTableCell';
import IntervalTableCell from 'pages/Platforms/components/tableCells/IntervalTableCell';
import type { SortedRowReduceArray } from 'types';
import type { GetPlatformsArgumentsType, PlatformTableType, SettingsType, Value } from '../types';
import NameTableCell from './tableCells/NameTableCell';

type Props = {
  row: PlatformTableType;
  paginationData: GetPlatformsArgumentsType;
};

const Row: FC<Props> = ({ row, paginationData }) => {
  const {
    id,
    isActive,
    isHidden,
    isSkippingInactives,
    proxy,
    restrictResourceType,
    url,
    updatedAt,
    createdAt,
    delay,
    intervalCycle,
    intervalItemCount,
    key,
    type,
    comment,
    profileRatingType,
    worstRating,
    bestRating,
    hasExternalReviews,
    ratingBasedOnLastMonthCount,
    numberOfMonths,
    crawlerType,
  } = row;

  const [openSources, setOpenSources] = useState(false);

  const settings: SettingsType = {
    id,
    isActive,
    isHidden,
    isSkippingInactives,
    proxy,
    restrictResourceType,
    delayActive: delay.active,
    delayFailedActive: delay.failedActive,
    delayInactive: delay.inactive,
    delayFailedInactive: delay.failedInactive,
    intervalCycle,
    intervalItemCount,
  };

  const { data } = useGetPlatformMonitoringQuery(
    { platformId: id },
    {
      skip: !openSources,
    },
  );
  const { data: monitoringData = [] } = data ?? {};

  const details = {
    url,
    updatedAt,
    createdAt,
    key,
    type,
    comment,
    delay,
    intervalCycle,
    intervalItemCount,
    profileRatingType,
    bestRating,
    worstRating,
    hasExternalReviews,
    ratingBasedOnLastMonthCount,
    numberOfMonths,
    monitoringData,
  };

  const rowArray = Object.entries(row);

  const headCellIdsMemoized = useMemo(
    () => headCells.filter((headCell) => !headCell.hideColumn).map((cell) => cell.id),
    [],
  );

  const sortedRow = headCellIdsMemoized.reduce((pV, cV) => {
    const index = rowArray.findIndex(([rowElement]) => rowElement === cV);

    if (index !== -1) {
      pV.push(rowArray[index]);
    } else {
      pV.push([cV, '']);
    }

    return pV;
  }, [] as SortedRowReduceArray);

  // eslint-disable-next-line no-shadow
  const getTableCell = (element: keyof PlatformTableType, value: Value, id: string) => {
    if (element === 'name') {
      return <NameTableCell value={value} isOpenSources={openSources} openRow={openRow} id={id} />;
    }

    if (element === 'isActive' || element === 'isHidden' || element === 'isSkippingInactives' || value === 'disabled') {
      return <ChipTableCell name={element} value={value} crawlerType={crawlerType} />;
    }

    if (element === 'averageActiveIntervalNote' || element === 'averageFailedActiveIntervalNote') {
      return <IntervalTableCell averageSourcesIntervalNote={value as number} isActive={isActive} />;
    }

    if (element === 'averageInactiveIntervalNote' || element === 'averageFailedInactiveIntervalNote') {
      return (
        <IntervalTableCell
          averageSourcesIntervalNote={value as number}
          isActive={isActive}
          isSkippingInactives={isSkippingInactives}
        />
      );
    }

    return <DefaultTableCell element={element} value={value} />;
  };

  const openRow = (open: boolean) => {
    setOpenSources(open);
  };

  return (
    <>
      <TableRowWrapper
        settingsComponent={<PlatformSettings settings={settings} paginationData={paginationData} />}
        sortedRow={sortedRow}
        id={id}
      >
        {getTableCell}
      </TableRowWrapper>
      <TableRow>
        <Details isOpenSources={openSources} details={details} />
      </TableRow>
    </>
  );
};

export default Row;

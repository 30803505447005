import React from 'react';
import type { FC } from 'react';
import Chip from '@mui/material/Chip';
import CommonEnhancedToolbar from 'components/EnhancedToolbar';
import { convertToColumnName } from 'pages/helper';
import type { SourceFilterNamesType } from 'pages/Sources/types';
import type { DateRangeType } from 'pages/types';
import type { ChipType, DateFilterPicker } from 'types';
import type { DuplicateIdsFilterType } from '../types';
import Filters from './Filters';
import headCells from './headCells';

type Props = {
  filter: DuplicateIdsFilterType;
  onChangeFilter: (name: SourceFilterNamesType, value: DateFilterPicker | string) => void;
  onResetFilter: () => void;
  toggleFilters: () => void;
  onDeleteChip: (names: SourceFilterNamesType[]) => void;
  chosenGroupId?: string;
  chosenPlatformSpecificId?: string;
  chosenCount?: string;
  chosenStatus?: string;
};

const EnhancedToolbar: FC<Props> = ({
  filter,
  onDeleteChip,
  onChangeFilter,
  onResetFilter,
  toggleFilters,
  chosenGroupId,
  chosenPlatformSpecificId,
  chosenCount,
  chosenStatus,
}) => {
  const chips = Object.entries(filter.activeFilters).reduce<
    Array<{ key: string; value: DateRangeType | string; columnNames: SourceFilterNamesType[] }>
  >((pV, [key, value]) => {
    const dateFilterIndex = pV.findIndex((activeFilter) => activeFilter.key === convertToColumnName(key));

    if (typeof value !== 'string') {
      return pV;
    }

    const columnName = key as SourceFilterNamesType;

    if (key.includes('min-')) {
      if (dateFilterIndex >= 0) {
        const current = pV[dateFilterIndex] as unknown as ChipType;

        current.value.dateFrom = value;
        current.columnNames.push(columnName);
      } else {
        pV.push({ key: convertToColumnName(key), columnNames: [columnName], value: { dateFrom: value, dateTo: null } });
      }
    } else if (key.includes('max-')) {
      if (dateFilterIndex >= 0) {
        const current = pV[dateFilterIndex] as unknown as ChipType;

        current.value.dateTo = value;
        current.columnNames.push(columnName);
      } else {
        pV.push({ key, columnNames: [columnName], value: { dateTo: value, dateFrom: null } });
      }
    } else {
      pV.push({ key, value, columnNames: [] });
    }

    return pV;
  }, []);

  const onDelete = ({ key, columnNames }: { key: string; columnNames: SourceFilterNamesType[] }) => {
    if (columnNames.length > 0) {
      onDeleteChip(columnNames);
    } else {
      onDeleteChip([key as SourceFilterNamesType]);
    }
  };

  const getChipLabel = (key: keyof DuplicateIdsFilterType['activeFilters'], value: string) => {
    const headCell = headCells.find((element) => element.id === key);
    const label = headCell?.label;

    if (!label) {
      return '----';
    }

    if (!headCell.options) {
      return `${label}: ${value}`;
    }

    const option = headCell.options.find((element) => element.value?.toString() === value)?.label ?? value;

    return `${label}: ${option}`;
  };

  return (
    <CommonEnhancedToolbar
      toggleFilters={toggleFilters}
      filter={{
        component: (
          <Filters
            onChangeFilter={onChangeFilter}
            onResetFilter={onResetFilter}
            chosenGroupId={chosenGroupId}
            chosenPlatformSpecificId={chosenPlatformSpecificId}
            chosenCount={chosenCount}
            chosenStatus={chosenStatus}
          />
        ),
        isActive: filter.active,
      }}
    >
      {chips.map(
        ({ key, value, columnNames }) =>
          value && (
            <Chip
              key={key}
              label={getChipLabel(key as keyof DuplicateIdsFilterType['activeFilters'], value as string)}
              size="medium"
              sx={{ m: '5px 10px 5px 0' }}
              onDelete={() => onDelete({ key, columnNames })}
            />
          ),
      )}
    </CommonEnhancedToolbar>
  );
};

export default EnhancedToolbar;

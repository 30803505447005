import React from 'react';
import type { FC } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import type { ProfileRatingType } from 'types';
import type { ProfileDetailsType } from '../types';

type Props = {
  profile?: ProfileDetailsType;
  profileRatingType: ProfileRatingType;
};

const ProfileDetails: FC<Props> = ({ profile, profileRatingType }) => {
  const hasRatingTypeConflict =
    (profile?.attributes.profileRatingType === 'employeeRatings' && profileRatingType === 'customerRatings') ||
    (profile?.attributes.profileRatingType === undefined && profileRatingType === 'employeeRatings');

  return (
    <TableCell sx={{ p: '10px 20px', backgroundColor: (t) => t.palette.common.white }} colSpan={18}>
      <Typography sx={{ fontSize: 13 }}>
        {hasRatingTypeConflict && (
          <WarningRoundedIcon
            color="error"
            titleAccess="Rating type mismatch"
            sx={{ fontSize: '1.8rem', marginBottom: '-8px' }}
          />
        )}
        <b>Profile name: </b>
        {profile?.attributes.profileName ?? '-'}, <b>address: </b>
        {!profile ? (
          '-'
        ) : (
          <>
            {profile.attributes.addressParts.street || '-'}, {profile.attributes.addressParts.zip || '-'},{' '}
            {profile.attributes.addressParts.city || '-'},{' '}
            {profile.attributes.addressParts.state ? `${profile.attributes.addressParts.state},` : ''}{' '}
            {profile.attributes.addressParts.country || '-'}
          </>
        )}
      </Typography>
    </TableCell>
  );
};

export default ProfileDetails;

import type { FC } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useGetDashboardQuery } from 'api/subSlices/dashboardExtendedSlice';
import { useGetPlatformMonitoringQuery } from 'api/subSlices/platformsExtendedSlice';
import { NewSourcesChart } from 'pages/Platforms/components/NewSourcesChart';
import { NotVerifiedSourcesChart } from 'pages/Platforms/components/NotVerifiedSourcesChart';
import { tooltips } from '../data';
import type { CardStatisticsType } from '../types';
import CardElement from './CardElement';
import ChartElement from './ChartElement';
import Tooltip from './Tooltip';

const Statistics: FC = () => {
  const { isLoading, isFetching, isSuccess, data } = useGetDashboardQuery();
  const { data: monitoringDataResponse } = useGetPlatformMonitoringQuery({
    platformId: '00000000-0000-0000-0000-000000000000',
  });

  const { data: monitoringData = [] } = monitoringDataResponse ?? {};

  const {
    totalPlatformsCount,
    activePlatformsCount,
    hiddenPlatformsCount,
    puppeteerDefaultPlatformsCount,
    puppeteerHeadfullPlatformsCount,
    valueserpPlatformsCount,
    totalSourcesCount,
    activeSourcesCount,
    sourcesToBeVerifiedCount,
    skippedSourcesCount,
  } = data ?? {};

  const platformCardStatistics: CardStatisticsType[] = [
    {
      title: 'Active Platforms',
      count: activePlatformsCount,
      total: totalPlatformsCount,
      icon: <CheckCircleOutlineIcon />,
      tooltip: <Tooltip title={tooltips.activePlatforms} />,
    },
    {
      title: 'Hidden Platforms',
      count: hiddenPlatformsCount,
      total: totalPlatformsCount,
      icon: <VisibilityOffIcon />,
      tooltip: (
        <Tooltip title={tooltips.hiddenPlatforms}>
          <Link
            sx={{ color: (t) => t.palette.common.white, textDecoration: 'underline' }}
            href="https://www.provenexpert.com/en-us/customer-review-websites/#portals"
            target="_blank"
            rel="noreferrer"
          >
            https://www.provenexpert.com/en-us/customer-review-websites/#portals
          </Link>
        </Tooltip>
      ),
    },
  ];

  const sourceCardStatistics: CardStatisticsType[] = [
    {
      title: 'Active Sources',
      count: activeSourcesCount,
      total: totalSourcesCount,
      icon: <CheckCircleOutlineIcon />,
      tooltip: <Tooltip title={tooltips.activeSources} />,
    },
    {
      title: 'Skipped Sources',
      count: skippedSourcesCount,
      total: totalSourcesCount,
      icon: <SkipNextIcon />,
      tooltip: <Tooltip title={tooltips.skippedSources} />,
    },
    {
      title: 'Sources to be verified',
      count: sourcesToBeVerifiedCount,
      total: totalSourcesCount,
      icon: <ContentPasteSearchIcon />,
      tooltip: <Tooltip title={tooltips.sourcesToBeVerified} />,
    },
  ];

  if (isLoading) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isSuccess || isFetching) {
    return (
      <>
        <Typography variant="h4" sx={{ my: '1rem' }}>
          Verification of new sources
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '70%',
            justifyContent: 'center',
            alignItems: 'center',
            m: 'auto',
          }}
        >
          <NewSourcesChart monitoringData={monitoringData} />
          <NotVerifiedSourcesChart monitoringData={monitoringData} />
        </Box>
        <Typography variant="h4" sx={{ my: '1rem' }}>
          Platforms
        </Typography>
        <Grid
          spacing={{ xs: 4, lg: 8 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: '2rem', backgroundColor: isFetching ? (theme) => theme.palette.grey.A100 : undefined }}
          container={true}
        >
          {platformCardStatistics.map(
            ({ count, total, title, icon, tooltip }) =>
              count !== undefined &&
              total !== undefined && (
                <Grid key={title} xs={12} md={4} item={true}>
                  <CardElement title={title} count={count} total={total} icon={icon} tooltip={tooltip} />
                </Grid>
              ),
          )}
          <Grid xs={12} md={4} item={true}>
            <ChartElement
              puppeteerDefaultPlatformsCount={puppeteerDefaultPlatformsCount}
              puppeteerHeadfullPlatformsCount={puppeteerHeadfullPlatformsCount}
              valueserpPlatformsCount={valueserpPlatformsCount}
            />
          </Grid>
        </Grid>
        <Typography variant="h4" sx={{ mb: '1rem' }}>
          Sources
        </Typography>
        <Grid
          spacing={{ xs: 4, lg: 8 }}
          direction="row"
          justifyContent="center"
          sx={{ mb: '2rem', backgroundColor: isFetching ? (theme) => theme.palette.grey.A100 : undefined }}
          container={true}
        >
          {sourceCardStatistics.map(
            ({ count, total, title, icon, tooltip }) =>
              count !== undefined &&
              total !== undefined && (
                <Grid key={title} xs={12} md={4} item={true}>
                  <CardElement title={title} count={count} total={total} icon={icon} tooltip={tooltip} />
                </Grid>
              ),
          )}
        </Grid>
      </>
    );
  }

  return <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Error</Box>;
};

export default Statistics;

import React from 'react';
import Body from '@mui/material/TableBody';
import { useSelector } from 'react-redux';
import type { PlatformsTransformedType } from '../../../api/types';
import Loader from '../../../components/Loader';
import { getSidebarOpen } from '../../../settingsReducer';
import type { GetPlatformsArgumentsType, PlatformTableType } from '../types';
import Row from './Row';

type PropsType = {
  isLoading: boolean;
  isFetching: boolean;
  paginationData: GetPlatformsArgumentsType;
  data?: PlatformsTransformedType;
};

const TableBody = React.memo(({ data, isFetching, isLoading, paginationData }: PropsType) => {
  const isSidebarOpen = useSelector(getSidebarOpen);

  const { transformedData = [] } = data ?? {};

  const platformData: PlatformTableType[] = transformedData.map(
    ({ attributes: { delay, comment, proxy, ...rest }, type, id }) => ({
      ...rest,
      delay,
      comment: comment ?? '',
      proxy: proxy ?? '',
      type,
      id,
    }),
  );

  return (
    <Body sx={isFetching && !isLoading ? { opacity: '0.75' } : null}>
      {isLoading || isFetching ? (
        <Loader isSidebarOpen={isSidebarOpen} size={40} isFullTable={true} />
      ) : (
        <>
          {platformData.map((platform) => (
            <Row key={platform.id} row={platform} paginationData={paginationData} />
          ))}
        </>
      )}
    </Body>
  );
});

TableBody.displayName = 'TableBody';
export default TableBody;

import type { SourceHeadCellType } from '../types';

const headCells: SourceHeadCellType[] = [
  {
    id: 'url',
    label: 'URL',
    sortable: true,
    filter: true,
    tooltip: 'hover over the url see the whole address',
  },
  { id: 'profileDetails', label: 'Profile details' },
  { id: 'platformName', label: 'Platform name', filter: true },
  {
    id: 'platformStatusQuery',
    label: 'Platform Status',
    filter: true,
    quickFilter: true,
    hideColumn: true,
    quickFilterLabel: 'known Platform',
    options: [
      { label: 'Approved', value: 'approved' },
      { label: 'Blocked', value: 'blocked' },
      { label: 'In Progress CS', value: 'in_progress_cs' },
      { label: 'In Progress DEV', value: 'in_progress_dev' },
      { label: 'In Progress PM', value: 'in_progress_pm' },
      { label: 'Pending', value: 'pending' },
      { label: '-', value: '' },
    ],
  },
  { id: 'duplicates', label: 'ID info' },
  {
    id: 'isReviewed',
    label: 'Verified',
    filter: true,
    options: [
      { label: 'not verified', value: false },
      { label: 'verified', value: true },
      { label: '-', value: '' },
    ],
    quickFilter: true,
    quickFilterLabel: 'Not verified',
  },
  {
    id: 'isActive',
    label: 'Active',
    filter: true,
    options: [
      { label: 'active', value: true },
      { label: 'inactive', value: false },
      { label: '-', value: '' },
    ],
    quickFilter: true,
  },
  {
    id: 'isInactive',
    label: 'Inactive',
    quickFilter: true,
    hideColumn: true,
  },
  {
    id: 'isSkipped',
    label: 'Skipped',
    filter: true,
    options: [
      { label: 'skipped', value: true },
      { label: 'not skipped', value: false },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'isFaulty',
    label: 'Faulty',
    filter: true,
    hideColumn: true,
    options: [
      { label: 'faulty', value: true },
      { label: 'not faulty', value: false },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'origin',
    label: 'Origin',
    filter: true,
    options: [
      { label: 'customer', value: 'customer' },
      { label: 'autocrawler', value: 'autocrawler' },
      { label: 'support', value: 'support' },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'lastResultType',
    label: 'Last result type',
  },
  {
    id: 'lastResultCount',
    label: 'Last success count/value',
  },

  { id: 'createdAt', label: 'Created', filter: true, hideColumn: true },
  { id: 'updatedAt', label: 'Updated', filter: true, hideColumn: true },
  {
    id: 'ratingWarning',
    label: 'Rating Warning',
    sortable: true,
    filter: true,
    options: [
      { label: 'High', value: 'high' },
      { label: 'Moderate', value: 'moderate' },
      { label: 'None', value: 'none' },
      { label: '-', value: '' },
    ],
  },
  {
    id: 'lastRunAt',
    label: 'Last run',
    tooltip:
      'Last Run Time and an indication if this was achieved within the defined delay (should be lower than 100%)',
    sortable: true,
    filter: true,
  },
  {
    id: 'profileId',
    label: 'Profile ID',
    filter: true,
    hideColumn: true,
  },
  {
    id: 'platformId',
    label: 'Platform ID',
    filter: true,
    hideColumn: true,
  },
  {
    id: 'rawUrl',
    label: 'Raw URL',
    filter: true,
    hideColumn: true,
  },
  {
    id: 'platformSpecificId',
    label: 'Platform Specific Id',
    filter: true,
    hideColumn: true,
  },
  {
    id: 'errorType',
    label: 'Error Type',
    filter: true,
    hideColumn: true,
  },
  {
    id: 'errorMessage',
    label: 'Error Message',
    filter: true,
    hideColumn: true,
  },
];

export default headCells;

import type { FC } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginApiSlice } from 'api/loginApiSlice';
import { ratingCrawlerApiSlice } from 'api/ratingCrawlerApiSlice';
import useAuth from 'hooks/useIsAuthenticated';
import { r } from 'routes';

const SignOut: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const logOut = loginApiSlice.usePrefetch('logOut');

  const onSignOut = () => {
    logOut();
    setIsAuthenticated(false);
    dispatch(ratingCrawlerApiSlice.util.resetApiState());
    dispatch(loginApiSlice.util.resetApiState());

    navigate(r.login);
  };

  return (
    <IconButton type="button" onClick={onSignOut}>
      <LogoutIcon />
    </IconButton>
  );
};

export default SignOut;

import * as yup from 'yup';

export const timeSettingsSchema = yup.object({
  weeks: yup.number(),
  days: yup.number(),
  hours: yup.number(),
  minutes: yup.number(),
  seconds: yup.number(),
  milliseconds: yup.number(),
});

import React from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';

const getValue = (element: 'count' | 'groupId' | 'platformSpecificId', value: number | string) => value;

type Props = {
  element: 'count' | 'groupId' | 'platformSpecificId';
  value: number | string;
};

const DefaultTableCell: FC<Props> = ({ element, value }) => <Box sx={{ fontSize: 14 }}>{getValue(element, value)}</Box>;

export default DefaultTableCell;

import { loginApiSlice } from './api/loginApiSlice';
import { ratingCrawlerApiSlice } from './api/ratingCrawlerApiSlice';
import settings from './settingsReducer';

const reducer = {
  settings,
  [ratingCrawlerApiSlice.reducerPath]: ratingCrawlerApiSlice.reducer,
  [loginApiSlice.reducerPath]: loginApiSlice.reducer,
};

export default reducer;

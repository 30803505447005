import { useMemo } from 'react';
import type { FC } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesIcon from '@mui/icons-material/Devices';
import SourceIcon from '@mui/icons-material/Source';
import WebIcon from '@mui/icons-material/Web';
import { Collapse, List, ListItemButton, ListItemIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { r } from 'routes';
import { getCrawlerListOpen, crawlerListOpenChanged } from 'settingsReducer';
import useTheme from 'theme';

type menuItem = {
  title: string;
  icon: JSX.Element;
  link: string;
};

const crawlerMenuItems: menuItem[] = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    link: r.dashboard,
  },
  {
    title: 'Platforms',
    icon: <DevicesIcon />,
    link: r.platforms,
  },
  {
    title: 'Sources',
    icon: <SourceIcon />,
    link: r.sources,
  },
  {
    title: 'Duplicate Sources',
    icon: <SourceIcon />,
    link: r.duplicateids,
  },
  {
    title: 'Unrecognized Platforms',
    icon: <WebIcon />,
    link: r.domains,
  },
];

const ItemsList: FC = () => {
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const isCrawlerListOpen = useSelector(getCrawlerListOpen);

  const handleCrawlerClick = () => dispatch(crawlerListOpenChanged(!isCrawlerListOpen));

  const crawlerItemsMemoized = useMemo(
    () =>
      crawlerMenuItems.map(({ link, title, icon }) => (
        <NavLink
          key={title}
          to={pathname !== link ? link : `${link}${search}`}
          style={{ color: theme.palette.grey[800], textDecoration: 'none' }}
        >
          <ListItemButton sx={{ pl: { xs: '16px', sm: '20px' } }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} sx={{ whiteSpace: 'normal' }} />
          </ListItemButton>
        </NavLink>
      )),
    [pathname, search, theme.palette.grey],
  );

  return (
    <>
      <ListItem onClick={handleCrawlerClick}>
        <ListItemText primary="External Sources" />
        {isCrawlerListOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isCrawlerListOpen} timeout="auto" unmountOnExit={true}>
        <List component="div" disablePadding={true}>
          {crawlerItemsMemoized}
        </List>
      </Collapse>
    </>
  );
};

export default ItemsList;

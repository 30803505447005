import { createApi } from '@reduxjs/toolkit/query/react';
import type { LoginRequestBodyType } from '../pages/Login/types';
import { ratingCrawlerBaseQuery } from './ratingCrawlerBaseQuery';

const { AUTH_HOST } = window.env;

export const loginApiSlice = createApi({
  reducerPath: 'loginApi',
  baseQuery: ratingCrawlerBaseQuery({ baseUrl: AUTH_HOST }),
  endpoints: (builder) => ({
    login: builder.mutation<undefined, LoginRequestBodyType>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    logOut: builder.query<void, void>({
      query: () => ({
        url: '/logout',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLoginMutation } = loginApiSlice;

import type { MouseEvent, ChangeEvent, FC } from 'react';
import React, { useCallback } from 'react';
import { TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { useSelector } from 'react-redux';
import { useGetPlatformNamesQuery } from 'api/subSlices/platformsExtendedSlice';
import { useGetSourcesQuery } from 'api/subSlices/sourcesExtendedSlice';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'components/Loader';
import useFilters from 'hooks/useFilters';
import usePaginationData from 'hooks/usePaginationData';
import { getValueParameter } from 'pages/helper';
import { getSidebarOpen } from 'settingsReducer';
import type { DateFilterPicker } from 'types';
import EnhancedTableHead from './components/EnhancedTableHead';
import EnhancedToolbar from './components/EnhancedToolbar';
import Row from './components/Row';
import type { SourceSortingType, SourceFilterNamesType, GetSourcesPaginationData } from './types';

const Sources: FC = () => {
  const isSidebarOpen = useSelector(getSidebarOpen);

  const [paginationData, setPaginationData] = usePaginationData<
    SourceSortingType,
    GetSourcesPaginationData['activeFilters']
  >({ orderByInitValue: 'createdAt' });

  const { isActive, setIsActive, resetFilters, activeFilters, changeFilter } = useFilters<SourceFilterNamesType>({
    onChangeCallback: (data) => {
      setPaginationData({ type: 'setActiveFilters', payload: data });
    },
  });

  const statusQuery = activeFilters.platformStatusQuery ? `status=${activeFilters.platformStatusQuery}` : '';

  const { data: platformNames } = useGetPlatformNamesQuery(statusQuery);

  const { data, isLoading, isFetching } = useGetSourcesQuery(paginationData);

  const onDeleteChip = (names: SourceFilterNamesType[]) => {
    changeFilter(names.map((name) => ({ name, value: '' })));
  };

  const toggleFilters = () => {
    setIsActive((previous) => !previous);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationData({ type: 'setPage', payload: newPage });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPaginationData({ type: 'setRowsPerPage', payload: Number.parseInt(event.target.value, 10) });
    setPaginationData({ type: 'setPage', payload: 0 });
  };

  const setProfileFilter = (profileId: string) => {
    changeFilter([{ name: 'profileId', value: getValueParameter('profileId', profileId, platformNames) as string }]);
  };

  const handleRequestSort = useCallback(
    (event: MouseEvent<unknown>, property: keyof SourceSortingType) => {
      const isAsc =
        (paginationData.orderBy === property && paginationData.order === 'asc') || paginationData.orderBy !== property;

      setPaginationData({ type: 'setOrder', payload: isAsc ? 'desc' : 'asc' });
      setPaginationData({ type: 'setOrderBy', payload: property });
      setPaginationData({ type: 'setPage', payload: 0 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationData.order, paginationData.orderBy],
  );

  const onChangeFilter = (name: SourceFilterNamesType, value: DateFilterPicker | string) => {
    changeFilter([{ name, value }]);
  };

  return (
    <>
      <EnhancedToolbar
        filter={{ activeFilters, active: isActive }}
        onDeleteChip={onDeleteChip}
        onChangeFilter={onChangeFilter}
        onResetFilter={resetFilters}
        toggleFilters={toggleFilters}
        chosenUrl={activeFilters.url as string}
        chosenRawUrl={activeFilters.rawUrl as string}
        chosenPlatformId={activeFilters.platformId as string}
        chosenStatus={activeFilters.platformStatusQuery as string}
        chosenProfileId={activeFilters.profileId as string}
        chosenPlatformName={activeFilters.platformName as string}
        chosenPlatformSpecificId={activeFilters.platformSpecificId as string}
        chosenErrorMessage={activeFilters.errorMessage as string}
        chosenErrorType={activeFilters.errorType as string}
        platformNames={platformNames ?? []}
      />
      <Paper sx={{ m: { xs: '10px 0 20px 0', sm: '10px 20px 20px' }, p: '0 15px' }}>
        <TableContainer sx={{ height: 'calc(100vh - 224px)' }}>
          <Table aria-label="collapsible table" stickyHeader={true} sx={{ position: 'relative', zIndex: '0' }}>
            <EnhancedTableHead
              order={paginationData.order}
              orderBy={paginationData.orderBy}
              onRequestSort={handleRequestSort}
              platformID={activeFilters.platformId as string}
            />
            <TableBody sx={isFetching && !isLoading ? { opacity: '0.75' } : null}>
              {isLoading ? (
                <Loader isSidebarOpen={isSidebarOpen} size={40} isFullTable={true} />
              ) : (
                <>
                  {data?.sourcesData.map((source) => (
                    <Row
                      key={source.id}
                      row={source}
                      setProfileFilter={setProfileFilter}
                      paginationData={paginationData as GetSourcesPaginationData}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={data?.meta.count ?? 0}
            rowsPerPage={paginationData.rowsPerPage}
            page={paginationData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ position: 'sticky', bottom: 0, backgroundColor: (theme) => theme.palette.common.white }}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default Sources;

import type { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

type Props = {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
};

const Header: FC<Props> = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      {...other}
      sx={{
        mb: '2rem',
        p: '1rem',
        backgroundColor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.common.white,
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default Header;

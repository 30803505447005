import { toast } from 'react-toastify';
import type { CustomError } from 'api/ratingCrawlerBaseQuery';
import type { GetDashboardResultType } from 'pages/Dashboard/types';
import { ratingCrawlerApiSlice } from '../ratingCrawlerApiSlice';

const dashboardExtendedApi = ratingCrawlerApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<GetDashboardResultType, void>({
      query: () => ({
        url: '/q/v1/dashboard',
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          for (const customError of (error as { error: { status: number; data: CustomError[] } }).error.data) {
            toast(customError.detail);
          }
        }
      },
      transformResponse: (payload: { data: Array<{ type: string; attributes: { count: number } }> }) => ({
        totalPlatformsCount: payload.data.find((element) => element.type === 'platformsTotal')?.attributes.count,
        activePlatformsCount: payload.data.find((element) => element.type === 'platformsActive')?.attributes.count,
        hiddenPlatformsCount: payload.data.find((element) => element.type === 'platformsHidden')?.attributes.count,
        puppeteerDefaultPlatformsCount: payload.data.find((element) => element.type === 'platformsPuppeteer')
          ?.attributes.count,
        puppeteerHeadfullPlatformsCount: payload.data.find((element) => element.type === 'platformsHeadful')?.attributes
          .count,
        valueserpPlatformsCount: payload.data.find((element) => element.type === 'platformsSerp')?.attributes.count,
        totalSourcesCount: payload.data.find((element) => element.type === 'sourcesTotal')?.attributes.count,
        activeSourcesCount: payload.data.find((element) => element.type === 'sourcesActive')?.attributes.count,
        skippedSourcesCount: payload.data.find((element) => element.type === 'sourcesSkipped')?.attributes.count,
        sourcesToBeVerifiedCount: payload.data.find((element) => element.type === 'sourcesNotReviewed')?.attributes
          .count,
      }),
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardExtendedApi;

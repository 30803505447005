import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import FastForwardIcon from '@mui/icons-material/FastForward';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Loader from 'components/Loader';
import type { PlatformStatus } from 'pages/Platforms/types';

type Props = {
  hasNormalizedUrl: boolean;
  platformStatus: PlatformStatus;
  crawlingMetaData: {
    crawlResultsReveived: boolean;
    setCrawlResultsReceived: React.Dispatch<React.SetStateAction<boolean>>;
    handleCrawlSource: () => void;
  };
};

const Crawl: FC<Props> = ({ hasNormalizedUrl, platformStatus, crawlingMetaData }) => {
  const [crawlIsLoading, setCrawlIsLoading] = useState(false);
  const { crawlResultsReveived, handleCrawlSource, setCrawlResultsReceived } = crawlingMetaData;

  useEffect(() => {
    if (crawlResultsReveived) {
      setCrawlIsLoading(false);
    }
  }, [crawlResultsReveived]);

  const handleCrawl = () => {
    setCrawlResultsReceived(false);
    handleCrawlSource();
    setCrawlIsLoading(true);
  };

  const handleClose = () => {
    setCrawlResultsReceived(false);
  };

  return (
    <>
      {crawlIsLoading ? (
        <Loader size={25} />
      ) : (
        <IconButton
          title="click to crawl url"
          onClick={handleCrawl}
          color="primary"
          disabled={!hasNormalizedUrl || platformStatus !== 'approved'}
        >
          <FastForwardIcon />
        </IconButton>
      )}
      <Snackbar
        open={crawlResultsReveived}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="success"
          sx={{
            width: '100%',
            color: (theme) => theme.palette.common.white,
            zIndex: (theme) => theme.zIndex.appBar + 1,
          }}
        >
          URL crawled!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Crawl;

import * as React from 'react';
import type { FC } from 'react';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { roundNumber } from 'pages/helper';

type PropsType = {
  averageSourcesIntervalNote: number;
  isActive: boolean;
  isSkippingInactives?: boolean;
};

const lowPercentage = 130;

const NameTableCell: FC<PropsType> = ({ averageSourcesIntervalNote, isActive, isSkippingInactives }) => {
  const noIssues = averageSourcesIntervalNote <= 80;

  const alertLevel = averageSourcesIntervalNote > 80 && averageSourcesIntervalNote <= 100;

  const lowIssues = averageSourcesIntervalNote > 100 && averageSourcesIntervalNote <= lowPercentage;

  const getColor = () => {
    if (!isActive || isSkippingInactives) {
      return 'disabled';
    }

    if (noIssues) {
      return 'success';
    }

    if (alertLevel) {
      return 'info';
    }

    if (lowIssues) {
      return 'warning';
    }

    return 'error';
  };

  const getTooltipContent = () => {
    if (!isActive || isSkippingInactives) {
      return (
        <Box sx={{ p: '0.5rem' }}>
          <Typography sx={{ fontSize: 12 }}>
            <b>Not relevant</b>
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ p: '0.5rem' }}>
        <Typography sx={{ fontSize: 12 }}>
          <b>Average Crawling Delay: </b>
          {roundNumber(averageSourcesIntervalNote)}%
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip title={getTooltipContent()} placement="right" arrow={true}>
        <Brightness1Icon color={getColor()} />
      </Tooltip>
    </Box>
  );
};

export default NameTableCell;

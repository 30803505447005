import React from 'react';
import type { FC } from 'react';
import Typography from '@mui/material/Typography';
import useTheme from 'theme';
import type { ProfileDetailsType } from '../../Sources/types';

type Props = {
  profile?: ProfileDetailsType;
};

const ProfileDetails: FC<Props> = ({ profile }) => {
  const theme = useTheme();

  return (
    <Typography sx={{ fontSize: 14, padding: '.5rem 1rem' }}>
      <b>Core link: </b>
      <a
        href={`https://core.service.provenexpert.com/de-de/users/detail/?profile=${profile?.id ?? ''}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.text.primary }}
      >
        {profile?.id ? `https://core.service.provenexpert.com/de-de/users/detail/?profile=${profile.id}` : '-'}
      </a>
    </Typography>
  );
};

export default ProfileDetails;

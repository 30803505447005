import React from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import type { PlatformStatus } from 'pages/Platforms/types';
import type { GetSourcesPaginationData, SettingsType } from '../types';
import Crawl from './Crawl';
import Delete from './Delete';
import History from './History';
import Settings from './SourceSettings';

type Props = {
  settings: SettingsType;
  id: string;
  url: string;
  platformId: string;
  platformStatus: PlatformStatus;
  hasNormalizedUrl: boolean;
  handleDeleteSource: () => void;
  handleChangeSourceUrl: (newUrl: string) => void;
  crawlingMetaData: {
    crawlResultsReveived: boolean;
    setCrawlResultsReceived: React.Dispatch<React.SetStateAction<boolean>>;
    handleCrawlSource: () => void;
  };
  paginationData: GetSourcesPaginationData;
};

const ActionCell: FC<Props> = ({
  settings,
  id,
  url,
  platformId,
  platformStatus,
  hasNormalizedUrl,
  handleDeleteSource,
  handleChangeSourceUrl,
  crawlingMetaData,
  paginationData,
}) => (
  <Box sx={{ maxWidth: '200px', display: 'flex' }}>
    <Settings settings={settings} handleChangeSourceUrl={handleChangeSourceUrl} paginationData={paginationData} />
    <History id={id} paginationData={paginationData} />
    <Delete url={url} handleDeleteSource={handleDeleteSource} platformId={platformId} platformStatus={platformStatus} />
    <Crawl hasNormalizedUrl={hasNormalizedUrl} crawlingMetaData={crawlingMetaData} platformStatus={platformStatus} />
  </Box>
);

export default ActionCell;

import * as yup from 'yup';

const platformSettingsSchema = yup.object({
  isActive: yup.boolean(),
  isHidden: yup.boolean(),
  isSkippingInactives: yup.boolean(),
  proxy: yup.string().oneOf(['disabled', 'zyte', 'zyte2', 'provenexpert']),
  delayActive: yup.number(),
  delayFailedActive: yup.number(),
  delayInactive: yup.number(),
  delayFailedInactive: yup.number(),
  intervalCycle: yup.number(),
  intervalItemCount: yup.number(),
});

export default platformSettingsSchema;

import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import DeleteAllExceptThis from './DeleteAllExceptThis';
import DeleteSource from './DeleteSource';

type Props = {
  url: string;
  handleDeleteSource: () => void;
  handleDeleteAllSourcesExceptThis: () => void;
};

const DuplicateSourceActionCell: FC<Props> = ({ handleDeleteSource, handleDeleteAllSourcesExceptThis, url }) => (
  <Box sx={{ display: 'flex' }}>
    <DeleteSource handleDeleteSource={handleDeleteSource} url={url} />
    <DeleteAllExceptThis handleDeleteAllSourcesExceptThis={handleDeleteAllSourcesExceptThis} url={url} />
  </Box>
);

export { DuplicateSourceActionCell };

import React from 'react';
import type { FC } from 'react';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  useChangeDuplicateSourceIsReviewedMutation,
  useChangeDuplicateSourceIsSkippedMutation,
  useDeleteDuplicateSourceMutation,
} from 'api/subSlices/duplicateSourcesExtendedSlice';
import { useGetProfilesQuery, useGetSourceByIdQuery } from 'api/subSlices/sourcesExtendedSlice';
import OriginCell from 'pages/Sources/components/OriginCell';
import type { DuplicateSource, getDuplicateSourcesArgumentsType } from '../types';
import CoreLink from './CoreLink';
import { DuplicateSourceActionCell } from './DuplicateSourceActionCell';
import ProfileDetails from './ProfileDetails';
import UrlCell from './UrlCell';

type Props = {
  source: DuplicateSource;
  groupUuid: string;
  sources: DuplicateSource[];
  paginationData: getDuplicateSourcesArgumentsType;
  handleSourceStatusChange: (event: React.ChangeEvent<HTMLInputElement>, sourceId: string) => void;
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: '1rem 0.75rem',
    borderBottom: 'none',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DuplicateMatch: FC<Props> = ({
  source: { sourceId, isVerified },
  groupUuid,
  sources,
  handleSourceStatusChange,
  paginationData,
}) => {
  const { data } = useGetSourceByIdQuery({ sourceId });
  const { url, rawUrl, isReviewed, isActive, isSkipped, origin, profileId } = data ?? {};
  const { data: profilesData } = useGetProfilesQuery([profileId ?? '']);
  const [changeSourceIsReviewed] = useChangeDuplicateSourceIsReviewedMutation();
  const [changeSourceIsSkipped] = useChangeDuplicateSourceIsSkippedMutation();
  const [deleteSource] = useDeleteDuplicateSourceMutation();

  const handleReviewedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeSourceIsReviewed({ sourceId, reviewed: event.target.checked });
  };

  const handleSkippedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeSourceIsSkipped({ sourceId, skipped: event.target.checked });
  };

  const handleDeleteSource = () => {
    deleteSource({ sourceId, groupUuid, paginationData });
  };

  const handleDeleteAllSourcesExceptThis = async () => {
    const sourceIds = sources.filter((item) => item.sourceId !== sourceId).map((item) => item.sourceId);

    for (const id of sourceIds) {
      // eslint-disable-next-line no-await-in-loop
      await deleteSource({ sourceId: id, groupUuid, paginationData }).unwrap();
    }
  };

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell align="left">
          <DuplicateSourceActionCell
            handleDeleteSource={handleDeleteSource}
            handleDeleteAllSourcesExceptThis={handleDeleteAllSourcesExceptThis}
            url={url ?? rawUrl ?? ''}
          />
        </StyledTableCell>
        <StyledTableCell align="left" title={url}>
          <UrlCell url={url ?? ''} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Switch
            title={isVerified ? 'approved' : 'not approved'}
            checked={isVerified}
            onChange={(event) => handleSourceStatusChange(event, sourceId)}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Switch
            title={isReviewed ? 'verified' : 'not verified'}
            checked={isReviewed}
            onChange={(event) => handleReviewedChange(event)}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Box sx={{ display: 'flex' }}>
            <Brightness1Icon color={isActive ? 'success' : 'disabled'} titleAccess={isActive ? 'active' : 'inactive'} />
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Switch
            title={isSkipped ? 'skipped' : 'not skipped'}
            checked={isSkipped}
            onChange={(event) => handleSkippedChange(event)}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <OriginCell origin={origin} />
        </StyledTableCell>
      </StyledTableRow>
      <TableRow sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <TableCell colSpan={7}>
          <ProfileDetails profile={profilesData?.[0]} />
          <CoreLink profile={profilesData?.[0]} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default DuplicateMatch;

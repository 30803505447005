import type { PlatformBlockingReason, PlatformStatus } from 'pages/Platforms/types';

export const getStatusLabel = (value: PlatformStatus): string => {
  switch (value) {
    case 'approved':
      return 'Approved';

    case 'blocked':
      return 'Blocked';

    case 'in_progress_cs':
      return 'In Progress CS';

    case 'in_progress_dev':
      return 'In Progress DEV';

    case 'in_progress_pm':
      return 'In Progress PM';

    case 'pending':
      return 'Pending';
    default:
      return 'Error';
  }
};

export const getBlockingReasonLabel = (value: PlatformBlockingReason): string => {
  switch (value) {
    case 'not_pe_compliant':
      return 'Not PE Compliant';

    case 'not_review_portal':
      return 'Not Review Portal';

    case 'other':
      return 'Other';
    default:
      return '-';
  }
};

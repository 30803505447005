import type { FC } from 'react';
import Chip from '@mui/material/Chip';
import type { CrawlerType, PlatformTableType, Value } from '../../types';

const getLabel: ({
  name,
  value,
  crawlerType,
}: {
  name: keyof PlatformTableType;
  value: Value;
  crawlerType: CrawlerType;
}) => {
  label: CrawlerType | 'disabled' | 'hidden' | 'inactive' | 'not skipping' | 'skipping' | 'visible';
  color: 'default' | 'success' | 'warning';
} = ({ name, value, crawlerType }) => {
  switch (name) {
    case 'isActive':
      return {
        label: value ? crawlerType : 'inactive',
        color: value ? 'success' : 'default',
      };

    case 'isHidden':
      return {
        label: value ? 'hidden' : 'visible',
        color: value ? 'default' : 'success',
      };

    case 'isSkippingInactives':
      return {
        label: value ? 'skipping' : 'not skipping',
        color: value ? 'warning' : 'default',
      };
    default:
      return {
        label: 'disabled',
        color: 'default',
      };
  }
};

const ChipTableCell: FC<{
  name: keyof PlatformTableType;
  value: Value;
  crawlerType: CrawlerType;
}> = ({ name, value, crawlerType }) => (
  <Chip
    label={typeof value === 'boolean' ? getLabel({ name, value, crawlerType }).label : value}
    color={typeof value === 'boolean' ? getLabel({ name, value, crawlerType }).color : 'default'}
    size="small"
    sx={{ fontWeight: 'bold' }}
  />
);

export default ChipTableCell;

import type { FC } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from 'components/Divider';
import type { FormikType } from '../../types';

type Props = {
  formik: FormikType;
};

const UrlSettings: FC<Props> = ({ formik }) => (
  <>
    <Grid item={true} xs={12} sm={6} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem 0 0.75rem 0.75rem' } }}>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <TextField
          id="outlined-basic"
          name="url"
          label="Url"
          value={formik.values.url}
          onChange={formik.handleChange}
        />
        {formik.errors.url && formik.touched.url ? (
          <Box sx={{ fontSize: '0.75rem', color: (theme) => theme.palette.error.main }}>{formik.errors.url}</Box>
        ) : null}
      </FormControl>
    </Grid>
    <Divider />
  </>
);

export default UrlSettings;

export const tooltips = {
  activePlatforms:
    'A non-active platform is a platform we do not support/do not want to crawl (temporarily or permanently) but we do not want to remove it completely (yet)',
  hiddenPlatforms:
    'Platforms that we still want to keep in the database but we do not want them to show up on the supported-platforms list',
  activeSources:
    ' This indicates whether the user who added this source is on a test period or paid package (a non free package) - users on free status cannot add external sources and the ones they added before are on  &quot;inactive&quot; state because they may be crawled on a less frequent basis',
  skippedSources:
    'Sources we do not want to crawl for some reason. Maybe they have no more reviews but we do not want to delete them because we want to preserve for the users the reviews they had on this sources in the past',
  sourcesToBeVerified:
    'Support goes over newly added sources and verifies whether they indeed belong to the user. Non-verified customer&apos;s profile (when the public profile is revamped).',
};

export const chartData = {
  title: 'Platform type describes how we get the data:',
  paragraph1: 'Puppeteer Default - a remotely controlled chrome browser opens the URL and grabs the data we need',
  paragraph2:
    'Puppeteer Headfull - same as above, but it has a virtual screen, which makes detecting this &quot;bot&quot; harder in some cases',
  paragraph3:
    'Valueserp - an external service that provides an API to make google search queries and thus we can puppeteer types are blocked. But this costs us additional money, so we only want to use it if we  really need it',
};

import React from 'react';
import type { FC } from 'react';
import Grid from '@mui/material/Grid';
import Divider from 'components/Divider';
import type { SettingsType } from '../../../types';
import NumberInput from '../inputs/NumberInput';
import TimeInput from '../inputs/TimeInput';
import settingsList from './settingsList';

const delayElements = settingsList.delaySettings;

type Props = {
  settings: SettingsType;
  intervalItemCount: number;
  actions: {
    setDeleyActive: React.Dispatch<React.SetStateAction<number>>;
    setDeleyInactive: React.Dispatch<React.SetStateAction<number>>;
    setDeleyFailedActive: React.Dispatch<React.SetStateAction<number>>;
    setDeleyFailedInactive: React.Dispatch<React.SetStateAction<number>>;
    setIntervalCycle: React.Dispatch<React.SetStateAction<number>>;
    setIntervalItemCount: React.Dispatch<React.SetStateAction<number>>;
  };
};

const DelaySettings: FC<Props> = ({ actions, settings, intervalItemCount }) => (
  <>
    <Grid xs={12} sx={{ p: { xs: '0.75rem 0.5rem', sm: '0.75rem' } }} item={true}>
      DELAYS
    </Grid>
    {delayElements.map(({ name, label, options }) => (
      <TimeInput
        key={name}
        name={name}
        label={label}
        actions={actions}
        milliseconds={settings[name]}
        options={options}
      />
    ))}
    <NumberInput
      name="intervalItemCount"
      label="Interval Item Count"
      valueLabel="count"
      value={intervalItemCount}
      action={actions.setIntervalItemCount}
    />
    <Divider />
  </>
);

export default DelaySettings;

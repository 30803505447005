import React from 'react';
import type { FC } from 'react';
import Chip from '@mui/material/Chip';
import { capitalizeFirstLetter } from 'pages/helper';
import type { WarningLevel } from '../types';

type Props = {
  ratingWarning: WarningLevel;
};

const RatingWarning: FC<Props> = ({ ratingWarning }) => {
  if (ratingWarning === 'none' || ratingWarning === undefined) {
    return null;
  }

  return (
    <Chip
      label={capitalizeFirstLetter(ratingWarning)}
      color={ratingWarning === 'moderate' ? 'warning' : 'error'}
      size="small"
      style={{ fontWeight: 'bold' }}
    />
  );
};

export default RatingWarning;

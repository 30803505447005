import type { PlatformMonitoring, TimeWindow } from 'pages/Platforms/types';

export const convertTimeToString = (time: number): string => {
  const timeArray = [];
  const weeks = Math.floor(time / 604800000);

  if (weeks > 0) {
    timeArray.push(`${weeks}w`);
  }

  const weekRest = time % 604800000;

  const days = Math.floor(weekRest / 86400000);

  if (days > 0) {
    timeArray.push(`${days}d`);
  }

  const dayRest = weekRest % 86400000;

  const hours = Math.floor(dayRest / 3600000);

  if (hours > 0) {
    timeArray.push(`${hours}h`);
  }

  const hourRest = dayRest % 3600000;

  const minutes = Math.floor(hourRest / 60000);

  if (minutes > 0) {
    timeArray.push(`${minutes}m`);
  }

  const minutesRest = hourRest % 60000;

  const seconds = Math.floor(minutesRest / 1000);

  if (seconds > 0) {
    timeArray.push(`${seconds}s`);
  }

  const milliseconds = minutesRest % 1000;

  if (milliseconds > 0) {
    timeArray.push(`${milliseconds}ms`);
  }

  return timeArray.length > 0 ? timeArray.join(' ') : '0';
};

export const convertMillisecondsToTime = (
  time: number,
): { weeks: number; days: number; hours: number; minutes: number; seconds: number; milliseconds: number } => {
  const weeks = Math.floor(time / 604800000);

  const weekRest = time % 604800000;

  const days = Math.floor(weekRest / 86400000);

  const dayRest = weekRest % 86400000;

  const hours = Math.floor(dayRest / 3600000);

  const hourRest = dayRest % 3600000;

  const minutes = Math.floor(hourRest / 60000);

  const minutesRest = hourRest % 60000;

  const seconds = Math.floor(minutesRest / 1000);

  const milliseconds = minutesRest % 1000;

  return {
    weeks,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const convertData = ({
  timeWindow,
  monitoringData,
}: {
  timeWindow: TimeWindow;
  monitoringData: PlatformMonitoring[];
}): PlatformMonitoring[] => {
  const newArray: PlatformMonitoring[] = [];
  let step: number;
  let length: number;

  switch (timeWindow) {
    case '1week':
      step = 1;
      length = 7;
      break;

    case '2week':
      step = 1;
      length = 14;
      break;

    case '3week':
      step = 1;
      length = 21;
      break;

    case 'month-1d':
      step = 1;
      length = 30;
      break;

    case 'month-3d':
      step = 3;
      length = 30;
      break;

    case '3month':
      step = 7;
      length = 90;
      break;

    case 'year':
      step = 14;
      length = 365;
      break;
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    default:
      step = 1;
      length = 7;
  }

  if (step === 1) {
    return monitoringData.slice(0, length);
  }

  for (let n = 0; n < monitoringData.length; n += step) {
    newArray.push(monitoringData[n]);
  }

  return newArray.slice(0, length);
};

import React, { useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Box, debounce, FormControl, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import type { SettingsLabelType, SettingsType } from '../../../types';

type Props = {
  name: keyof SettingsType;
  label: SettingsLabelType;
  valueLabel: string;
  value: number;
  action: React.Dispatch<React.SetStateAction<number>>;
};

const NumberInput: FC<Props> = ({ name, label, value, action, valueLabel }) => {
  const formik = useFormik({
    initialValues: {
      [name]: value,
    },
    enableReinitialize: true,
    validationSchema: yup.number(),
    onSubmit: (values) => {
      action(values[name]);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), 200),
    [formik.submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <Box sx={{ p: { xs: '0.5rem', sm: '0.5rem 0.75rem' } }}>
      <Typography sx={{ paddingBottom: '0.75rem' }}>{label}</Typography>
      <Grid container={true}>
        <Grid xs={6} item={true}>
          <FormControl
            sx={{
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <TextField
              id="outlined-basic"
              name={name}
              label={valueLabel}
              type="number"
              value={formik.values[name]}
              onChange={formik.handleChange}
            />
            {formik.errors[name] && formik.touched[name] ? (
              <Box sx={{ fontSize: '0.75rem', color: (theme) => theme.palette.error.main }}>{formik.errors[name]}</Box>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NumberInput;

import React from 'react';
import type { FC } from 'react';
import { Box, FormControl, Grid, TextField } from '@mui/material';
import type { FormikTimeType, TimeInputType } from '../../../types';

type Props = {
  name: keyof TimeInputType;
  formik: FormikTimeType;
};

const TimeElementInput: FC<Props> = ({ name, formik }) => {
  const getLabel = () => name[0].toLocaleUpperCase() + name.slice(1);

  return (
    <Grid xs={2} item={true}>
      <FormControl
        sx={{
          width: '100%',
          marginBottom: '10px',
        }}
      >
        <TextField
          id="outlined-basic"
          name={name}
          label={getLabel()}
          type="number"
          value={formik.values[name]}
          onChange={formik.handleChange}
        />
        {formik.errors[name] && formik.touched[name] && (
          <Box sx={{ fontSize: '0.75rem', color: (theme) => theme.palette.error.main }}>{formik.errors[name]}</Box>
        )}
      </FormControl>
    </Grid>
  );
};

export default TimeElementInput;

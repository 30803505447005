import React from 'react';
import type { FC } from 'react';
import { TableCell } from '@mui/material';
import type { SortDirection } from '@mui/material';

type Props = {
  sortDirection?: SortDirection;
  align?: 'center' | 'left' | 'right';
};

const TableCellWrapper: FC<Props> = ({ children, sortDirection, align = 'left' }) => (
  <TableCell
    sortDirection={sortDirection}
    align={align}
    sx={{
      fontSize: '13px',
      lineHeight: 1.2,
      padding: '1rem 0.75rem',
      backgroundColor: (theme) => theme.palette.common.white,
    }}
  >
    {children}
  </TableCell>
);

export default TableCellWrapper;

import * as React from 'react';
import type { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import type { Value } from '../../types';

type PropsType = {
  value: Value;
  isOpenSources: boolean;
  openRow: (isOpenSources: boolean) => void;
  id: string;
};

const NameTableCell: FC<PropsType> = ({ value, isOpenSources, openRow, id }) => (
  <>
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={() => openRow(!isOpenSources)}
      sx={{ mr: '10px', padding: 0 }}
    >
      {isOpenSources ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
    <Avatar
      alt={value as string}
      src={`/images/logos/${typeof value === 'string' ? value.toLowerCase().replaceAll(' ', '') : 'noimage'}.png`}
      sx={{
        border: '2px solid lightgray',
      }}
    />
    <Box sx={{ pl: '5px', fontWeight: 'bold' }}>
      <Link to={`/sources?platformId=${encodeURIComponent(id ?? '')}`} component={NavLink}>
        {value}
      </Link>
    </Box>
  </>
);

export default NameTableCell;
